import React, { Component } from "react";
import { Segment, Form } from "semantic-ui-react";
import api from "../../../../actions/api";

class addHostingPackage extends Component {
  state = {
    data: { name: "", client: this.props.companyID, server: "", domains: [] },
    domains: [],
    servers: [],
    loading: false,
    errors: {},
  };

  componentDidMount() {
    Promise.all([
      api.management.hosting_server.listAllDropdown(),
      api.company.domains.getAllDropdown(this.props.companyID),
    ]).then((res) => this.setState({ servers: res[0], domains: res[1] }));
  }

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDropdownChange = (props, e) => {
    this.setState({ data: { ...this.state.data, [e.name]: e.value } });
  };

  validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Can't Be Empty.";
    if (!data.server) errors.server = "Can't Be Empty.";
    if (data.domains.length === 0) errors.domains = "Can't Be Empty.";

    return errors;
  };

  onSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors, loading: false });

    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="name"
              value={this.state.data.name}
              label="Package Name"
              placeholder="Package Name"
              onChange={this.handleChange}
              error={this.state.errors.name}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Dropdown
              name="server"
              label="Server"
              value={this.state.data.server}
              onChange={this.handleDropdownChange}
              placeholder="Select A Server"
              options={
                this.state.servers.length !== 0 ? this.state.servers : []
              }
              selection
              noResultsMessage={"No Servers"}
              search
              error={this.state.errors.server}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Dropdown
              name="domains"
              label="Domains"
              value={this.state.data.domains}
              onChange={this.handleDropdownChange}
              placeholder="Select Domain(s)"
              options={
                this.state.domains.length !== 0 ? this.state.domains : []
              }
              selection
              noResultsMessage={"No Domains"}
              search
              multiple
              error={this.state.errors.domains}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              type="submit"
              color="red"
              floated="left"
              onClick={() => {
                this.props.close();
              }}
              icon="close"
              content="Cancel"
            />
            <Form.Button
              color="green"
              floated="right"
              onClick={() => this.onSubmit()}
              icon="paper plane"
              content="Submit"
              loading={this.state.loading}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default addHostingPackage;
