import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";
import api from "../../../../actions/api";

import TicketTable from "./ticketTable";

export default class ticketManagement extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    api.tickets
      .getAll()
      .then((data) => {
        this.setState({ data: data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  render() {
    return (
      <React.Fragment>
        <Header textAlign="center">Ticket Management</Header>
        <Segment textAlign="right" basic />
        <TicketTable
          getData={this.getData.bind(this)}
          data={this.state.data}
          history={this.props.history}
        />
      </React.Fragment>
    );
  }
}
