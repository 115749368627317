import React, { Component } from "react";
import { Segment, Form, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";

export default class addNoteForm extends Component {
  state = {
    data: {
      message: "",
    },
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  onSubmit = () => {
    if (this.state.data.message === "") {
      toast.error("Missing Required Field", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="message"
              value={this.state.data.message}
              label="Note"
              placeholder="Add Note Here"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              type="submit"
              color="red"
              floated="left"
              onClick={() => {
                this.props.close();
              }}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              color="green"
              floated="right"
              onClick={() => this.onSubmit()}
            >
              <Icon name="paper plane" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
