import { APIRequest } from "@liquidcomputing/liquid__reactjs-localauth";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

export default {
  _getBuildNumber: () => APIRequest.get(server + `/_buildNumber`),
  company: {
    getAll: () => APIRequest.get(server + "/company"),
    getAllPaged: (page, size, searchQuery, filtered) =>
      APIRequest.get(
        server +
          `/company/paged?page=${page}&size=${size}&search=` +
          encodeURIComponent(searchQuery || "") +
          `&filter=` +
          encodeURIComponent(JSON.stringify(filtered) || "")
      ),
    getCompany: (id) => APIRequest.get(server + `/company/${id}`),
    addCompany: (data) => APIRequest.post(server + "/company", data),
    updateCompany: (id, data) =>
      APIRequest.patch(server + `/company/${id}`, data),
    deleteCompany: (id) => APIRequest.delete(server + `/company/${id}`),
    ticketTimeUsage: (id) =>
      APIRequest.get(server + `/company/ticketdata/${id}`),
    printTickets: (id, year, month) =>
      APIRequest.post(
        server + `/company/${id}/print/tickets/${year}/${month}`,
        {},
        { responseType: "blob" }
      ),
    company_helpdesk_time_management: {
      adjust_time: (id, data) =>
        APIRequest.patch(server + `/company/${id}/adjust_time`, data),
      get_paged_list: (page, size, searchQuery, filtered) =>
        APIRequest.get(
          server +
            `/company/time/paged?page=${page}&size=${size}` +
            `&filter=` +
            encodeURIComponent(JSON.stringify(filtered) || "")
        ),
    },
    contacts: {
      addContact: (id, data) =>
        APIRequest.post(server + `/company/${id}/contact`, data),
      allContacts: (id) => APIRequest.get(server + `/company/${id}/contact`),
      updateContact: (id, id2, data) =>
        APIRequest.patch(server + `/company/${id}/contact/${id2}`, data),
      getContact: (id, id2) =>
        APIRequest.get(server + `/company/${id}/contact/${id2}`),
      deleteContact: (id, id2) =>
        APIRequest.delete(server + `/company/${id}/contact/${id2}`),
    },
    company_notes: {
      getNotes: (id) => APIRequest.get(server + `/company/${id}/notes`),
      getNote: (id, id2) =>
        APIRequest.get(server + `/company/${id}/notes/${id2}`),
      addNote: (id, data) =>
        APIRequest.post(server + `/company/${id}/notes`, data),
      updateNote: (id, id2, data) =>
        APIRequest.patch(server + `/company/${id}/notes/${id2}`, data),
      deleteNote: (id, id2) =>
        APIRequest.delete(server + `/company/${id}/notes/${id2}`),
    },
    domains: {
      getAll: (company_id) =>
        APIRequest.get(server + `/company/${company_id}/domains`),
      getAllDropdown: (company_id) =>
        APIRequest.get(server + `/company/${company_id}/domains/dropdown`),
      add: (company_id, data) =>
        APIRequest.post(server + `/company/${company_id}/domains`, data),
      update: (company_id, domain_id, data) =>
        APIRequest.patch(
          server + `/company/${company_id}/domains/${domain_id}`,
          data
        ),
      remove: (company_id, id) =>
        APIRequest.delete(server + `/company/${company_id}/domains/${id}`),
      domain_actions: {
        getAll: (company_id, domain_id) =>
          APIRequest.get(
            server +
              `/company/${company_id}/domains/${domain_id}/domain_actions`
          ),
        add: (company_id, domain_id, data) =>
          APIRequest.post(
            server +
              `/company/${company_id}/domains/${domain_id}/domain_actions`,
            data
          ),
        update: (company_id, domain_id, id, data) =>
          APIRequest.patch(
            server +
              `/company/${company_id}/domains/${domain_id}/domain_actions/${id}`,
            data
          ),
      },
    },
    tickets: {
      all: (id) => APIRequest.get(server + `/company/${id}/tickets`),
    },
  },
  tickets: {
    getAll: (limit = false) =>
      APIRequest.get(server + "/ticket" + (limit ? "?recent=true" : "")),
    getAllPaged: (page, size, searchQuery, status = "all") =>
      APIRequest.get(
        server +
          `/ticket/paged?page=${page}&size=${size}&search=` +
          encodeURIComponent(searchQuery || "") +
          `&status=${status}`
      ),
    getAllUnassigned: () => APIRequest.get(server + `/ticket/unassigned`),
    getAllUserTickets: (type) =>
      APIRequest.get(server + `/ticket/user_assigned?type=${type}`),
    addTicket: (data) => APIRequest.post(server + "/ticket", data),
    getTicket: (id) => APIRequest.get(server + `/ticket/${id}`),
    updateTicket: (id, data) =>
      APIRequest.patch(server + `/ticket/${id}`, data),
    deleteTicket: (id) => APIRequest.delete(server + `/ticket/${id}`),
    updateTicketStatus: (id, data) =>
      APIRequest.post(
        server + `/ticket/${id}/activity/ticketstatuschange`,
        data
      ),
    updateTicketAssignment: (id, data) =>
      APIRequest.post(
        server + `/ticket/${id}/activity/ticketassignmentchange`,
        data
      ),
    getActivity: (id) => APIRequest.get(server + `/ticket/${id}/activity`),
    newActivity: (id, data) =>
      APIRequest.post(server + `/ticket/${id}/activity/newactivity`, data),
    postResponse: (id, data) =>
      APIRequest.post(server + `/ticket/${id}/activity/response`, data),
    updateTime: (id, data) =>
      APIRequest.patch(server + `/ticket/${id}/time`, data),
    editTime: (id, id2, data) =>
      APIRequest.patch(server + `/ticket/${id}/time/${id2}`, data),
    updateTimes: (id, data) =>
      APIRequest.patch(server + `/ticket/${id}/times`, data),
    invoiceUpdate: (id, data) =>
      APIRequest.patch(server + `/ticket/${id}/invoice`, data),
  },
  billing: {
    closed_tickets: (start_month, start_year, end_month, end_year) => {
      return APIRequest.get(
        server +
          `/billing/closed_tickets?start_month=${encodeURIComponent(
            start_month
          )}&start_year=${encodeURIComponent(
            start_year
          )}&end_month=${encodeURIComponent(
            end_month
          )}&end_year=${encodeURIComponent(end_year)}`
      );
    },
    domain_renewals: () => APIRequest.get(server + "/billing/domain_renewal/"),
    pending_actions: () =>
      APIRequest.get(server + "/billing/domain_renewal/pending_actions"),
  },
  workshop: {
    getAll: () => APIRequest.get(server + "/workshop"),
    getWorkshop: (id) => APIRequest.get(server + `/workshop/${id}`),
    addWorkshop: (data) => APIRequest.post(server + `/workshop`, data),
  },
  todos: {
    getAll: () => APIRequest.get(server + "/todo"),
    getTodo: (id) => APIRequest.get(server + `/todo/${id}`),
    addTodo: (data) => APIRequest.post(server + "/todo", data),
    removeTodo: (id) => APIRequest.delete(server + `/todo/${id}`),
    updateStatus: (id) =>
      APIRequest.patch(server + `/todo/${id}/updateStatus`, {}),
  },
  wiki: {
    wiki_entry: {
      getAll: (id) => APIRequest.get(server + `/wiki/${id}/entry/`),
      getWikiEntry: (id, id2) =>
        APIRequest.get(server + `/wiki/${id}/entry/${id2}`),
      addWikiEntry: (id, data) =>
        APIRequest.post(server + `/wiki/${id}/entry/`, data),
      removeWikiEntry: (id, id2) =>
        APIRequest.delete(server + `/wiki/${id}/entry/${id2}`),
      editWikiEntry: (id, id2, data) =>
        APIRequest.patch(server + `/wiki/${id}/entry/${id2}`, data),
    },
    getAll: () => APIRequest.get(server + "/wiki"),
    getWiki: (id) => APIRequest.get(server + `/wiki/${id}`),
    addWiki: (data) => APIRequest.post(server + "/wiki", data),
    removeWiki: (id) => APIRequest.delete(server + `/wiki/${id}`),
    editWiki: (id, data) => APIRequest.patch(server + `/wiki/${id}`, data),
  },
  hosting_package: {
    add: (data) => APIRequest.post(server + `/hosting_package`, data),
    edit: (id, data) =>
      APIRequest.patch(server + `/hosting_package/${id}`, data),
    remove: (id) => APIRequest.delete(server + `/hosting_package/${id}`),
    findOne: (id) => APIRequest.get(server + `/hosting_package/${id}`),
    findOneFullData: (id) =>
      APIRequest.get(server + `/hosting_package/${id}/full_data`),
    listAll: () => APIRequest.get(server + "/hosting_package"),
    listAllCompany: (id) =>
      APIRequest.get(server + `/hosting_package/${id}/company`),
    packageLookup: (searchQuery) =>
      APIRequest.get(server + "/hosting_package/lookup", {
        params: { search: encodeURIComponent(searchQuery || "") },
      }),
  },
  management: {
    users: {
      getAll: () => APIRequest.get(server + "/management/users"),
      getUser: (id) => APIRequest.get(server + `/management/users/${id}`),
      editUser: (id, data) =>
        APIRequest.patch(server + `/management/users/${id}`, data),
      addUser: (data) => APIRequest.post(server + "/management/users/", data),
      deleteUser: (id) => APIRequest.delete(server + `/management/users/${id}`),
    },
    hosting_provider: {
      add: (data) =>
        APIRequest.post(server + `/management/hosting_provider`, data),
      edit: (id, data) =>
        APIRequest.patch(server + `/management/hosting_provider/${id}`, data),
      findOne: (id) =>
        APIRequest.get(server + `/management/hosting_provider/${id}`),
      listAll: () => APIRequest.get(server + "/management/hosting_provider"),
      listAllDropdown: () =>
        APIRequest.get(server + "/management/hosting_provider/dropdown"),
    },
    hosting_server: {
      add: (data) =>
        APIRequest.post(server + `/management/hosting_server`, data),
      edit: (id, data) =>
        APIRequest.patch(server + `/management/hosting_server/${id}`, data),
      findOne: (id) =>
        APIRequest.get(server + `/management/hosting_server/${id}`),
      listAll: () => APIRequest.get(server + "/management/hosting_server"),
      listAllDropdown: () =>
        APIRequest.get(server + "/management/hosting_server/dropdown"),
    },
  },
  current_user: {
    changePassword: (data) =>
      APIRequest.post(server + "/current_user/changepassword", data),
    getUserDetails: () => APIRequest.get(server + "/current_user/details"),
    getAllUserDetails: () => APIRequest.get(server + "/current_user"),
    getStats: () => APIRequest.get(server + "/current_user/stats"),
  },
  settings: {
    getSystemSettings: () => APIRequest.get(server + `/settings/system`),
    updateSetting: (setting) =>
      APIRequest.patch(server + `/settings/system/`, setting),
  },
  additional_services: {
    list: () => APIRequest.get(server + "/service"),
    get: (id) => APIRequest.get(server + `/service/${id}`),
    add: (data) => APIRequest.post(server + "/service", data),
    remove: (id) => APIRequest.delete(server + `/service/${id}`),
    update: (id, data) => APIRequest.patch(server + `/service/${id}`, data),
  },
};
