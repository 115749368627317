import React, { Component } from "react";
import { Segment, Header, Container, Transition, Tab } from "semantic-ui-react";
import BillingDetails from "./ticketBillingTable";
import DomainBilling from "./domainBillingTable";

export default class index extends Component {
  state = {
    onLoad: { visible: false },
  };

  componentDidMount() {
    this.setState({
      onLoad: { visible: true },
    });
  }

  render() {
    const panes = [
      {
        menuItem: "Ticket Billing",
        render: () => (
          <Tab.Pane>
            <BillingDetails history={this.props.history} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Domain Billing",
        render: () => (
          <Tab.Pane>
            <DomainBilling history={this.props.history} />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <Container fluid>
        <Segment inverted attached>
          <Header textAlign="center" as="h2">
            Billing Page
          </Header>
        </Segment>
        <Transition
          visible={this.state.onLoad.visible}
          duration={750}
          animation="fade"
        >
          <Segment className="Margins">
            <Tab
              menu={{ secondary: true, pointing: true }}
              grid={{ tabWidth: 2, paneWidth: 14 }}
              panes={panes}
              className={"billingMenuTabs"}
            />
          </Segment>
        </Transition>
      </Container>
    );
  }
}
