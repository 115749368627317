import React from "react";
import { Header, Segment } from "semantic-ui-react";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "./authProvider";

export default class AzureAuthentication extends React.Component {
  // renderUserInfo(){
  //     const user = authProvider.getAccountInfo();
  //     console.log(user);
  //     if(user === null){
  //         return null;
  //     }
  //     return (
  //         <p>User logged in is: {user.account.name} ({user.account.userName})</p>
  //     );
  // }
  render() {
    return (
      <Segment>
        <Header>Azure Authentication</Header>
        {/*<AzureAD provider={authProvider} forceLogin={true}>*/}
        {/*    {this.renderUserInfo()}*/}
        {/*</AzureAD>*/}
        <AzureAD provider={authProvider} forceLogin={true}>
          {({ login, logout, authenticationState, error, accountInfo }) => {
            // FIX: requires default case, didn't want to modify but wanted clean console
            // eslint-disable-next-line
            switch (authenticationState) {
              case AuthenticationState.Authenticated:
                return (
                  <p>
                    <span>
                      Welcome, {accountInfo.account.name}!{" "}
                      {accountInfo.account.userName}
                    </span>
                    <button onClick={logout}>Logout</button>
                  </p>
                );
              case AuthenticationState.Unauthenticated:
                return (
                  <div>
                    {error && (
                      <p>
                        <span>
                          An error occured during authentication, please try
                          again!
                        </span>
                      </p>
                    )}
                    <p>
                      <span>Hey stranger, you look new!</span>
                      <button onClick={login}>Login</button>
                    </p>
                  </div>
                );
              case AuthenticationState.InProgress:
                return <p>Authenticating...</p>;
            }
          }}
        </AzureAD>
      </Segment>
    );
  }
}
