import React, { Component } from "react";
import { Form, Button, Header } from "semantic-ui-react";
import moment from "moment";

class actionHandleForm extends Component {
  state = {
    data: { pending_action: "", note: "", new_renew_date: "" },
  };

  componentDidMount() {
    this.setState({
      data: {
        pending_action: this.props.pendingAction.pending_action,
        note: this.props.pendingAction.note,
        new_renew_date:
          this.props.pendingAction.pending_action === "Renew"
            ? this.props.pendingAction.new_renew_date
            : "",
      },
    });
  }

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  render() {
    return (
      <Form>
        {this.state.data.pending_action === "Renew" ? (
          <Header textAlign="center">
            Extending To:{" "}
            {moment(this.state.data.new_renew_date).format("DD/MM/YYYY")}
          </Header>
        ) : (
          <React.Fragment />
        )}
        <Form.Group widths="equal">
          <Form.TextArea
            name="note"
            defaultValue={this.state.data.note}
            label="Notes"
            placeholder="Extra Details"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Button
            color="red"
            content="Cancel"
            icon="close"
            onClick={() => {
              this.props.close();
            }}
          />
          <Form.Field>
            <Button.Group floated="right">
              <Button
                color="orange"
                content="Cancel Action"
                icon="ban"
                onClick={() => {
                  this.setState(
                    {
                      data: {
                        ...this.state.data,
                        pending_action: "Cancelled",
                      },
                    },
                    () => this.props.submit(this.state.data)
                  );
                }}
              />
              <Button
                color="green"
                content="Confirm"
                icon="checkmark"
                onClick={() => this.props.submit(this.state.data)}
              />
            </Button.Group>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default actionHandleForm;
