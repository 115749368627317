import React, { Component } from "react";
import { Form, Segment } from "semantic-ui-react";
import api from "../../../../actions/api";

class assignServiceForm extends Component {
  state = {
    data: { additional_services: [] },
    serviceList: [],
    loading_services: true,
  };

  componentDidMount() {
    if (this.props.currentlyAssigned.length !== 0) {
      this.setState({
        data: { additional_services: this.props.currentlyAssigned },
      });
    }

    api.additional_services
      .list()
      .then((res) => {
        let nonArchived = [];

        res.forEach((item) => {
          if (item.archived === false) {
            nonArchived.push({
              text: item.service,
              value: item._id,
              key: item._id,
            });
          }
        });

        this.setState({
          serviceList: nonArchived,
          loading_services: false,
        });
      })
      .catch((e) => {
        this.setState({ loading_services: false });
        console.log(e);
      });
  }

  handleDropdownChange = (e, data) => {
    this.setState({
      data: { ...this.state.data, [data.name]: data.value },
    });
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Dropdown
            name="additional_services"
            label="Additional Services"
            value={this.state.data.additional_services}
            onChange={this.handleDropdownChange}
            placeholder="Please Select A Response"
            options={
              this.state.serviceList.length !== 0 ? this.state.serviceList : []
            }
            multiple
            selection
            clearable
            noResultsMessage={"No Services Available"}
            search
            loading={this.state.loading_services === true ? true : false}
          />
          <Form.Group widths="equal">
            <Form.Button
              type="submit"
              color="red"
              floated="left"
              onClick={() => {
                this.props.close();
              }}
              icon="close"
              content="Cancel"
            />

            <Form.Button
              color="green"
              floated="right"
              onClick={() =>
                this.props.submit(this.state.data.additional_services)
              }
              icon="paper plane"
              content="Submit"
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default assignServiceForm;
