import React, { Component } from "react";
import { Form, Segment, Icon } from "semantic-ui-react";
import api from "../../../../actions/api";

export default class editContactForm extends Component {
  state = {
    data: {
      name: "",
      email_address: "",
      phone_number: "",
      mobile_number: ""
    }
  };

  componentDidMount() {
    if (this.props.idnumb !== "") {
      api.company.contacts
        .getContact(this.props.idnumb, this.props.idnumb2)
        .then(data => {
          this.setState({ data: data });
        })
        .catch(err => {
          console.log(err.response);
        });
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const vname = target.name;

    this.setState({ data: { ...this.state.data, [vname]: value } });
  }

  onSubmit = () => {
    this.props.submit(this.state.data);
  };

  render() {
    return (
      <Segment>
        <Form onSubmit={this.onSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="name"
              defaultValue={this.state.data.name}
              label="Name Of Client"
              placeholder="Client Name"
              onChange={this.handleChange.bind(this)}
            />
            <Form.Input
              fluid
              name="email_address"
              defaultValue={this.state.data.email_address}
              label="Email Address"
              placeholder="Email Address"
              onChange={this.handleChange.bind(this)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="phone_number"
              defaultValue={this.state.data.phone_number}
              label="Phone Number"
              placeholder="Phone Number"
              onChange={this.handleChange.bind(this)}
            />
            <Form.Input
              fluid
              name="mobile_number"
              defaultValue={this.state.data.mobile_number}
              label="Mobile Number"
              placeholder="Mobile Number"
              onChange={this.handleChange.bind(this)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              type="submit"
              color="red"
              floated="left"
              onClick={e => {
                e.preventDefault();
                this.props.close();
              }}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button type="submit" color="green" floated="right">
              <Icon name="cloud upload" />
              Update
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
