import api from "./api";

export const getSystemSettings = () => dispatch => {
  return api.settings.getSystemSettings().then(system => {
    dispatch({
      type: "SYSTEM_SETTINGS_GOT",
      payload: system
    });
  });
};

export const settingsBegin = () => ({
  type: "SYSTEM_SETTINGS_BEGIN"
});
export const updateSetting = data => dispatch => {
  return api.settings.updateSetting(data).then(system => {
    dispatch({
      type: "SYSTEM_SETTINGS_UPDATING",
      payload: system
    });
    dispatch(getSystemSettings());
  });
};
