import React, { Component } from "react";
import { Segment, Grid, Icon, Button, Message } from "semantic-ui-react";
import api from "../../actions/api";

export default class summaryTicketDisplay extends Component {
  state = {
    update: false,
    data: [],
  };

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    return api.tickets
      .getTicket(this.props.data)
      .then((data) => this.setState({ data: data }))
      .catch((err) => {
        console.log(err.response);
      });
  };
  renderCCData() {
    const data = this.state.data.cc_contacts;
    return data.map((data) => {
      return <p key={data._id}>{data.name}</p>;
    });
  }
  changeEngineerType = (type) => {
    let rateTable = this.state.data.company.rates;
    let i = rateTable.findIndex((x) => x.name === type.bill_type);
    let nexti = i + 1;
    if (nexti === rateTable.length) {
      nexti = 0;
    }
    let nextRate = rateTable[nexti];
    let rates = this.state.data.company.rates.filter((item) => {
      return item.name === nextRate.name;
    });
    let rate = 0;
    if (rates[0] !== undefined) {
      if (type.location !== "Non-Billable") {
        rate = rates[0][type.location.toLowerCase()];
      }
    }
    let newTimes = this.state.data.times.map((item) => {
      if (item._id === type._id) {
        return {
          ...item,
          bill_type: nextRate.name,
          rate: rate,
        };
      }
      return item;
    });
    this.setState({
      update: true,
      data: {
        ...this.state.data,
        times: newTimes,
      },
    });
  };
  changeLoc = (time) => {
    let newLocation = "";
    switch (time.location) {
      case "Remote":
        newLocation = "Onsite";
        break;
      case "Onsite":
        newLocation = "Non-Billable";
        break;
      case "Non-Billable":
        newLocation = "Remote";
        break;
      default:
        newLocation = "UNKNOWN";
        break;
    }
    let rates = this.state.data.company.rates.filter((item) => {
      return item.name === time.bill_type;
    });
    let rate = 0;
    if (rates[0] !== undefined) {
      if (newLocation !== "Non-Billable") {
        rate = rates[0][newLocation.toLowerCase()];
      }
    }
    let newTimes = this.state.data.times.map((item) => {
      if (item._id === time._id) {
        return {
          ...item,
          location: newLocation,
          rate: rate,
        };
      }
      return item;
    });
    this.setState({
      update: true,
      data: {
        ...this.state.data,
        times: newTimes,
      },
    });
  };
  updateTimes = () => {
    let data = this.state.data.times;
    api.tickets.updateTimes(this.state.data._id, data).then(() => {
      this.props.close(true);
    });
  };
  render() {
    if (this.state.data.length === 0) {
      return (
        <Segment textAlign="center">
          Loading...
          <span role="img" aria-label="">
            🤔
          </span>
        </Segment>
      );
    } else {
      let total = 0;
      let data = this.state.data.times;

      if(this.props.userFilter !== ""){
          data = data.filter(item => item.author._id === this.props.userFilter);
      }

      data.forEach((time) => {
        total = total + (time.rate / 60) * time.total_time;
      });
      return (
        <Segment basic className="no-pad">
            {this.props.userFilter !== "" && <Message warning><Message.Header>ONLY SHOWING FILTERED USERS TIMES</Message.Header> You are filtering this ticket based on a specific user, there might be more time assigned to this ticket by other users.</Message>}
          <Grid stackable celled className="details no-marg">
            <Grid.Row>
              <Grid.Column width={8}>
                <p>
                  Contact:
                  <span>
                    {this.state.data.contact
                      ? this.state.data.contact.name
                      : "[NONE]"}
                  </span>
                </p>
              </Grid.Column>
              <Grid.Column width={8}>
                <p>
                  Total:
                  <span>£{total.toFixed(2)}</span>
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid celled className="no-marg">
            <Grid.Row columns="equal" className="gridHeader">
              <Grid.Column>Engineer</Grid.Column>
              <Grid.Column>Time Type</Grid.Column>
              <Grid.Column>Location</Grid.Column>
              <Grid.Column width="1">Hours</Grid.Column>
              <Grid.Column width="1">Rate</Grid.Column>
              <Grid.Column width="2">Line Total</Grid.Column>
            </Grid.Row>
            {data.map((time) => {
              let sub = (time.rate / 60) * time.total_time;
              return (
                <React.Fragment key={time._id}>
                  <Grid.Row columns="equal">
                    <Grid.Column>
                      {time.author ? time.author.fullName : "--"}
                    </Grid.Column>
                    <Grid.Column>
                      <div className="splitHeader">
                        <p>{time.bill_type}</p>
                        <Button
                          icon
                          onClick={() => this.changeEngineerType(time)}
                        >
                          <Icon name="exchange" />
                        </Button>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="splitHeader">
                        <p>{time.location}</p>
                        {time.bill_type !== "Helpdesk (Legacy)" && (
                          <Button icon onClick={() => this.changeLoc(time)}>
                            <Icon name="exchange" />
                          </Button>
                        )}
                      </div>
                    </Grid.Column>
                    <Grid.Column width="1">
                      {(time.total_time / 60).toFixed(2)}
                    </Grid.Column>
                    <Grid.Column width="1">{time.rate}</Grid.Column>
                    <Grid.Column width="2">£ {sub.toFixed(2)}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ borderBottom: "3px solid black" }}>
                    <Grid.Column width="16">{time.note}</Grid.Column>
                  </Grid.Row>
                </React.Fragment>
              );
            })}
            <Grid.Row>
              <Grid.Column>
                <div className="splitHeader">
                  {this.state.update && (
                    <Button negative onClick={() => this.props.close()}>
                      Cancel
                    </Button>
                  )}
                  {this.state.update && (
                    <Button positive onClick={this.updateTimes}>
                      Update
                    </Button>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      );
    }
  }
}