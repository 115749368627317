import React, { Component } from "react";
import { Form, Segment } from "semantic-ui-react";
import { toast } from "react-toastify";

export default class deleteUserForm extends Component {
  state = {
    data: {
      confirm: "",
    },
  };

  onSubmit = () => {
    if (this.state.data.confirm === "DELETE") {
      this.props.submit(this.state.data);
    } else {
      toast.error("Bruh You Can't Spell.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="confirm"
              label={'Please Enter "DELETE" Exactly As Shown'}
              value={this.state.data.confirm}
              onChange={this.handleChange}
              placeholder="DELETE"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              content="Cancel"
              icon="close"
              type="submit"
              color="red"
              floated="left"
              onClick={() => {
                this.props.close();
              }}
            />

            <Form.Button
              content="Confirm"
              icon="ban"
              type="submit"
              color="orange"
              floated="right"
              onClick={() => this.onSubmit()}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
