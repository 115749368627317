import React, { Component } from "react";
import {
  Table,
  Segment,
  Header,
  Statistic,
  Grid,
  Modal,
  Button,
  Form,
  Message,
} from "semantic-ui-react";
import CountUp from "react-countup";
import { toast } from "react-toastify";
import api from "../../../../../web_app/src/actions/api";
import FilteredPagedList from "../../common/lists/filteredPagedList";
import moment from "moment";

export default class clientTimeManagement extends Component {
  state = {
    company: {},
    minutes: 0,
    showModal: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    api.company
      .getCompany(this.props.company)
      .then((data) => {
        this.setState({ company: data });
      })
      .catch((err) => {
        toast.error("Error Fetching Company Data", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }

  renderModal() {
    let handleConfirm = (type) => {
      api.company.company_helpdesk_time_management
        .adjust_time(this.props.company, { type, time: this.state.minutes })
        .then(() => {
          this.setState({ minutes: 0 }, () => {
            this.getData();
            handleClose();
          });
        });
    };

    let handleClose = () => {
      this.setState({ showModal: false });
    };

    return (
      <Modal open={this.state.showModal}>
        <Modal.Header>
          Adjusting Helpdesk Time For: {this.state.company.name}
        </Modal.Header>
        <Modal.Content>
          <Message
            error
            icon="warning circle"
            header="Please Only Enter In Minutes"
          />
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                type={"number"}
                label="Enter Amount Of Time To Adjust By"
                placeholder="Enter Amount Of Time To Adjust By"
                value={this.state.minutes}
                action={
                  <Button.Group>
                    <Button
                      color="red"
                      icon="minus"
                      onClick={() => handleConfirm("Subtract")}
                    />
                    <Button
                      color="green"
                      icon="plus"
                      onClick={() => handleConfirm("Add")}
                    />
                  </Button.Group>
                }
                onChange={(e) => {
                  if (isNaN(e.target.value) === false && e.target.value >= 0) {
                    this.setState({ minutes: e.target.value });
                  }
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            content="Cancel"
            icon="close"
            onClick={handleClose}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic>
        {this.renderModal()}
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header>Client Time Management</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Statistic.Group widths="1">
                <Statistic>
                  <Statistic.Value>
                    <CountUp
                      duration={4}
                      start={0}
                      end={
                        this.state.company.helpdesk_time !== undefined
                          ? this.state.company.helpdesk_time
                          : 0
                      }
                    />
                  </Statistic.Value>
                  <Statistic.Label>Available Time</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header>
                <Button
                  fluid
                  color="green"
                  content="Adjust Time"
                  icon="balance scale"
                  onClick={() => this.setState({ showModal: true })}
                />

                <FilteredPagedList
                  search={false}
                  header={() => {
                    return (
                      <Table.Row>
                        <Table.HeaderCell>Created At</Table.HeaderCell>
                        <Table.HeaderCell>Created By</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Details</Table.HeaderCell>
                      </Table.Row>
                    );
                  }}
                  body={(data) => {
                    return (
                      <Table.Row
                        className="pointer"
                        key={data._id}
                        onClick={() => {
                          this.props.history.push(`/clients/show/${data._id}`);
                        }}
                      >
                        <Table.Cell>
                          {moment
                            .utc(data.createdAt)
                            .format("DD/MM/YYYY HH:mm")}
                        </Table.Cell>
                        <Table.Cell>
                          {data.createdBy && data.createdBy.fullName
                            ? data.createdBy.fullName
                            : "[ERROR]"}
                        </Table.Cell>
                        <Table.Cell>{data.type}</Table.Cell>
                        <Table.Cell>{data.time}</Table.Cell>
                        <Table.Cell>{data.details}</Table.Cell>
                      </Table.Row>
                    );
                  }}
                  noItems={() => {
                    return (
                      <Table.Row key="1">
                        <Table.Cell colSpan={4}>
                          There Are No Notes To Display...
                        </Table.Cell>
                      </Table.Row>
                    );
                  }}
                  query={(page, size, searchQuery, filters) => {
                    return new Promise((resolve, reject) => {
                      api.company.company_helpdesk_time_management
                        .get_paged_list(page, size, searchQuery, filters)
                        .then((data) => {
                          resolve(data);
                        })
                        .catch((err) => {
                          reject(err);
                        });
                    });
                  }}
                />
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
