import React, { Component } from "react";
import { Form } from "semantic-ui-react";

const domainExtentionOptions = [
  { text: "1 Year", value: 1, key: 1 },
  { text: "2 Years", value: 2, key: 2 },
  { text: "3 Years", value: 3, key: 3 },
  { text: "4 Years", value: 4, key: 4 },
  { text: "5 Years", value: 5, key: 5 },
];

class domainActionForm extends Component {
  state = {
    data: {
      new_renew_date: "",
      note: "",
    },
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };

  render() {
    return (
      <Form>
        {this.props.domainAction === "Renew" ? (
          <Form.Group widths="equal">
            <Form.Dropdown
              name="new_renew_date"
              label="Years To Extend By"
              defaultValue={this.state.data.new_renew_date}
              onChange={this.handleDropdownChange}
              placeholder="Please Select A Response"
              options={domainExtentionOptions}
              selection
            />
          </Form.Group>
        ) : (
          <React.Fragment />
        )}

        <Form.Group widths="equal">
          <Form.TextArea
            name="note"
            defaultValue={this.state.data.note}
            label="Notes"
            placeholder="Extra Details"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Button
            type="submit"
            color="red"
            floated="left"
            content="Cancel"
            icon="close"
            onClick={() => {
              this.props.close();
            }}
          />
          <Form.Button
            type="submit"
            color="green"
            floated="right"
            content="Submit"
            icon="checkmark"
            onClick={() => this.props.submit(this.state.data)}
          />
        </Form.Group>
      </Form>
    );
  }
}

export default domainActionForm;
