import React from "react";
import API from "../../actions/api";
import BuildNumber from "../../_buildNumber";
import {toast} from "react-toastify";
import {Button, Confirm, Segment} from "semantic-ui-react";

export default class FrontendUpdateChecker extends React.Component {
    checkInterval = 5;
    updateChecker = null;
    state = {
        showReloadWarning: false,
    };

    componentDidMount() {
        this.checkForUpdates();
        this.updateChecker = setInterval(() => {
            this.checkForUpdates();
        }, this.checkInterval * 60 * 1000);
    }
    checkForUpdates() {
        API._getBuildNumber().then((version) => {
            if (version.toString() !== BuildNumber.toString()) {
                toast.info((<span>
                      <p>There is a new version of the <strong>Liquid Helpdesk</strong> application. When you have a free moment please click the "Reload Application" button below to update to the latest version.</p>
                      <Button positive size={"small"} floated={"right"} onClick={() => this.setState({ showReloadWarning: true })}>Reload Application</Button>
                    </span>), {
                    position: "top-right",
                    autoClose: this.checkInterval * 60 * 1000 - 5,
                    closeOnClick: true,
                    pauseOnHover: true
                });
            }
        });
    }
    render() {
        if (this.state.showReloadWarning) {
            return (
                <Confirm
                    open
                    cancelButton={"No, go back. I'll update later"}
                    confirmButton={"I accept, Please reload the application"}
                    content={
                        <Segment basic textAlign={"center"}>
                            <h3 style={{ color: "red" }}>
                                WARNING
                                <br />
                                Please ensure your latest entry has been saved prior to accepting this system update
                            </h3>{" "}
                            <br />
                            Any unsaved changes will be lost during the system update
                        </Segment>
                    }
                    onCancel={() => {
                        this.setState({ showReloadWarning: false });
                    }}
                    onConfirm={() => {
                        // eslint-disable-next-line
                        window.location = "/";
                    }}
                />
            );
        }
        return null;
    }
}