import React, { Component } from "react";
import {
  Header,
  Button,
  Modal,
  Table,
  Segment,
  Confirm,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import moment from "moment";
import AddForm from "./Forms/addDomainForm";
import EditDomainForm from "./Forms/editDomainForm";
import ViewLog from "./Forms/viewLogsForm";
import api from "../../../actions/api";

export default class companyContacts extends Component {
  state = {
    loading: true,
    data: [],
    toggle_add: false,
    toggle_edit: false,
    toggle_delete: false,
    toggle_view_logs: false,
    view_log: {},
    delete: {},
    edit: {},
  };

  componentDidMount() {
    this.getData();
  }

  /** API Requests */
  getData() {
    api.company.domains
      .getAll(this.props.iddata)
      .then((data) => {
        this.setState({
          data: data,
        });
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  onDomainAddSubmit = (data) => {
    return api.company.domains.add(this.props.iddata, data).then(() => {
      toast.success("Domain Added Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
  };

  onDomainEditSubmit = (data) => {
    return api.company.domains
      .update(this.props.iddata, this.state.edit._id, data)
      .then(() => {
        toast.success("Domain Updated Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  onDomainDeleteSubmit = () => {
    api.company.domains
      .remove(this.props.iddata, this.state.delete._id)
      .then(() => {
        toast.success("Domain Deleted Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.handleDeleteClose(true);
      })
      .catch((err) => {
        toast.error("Domain failed to delete", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.handleDeleteClose(true);
      });
  };

  /** Handlers */
  handleEditClose = (reload = false) => {
    this.setState({ toggle_edit: false });
    if (reload) {
      this.getData();
    }
  };

  handleAddClose = (reload = false) => {
    this.setState({ toggle_add: false });
    if (reload) {
      this.getData();
    }
  };

  handleEdit(domain) {
    this.setState({
      toggle_edit: true,
      edit: { ...domain },
    });
  }

  handleDeleteClose = (reload = false) => {
    this.setState({
      toggle_delete: false,
      delete: {},
    });
    if (reload) {
      this.getData();
    }
  };

  handleDelete(data) {
    this.setState({
      toggle_delete: true,
      delete: { ...data },
    });
  }

  handleList(domain) {
    this.setState({
      toggle_view_logs: true,
      view_log: { ...domain },
    });
  }

  /** Renderers */
  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.toggle_delete}
        content={
          "Are you sure you like to remove the domain: " +
          (this.state.delete.domain !== undefined
            ? this.state.delete.domain
            : "") +
          "?"
        }
        onCancel={this.handleDeleteClose}
        onConfirm={this.onDomainDeleteSubmit}
      />
    );
  }

  renderAddModal() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_add}
        onClose={() => this.handleAddClose(false)}
        basic
        size="small"
      >
        <Header icon="at" content="Add New Domain" />
        <Modal.Content>
          <AddForm
            submit={this.onDomainAddSubmit}
            close={this.handleAddClose}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderEditModal() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_edit}
        onClose={() => this.handleEditClose(false)}
        size="small"
      >
        <Header icon="at" content="Edit Domain" />
        <Modal.Content>
          <EditDomainForm
            submit={this.onDomainEditSubmit}
            close={this.handleEditClose}
            data={this.state.edit}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderViewLog() {
    const handleClose = () =>
      this.setState({ toggle_view_logs: false, view_log: {} });

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_view_logs}
        onClose={() => handleClose()}
        closeIcon
        size="small"
      >
        <Header
          icon="list"
          content={`Viewing Action Log For: ${this.state.view_log.domain}`}
        />
        <Modal.Content scrolling>
          <ViewLog data={this.state.view_log} />
        </Modal.Content>
      </Modal>
    );
  }

  renderData() {
    let renderBody = this.state.data.map((data) => {
      return (
        <Table.Row key={data._id}>
          <Table.Cell>{data.domain}</Table.Cell>
          <Table.Cell>{data.registrar || "[Not Managed]"}</Table.Cell>
          <Table.Cell>
            {data.expires !== null
              ? moment(data.expires).format("DD/MM/YYYY")
              : "[Not Managed]"}
          </Table.Cell>
          <Table.Cell textAlign="center">
            <Button
              circular
              icon="pencil"
              color="yellow"
              onClick={() => {
                this.handleEdit(data);
              }}
            />
            <Button
              circular
              icon="list"
              color="blue"
              onClick={() => {
                this.handleList(data);
              }}
            />
            <Button
              circular
              icon="close"
              color="red"
              onClick={() => {
                this.handleDelete(data);
              }}
            />
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Domain</Table.HeaderCell>
            <Table.HeaderCell>Registrar</Table.HeaderCell>
            <Table.HeaderCell>Expires</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.data.length !== 0 ? (
            renderBody
          ) : (
            <Table.Row>
              <Table.Cell>No Data</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  render() {
    return (
      <Segment basic loading={this.state.loading}>
        {this.renderAddModal()}
        {this.renderEditModal()}
        {this.renderDeleteConfirm()}
        {this.renderViewLog()}
        <Header>
          Domains
          <Button
            floated="right"
            icon="plus"
            style={{ color: "#21ba45" }}
            circular
            onClick={() =>
              this.setState({ toggle_add: !this.state.toggle_add })
            }
          />
        </Header>
        {this.renderData()}
      </Segment>
    );
  }
}
