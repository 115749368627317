import React, { Component } from "react";
import { Container, Tab } from "semantic-ui-react";
import CompanyContact from "./SubMenu/companyContacts";
import ActiveTickets from "./SubMenu/activeTickets";
import InactiveTickets from "./SubMenu/inactiveTickets";
import NotesTab from "./SubMenu/notesTab";
// import SensitiveNotes from "./SubMenu/sensitiveNotes";
import Domains from "./SubMenu/domains";
import AdditionalServices from "./SubMenu/additionalServices";
import WebHosting from "./SubMenu/webHosting";
import ClientTimeManagement from "./SubMenu/clientTimeManagement";

class ShowCompanySubMenu extends Component {
  render() {
    const panes = [
      {
        menuItem: "Client Helpdek Time Management",
        render: () => (
          <Tab.Pane attached={false}>
            <ClientTimeManagement
              company={this.props.iddata}
              history={this.props.history}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Active Tickets",
        render: () => (
          <Tab.Pane attached={false}>
            <ActiveTickets
              iddata={this.props.iddata}
              history={this.props.history}
              data={this.props.data}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Inactive Tickets",
        render: () => (
          <Tab.Pane attached={false}>
            <InactiveTickets
              iddata={this.props.iddata}
              history={this.props.history}
              data={this.props.data}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Notes",
        render: () => (
          <Tab.Pane attached={false}>
            <NotesTab iddata={this.props.iddata} history={this.props.history} />
          </Tab.Pane>
        ),
      },
      // {
      //   menuItem: "Sensitive Notes",
      //   render: () => (
      //     <Tab.Pane attached={false}>
      //       <SensitiveNotes history={this.props.history} />
      //     </Tab.Pane>
      //   )
      // },
      {
        menuItem: "Contacts",
        render: () => (
          <Tab.Pane attached={false}>
            <CompanyContact iddata={this.props.iddata} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Domains",
        render: () => (
          <Tab.Pane attached={false}>
            <Domains data={this.props.data} iddata={this.props.iddata} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Additional Services",
        render: () => (
          <Tab.Pane attached={false}>
            <AdditionalServices iddata={this.props.iddata} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Hosting Packages",
        render: () => (
          <Tab.Pane attached={false}>
            <WebHosting iddata={this.props.iddata} />
          </Tab.Pane>
        ),
      },
    ];
    return (
      <React.Fragment>
        <Container className="formpad" fluid>
          <Tab
            menu={{
              style: {
                display: "flex",
                flexWrap: "wrap",
              },
              inverted: true,
              attached: true,
            }}
            panes={panes}
          />
        </Container>
      </React.Fragment>
    );
  }
}

export default ShowCompanySubMenu;
