import React                                                                from "react";
import {List, Segment, Button, Input, Form, Item, Header, Divider, Message} from "semantic-ui-react";

export default class SettingList extends React.Component {
    state = {
        textvalue: "",
        formData: []
    };

    componentDidMount(){
        this.setState({
            formData: this.props.data
        });
    }
    componentDidUpdate(prevProps){
        if (this.props.data !== prevProps.data){
            this.setState({
                formData: this.props.data
            });
        }
    }
    handleChange = e => {
        this.setState({
            textvalue: e.target.value
        });
    };
    handleSubmit = () => {
        let fd = this.state.formData;
        fd.push(this.state.textvalue);
        this.setState({
            formData: fd,
            textvalue: ""
        });
        let field   = this.props.field;
        const data  = {};
        data[field] = this.state.formData;
        this.props.submit(data);
    };
    handleDelete = obj => {
        let newList = this.state.formData.filter((item, i) => {
            return obj !== item;
        });
        this.setState({
            formData: newList
        });
        let field   = this.props.field;
        const data  = {};
        data[field] = newList;
        this.props.delete(data);
    };
    render(){
        const form = (
            <Segment basic>
                <Form onSubmit={this.handleSubmit}>
                    <Input action={{icon: "plus", color: "green"}} onChange={this.handleChange} value={this.state.textvalue} placeholder={this.props.placeholder} fluid/>
                </Form>
            </Segment>
        );
        let list   = [];
        if (this.props.data){
            list = this.props.data.map((item, i) => {
                return (
                    <React.Fragment key={i}>
                        <Divider/>
                        <List.Item key={i}>
                            {item}
                            <Button circular icon="close" floated="right" size="tiny" color="red" onClick={() => this.handleDelete(item)}/>
                        </List.Item>
                    </React.Fragment>
                );
            });
        }
        return (
            <Segment basic>
                <Header>{this.props.title}</Header>
                <Divider/>
                {form}
                {list.length ? <Item>
                    <List className="dataList no-pad" verticalAlign="middle">
                        {list}
                    </List>
                </Item> : <Message info><Message.Header>No Items</Message.Header>There are no items to display</Message>}
            </Segment>
        );
    }
}