import React, { Component } from "react";
import {
  Segment,
  Header,
  Modal,
  Container,
  Transition,
  Button,
  Tab,
  Table,
  Loader,
} from "semantic-ui-react";
import api from "../../actions/api";
import AddForm from "./Forms/addTicketForm";
import { toast } from "react-toastify";
import FilteredPagedList from "../common/lists/filteredPagedList";
// import UnassignedTable from "./unassignedTable";
// import UserTicketTable from "./userTicketTable";
import moment from "moment";

export default class index extends Component {
  state = {
    activeIndex: 0,
    onLoad: { visible: false },
    loading: false,
    toggle_add: false,
  };

  componentDidMount() {
    this.setState({
      onLoad: { visible: true },
    });
  }

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  handleRangeChange = (e) => this.setState({ activeIndex: e.target.value });

  handleChange = () => {
    this.setState({ toggle_add: !this.state.toggle_add });
  };

  renderModal() {
    const handleClose = () => {
      this.setState({ toggle_add: false, loading: false });
    };

    const onSubmit = (data) => {
      this.setState({ loading: true });
      return api.tickets
        .addTicket(data)
        .then(() => {
          handleClose();
          toast.success("Ticket Added Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_add}
        onClose={handleClose}
      >
        <Header icon="ticket" content="Add New Ticket" />
        <Modal.Content>
          <AddForm submit={onSubmit} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  renderTicketsHeader = () => {
    return (
      <Table.Row>
        <Table.HeaderCell>Ticket No.</Table.HeaderCell>
        <Table.HeaderCell>Created At</Table.HeaderCell>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>Title</Table.HeaderCell>
        <Table.HeaderCell>Client</Table.HeaderCell>
        <Table.HeaderCell>Contact</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Assigned</Table.HeaderCell>
        <Table.HeaderCell>Total Activity</Table.HeaderCell>
      </Table.Row>
    );
  };
  renderTicketsBody = (data) => {
    return (
      <Table.Row
        className="pointer"
        key={data._id}
        onClick={() => {
          this.props.history.push(`/tickets/show/${data._id}`);
        }}
      >
        <Table.Cell>{data._id}</Table.Cell>
        <Table.Cell>{moment(data.createdDate).format("DD-MM-YYYY")}</Table.Cell>
        <Table.Cell>{data.ticketType}</Table.Cell>
        <Table.Cell>{data.title}</Table.Cell>
        <Table.Cell>
          {data.company ? data.company.name : "[UNDEFINED]"}
        </Table.Cell>
        <Table.Cell>{data.contact ? data.contact.name : "N/A"}</Table.Cell>
        <Table.Cell>{data.status}</Table.Cell>
        <Table.Cell>
          {data.assigned !== undefined && data.assigned !== null
            ? data.assigned.fullName
            : "[UNASSIGNED]"}
        </Table.Cell>
        <Table.Cell>{data._totalActivity}</Table.Cell>
      </Table.Row>
    );
  };
  renderTicketsEmpty = () => {
    return (
      <Table.Row key="1">
        <Table.Cell colSpan={9}>There Are No Tickets To Display...</Table.Cell>
      </Table.Row>
    );
  };

  render() {
    // const panes = [
    //   {
    //     menuItem: "Your Tickets",
    //     render: () => (
    //       <Tab.Pane>
    //         <UserTicketTable history={this.props.history} />
    //       </Tab.Pane>
    //     ),
    //   },
    //   {
    //     menuItem: "All Tickets",
    //     render: () => (
    //       <Tab.Pane>
    //         <FilteredPagedList
    //           header={() => {
    //             return (
    //               <Table.Row>
    //                 <Table.HeaderCell>Ticket No.</Table.HeaderCell>
    //                 <Table.HeaderCell>Created At</Table.HeaderCell>
    //                 <Table.HeaderCell>Type</Table.HeaderCell>
    //                 <Table.HeaderCell>Title</Table.HeaderCell>
    //                 <Table.HeaderCell>Client</Table.HeaderCell>
    //                 <Table.HeaderCell>Contact</Table.HeaderCell>
    //                 <Table.HeaderCell>Status</Table.HeaderCell>
    //                 <Table.HeaderCell>Assigned</Table.HeaderCell>
    //                 <Table.HeaderCell>Total Activity</Table.HeaderCell>
    //               </Table.Row>
    //             );
    //           }}
    //           body={(data) => {
    //             return (
    //               <Table.Row
    //                 className="pointer"
    //                 key={data._id}
    //                 onClick={() => {
    //                   this.props.history.push(`/tickets/show/${data._id}`);
    //                 }}
    //               >
    //                 <Table.Cell>{data._id}</Table.Cell>
    //                 <Table.Cell>
    //                   {moment(data.createdDate).format("DD-MM-YYYY")}
    //                 </Table.Cell>
    //                 <Table.Cell>{data.ticketType}</Table.Cell>
    //                 <Table.Cell>{data.title}</Table.Cell>
    //                 <Table.Cell>
    //                   {data.company ? data.company.name : "[UNDEFINED]"}
    //                 </Table.Cell>
    //                 <Table.Cell>
    //                   {data.contact ? data.contact.name : "[UNDEFINED]"}
    //                 </Table.Cell>
    //                 <Table.Cell>{data.status}</Table.Cell>
    //                 <Table.Cell>
    //                   {data.assigned !== undefined && data.assigned !== null
    //                     ? data.assigned.fullName
    //                     : "[UNASSIGNED]"}
    //                 </Table.Cell>
    //                 <Table.Cell>{data._totalActivity}</Table.Cell>
    //               </Table.Row>
    //             );
    //           }}
    //           noItems={() => {
    //             return (
    //               <Table.Row key="1">
    //                 <Table.Cell colSpan={9}>
    //                   There Are No Tickets To Display...
    //                 </Table.Cell>
    //               </Table.Row>
    //             );
    //           }}
    //           query={(page, size, searchQuery) => {
    //             return new Promise((resolve, reject) => {
    //               api.tickets
    //                 .getAllPaged(page, size, searchQuery)
    //                 .then((data) => {
    //                   resolve(data);
    //                 })
    //                 .catch((err) => {
    //                   reject(err);
    //                 });
    //             });
    //           }}
    //         />
    //       </Tab.Pane>
    //     ),
    //   },
    //   {
    //     menuItem: "Unassigned Tickets",
    //     render: () => (
    //       <Tab.Pane>
    //         <UnassignedTable history={this.props.history} />
    //       </Tab.Pane>
    //     ),
    //   },
    // ];

    const panes = [
      {
        menuItem: "Active Tickets",
        render: () => (
          <Tab.Pane>
            <h3>Active Tickets</h3>
            <FilteredPagedList
              key={1}
              header={this.renderTicketsHeader}
              body={this.renderTicketsBody}
              noItems={this.renderTicketsEmpty}
              query={(page, size, searchQuery) => {
                return new Promise((resolve, reject) => {
                  api.tickets
                    .getAllPaged(page, size, searchQuery, "open")
                    .then((data) => {
                      resolve(data);
                    })
                    .catch((err) => {
                      reject(err);
                    });
                });
              }}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Closed Tickets",
        render: () => (
          <Tab.Pane>
            <h3>Closed Tickets</h3>
            <FilteredPagedList
              key={2}
              header={this.renderTicketsHeader}
              body={this.renderTicketsBody}
              noItems={this.renderTicketsEmpty}
              query={(page, size, searchQuery) => {
                return new Promise((resolve, reject) => {
                  api.tickets
                    .getAllPaged(page, size, searchQuery, "closed")
                    .then((data) => {
                      resolve(data);
                    })
                    .catch((err) => {
                      reject(err);
                    });
                });
              }}
            />
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Container fluid>
        {this.renderModal()}
        <Segment inverted attached>
          <Header textAlign="center" as="h2">
            Ticket Menu
          </Header>
        </Segment>
        <Transition
          visible={this.state.onLoad.visible}
          duration={750}
          animation="fade"
        >
          <Segment className="Margins">
            <React.Fragment>
              <Button
                fluid
                size="large"
                onClick={() => this.setState({ toggle_add: true })}
                positive
                icon="plus"
                content="Add A Ticket"
              />
              {this.state.loading === false ? (
                <Tab
                  menu={{ pointing: true }}
                  panes={panes}
                  activeIndex={this.state.activeIndex}
                  onTabChange={this.handleTabChange}
                  className={"ticketMenuTabs"}
                />
              ) : (
                <Segment basic padded>
                  <Loader active></Loader>
                </Segment>
              )}
            </React.Fragment>
          </Segment>
        </Transition>
      </Container>
    );
  }
}
