import React from "react";
import Settings from "./Settings_Context";

const joinProps = (a, b) => {
    return {
        ...a,
        ...b
    }
}
export default (Compo, mapContext) => {
    return (props) => {
        return (
            <Settings.Consumer>{context => (
                <Compo {...joinProps(props, mapContext(context))} />
            )}</Settings.Consumer>
        );
    }
}


// export default class Settings_Receiver extends React.Component {
//
//     render(){
//         console.log("##state##", this.state);
//         console.log("##props##", this.props);
//
//         return this.props.children;
//     }
// }
// const test = (props) => {
//     console.log("##props##", props);
//     let item = props.children;
    // return (
    //     <p>test</p>
    // );
// }
// export default test;

// export default class Settings_Receiver extends React.Component {
//
//     render(){
//         let SingleChild = this.props.children;
//         return (
//             <Settings.Consumer>{context => (
//                 <SingleChild {...this.props} settings />
//             )}</Settings.Consumer>
//         )
//     }
// }


// export default (Component, PropName = "settings") => {
//     return (
//         <Settings.Consumer>{context => (
//             <Component />
//         )}</Settings.Consumer>
//     );
// }