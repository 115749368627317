import React, { Component } from "react";
import { Tab, Table, Button, Modal, Header } from "semantic-ui-react";
import DomainActionForm from "./forms/domainActionForm";
import PendingActionForm from "./forms/pendingActionForm";
import api from "../../actions/api";
import moment from "moment";

class domainBillingTable extends Component {
  state = {
    upcoming_renewals: [],
    upcoming_renewal_modal: false,
    upcoming_renewal_item: {},
    upcoming_renewal_action: "",
    pending_renewal_actions: [],
    pending_renewal_modal: false,
    pending_renewal_item: {},
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    Promise.all([
      api.billing.domain_renewals(),
      api.billing.pending_actions(),
    ]).then((res) =>
      this.setState({
        upcoming_renewals: res[0],
        pending_renewal_actions: res[1],
      })
    );
  }

  renderRenewalAction() {
    const handleClose = () => {
      this.setState({
        upcoming_renewal_modal: false,
        upcoming_renewal_item: {},
        upcoming_renewal_action: "",
      });
    };

    const handleSubmit = (data) => {
      api.company.domains.domain_actions
        .add(
          this.state.upcoming_renewal_item.company._id,
          this.state.upcoming_renewal_item._id,
          { ...data, pending_action: this.state.upcoming_renewal_action }
        )
        .then(() => {
          handleClose();
          this.getData();
        });
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.upcoming_renewal_modal}
        onClose={handleClose}
        size="small"
      >
        <Header
          icon="list alternate"
          content={`Managing Domain: ${this.state.upcoming_renewal_action}`}
        />
        <Modal.Content>
          <DomainActionForm
            submit={handleSubmit}
            close={handleClose}
            domainAction={this.state.upcoming_renewal_action}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderPendingActionHandle() {
    const handleClose = () => {
      this.setState({
        pending_renewal_modal: false,
        pending_renewal_item: {},
      });
    };

    const handleSubmit = (data) => {
      api.company.domains.domain_actions
        .update(
          this.state.pending_renewal_item.domain.company._id,
          this.state.pending_renewal_item.domain._id,
          this.state.pending_renewal_item._id,
          data
        )
        .then(() => {
          handleClose();
          this.getData();
        });
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.pending_renewal_modal}
        onClose={handleClose}
        size="small"
      >
        <Header
          icon="list alternate"
          content={`Confirming Action For Domain: ${
            this.state.pending_renewal_item.domain !== undefined
              ? this.state.pending_renewal_item.domain.domain
              : "UNDEFINED"
          }`}
        />
        <Modal.Content>
          <PendingActionForm
            submit={handleSubmit}
            close={handleClose}
            pendingAction={this.state.pending_renewal_item}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderUpcomingRenewals() {
    let renderBody = this.state.upcoming_renewals.map((item) => {
      let pendingActionCheck =
        this.state.pending_renewal_actions.filter(
          (pendingItem) =>
            pendingItem.domain._id === item._id &&
            pendingItem.completed === false
        ).length !== 0
          ? true
          : false;

      return (
        <Table.Row key={item._id}>
          <Table.Cell>{item.domain}</Table.Cell>
          <Table.Cell>{item.company.name}</Table.Cell>
          <Table.Cell>{item.registrar}</Table.Cell>
          <Table.Cell>{moment(item.expires).format("DD/MM/YYYY")}</Table.Cell>
          <Table.Cell textAlign="center" disabled={pendingActionCheck}>
            <Button
              color="green"
              content="Renew"
              icon="redo alternate"
              onClick={() =>
                this.setState({
                  upcoming_renewal_modal: true,
                  upcoming_renewal_item: item,
                  upcoming_renewal_action: "Renew",
                })
              }
              disabled={pendingActionCheck}
            />
            <Button
              color="red"
              content="Drop"
              icon="ban"
              onClick={() =>
                this.setState({
                  upcoming_renewal_modal: true,
                  upcoming_renewal_item: item,
                  upcoming_renewal_action: "Drop",
                })
              }
              disabled={pendingActionCheck}
            />
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Domain</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Registrar</Table.HeaderCell>
            <Table.HeaderCell>Expire Date</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderBody.length === 0 ? (
            <Table.Row>
              <Table.Cell colSpan="5">No Data</Table.Cell>
            </Table.Row>
          ) : (
            renderBody
          )}
        </Table.Body>
      </Table>
    );
  }

  renderPendingActions() {
    let renderBody = this.state.pending_renewal_actions.map((item) => {
      return (
        <Table.Row
          key={item._id}
          onClick={() =>
            this.setState({
              pending_renewal_modal: true,
              pending_renewal_item: item,
            })
          }
        >
          <Table.Cell>{item.domain.domain}</Table.Cell>
          <Table.Cell>{item.domain.company.name}</Table.Cell>
          <Table.Cell>{item.domain.registrar}</Table.Cell>
          <Table.Cell>
            {moment(item.domain.expires).format("DD/MM/YY")}
          </Table.Cell>
          <Table.Cell>
            {item.pending_action === "Renew"
              ? moment(item.new_renew_date).format("DD/MM/YY")
              : "[N/A]"}
          </Table.Cell>
          <Table.Cell>{item.pending_action}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Domain</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Registrar</Table.HeaderCell>
            <Table.HeaderCell>Expire Date</Table.HeaderCell>
            <Table.HeaderCell>Proposed Extension Date</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderBody.length === 0 ? (
            <Table.Row>
              <Table.Cell colSpan="6">No Data</Table.Cell>
            </Table.Row>
          ) : (
            renderBody
          )}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const panes = [
      {
        menuItem: `Upcoming Renewals (Until ${moment()
          .add(60, "days")
          .format("DD/MM/YYYY")})`,
        render: () => <Tab.Pane>{this.renderUpcomingRenewals()}</Tab.Pane>,
      },
      {
        menuItem: "Pending Actions",
        render: () => <Tab.Pane>{this.renderPendingActions()}</Tab.Pane>,
      },
    ];

    return (
      <React.Fragment>
        {this.renderRenewalAction()}
        {this.renderPendingActionHandle()}
        <Tab
          menu={{ secondary: true, pointing: true }}
          grid={{ tabWidth: 2, paneWidth: 14 }}
          panes={panes}
        />
      </React.Fragment>
    );
  }
}

export default domainBillingTable;
