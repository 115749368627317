import React, { Component } from "react";
import { Menu, Icon, Dropdown, Sticky, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Authentication } from "@liquidcomputing/liquid__reactjs-localauth";
import image from "../../../assets/LCLogo.png";

export default class Navbar extends Component {
  state = {
    activeItem: "",
  };
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;
    const userAccount = Authentication.getUserData();
    const fullName = userAccount.fullName;
    return (
      <React.Fragment>
        <Sticky>
          <Menu inverted attached>
            <Menu.Item className="navbarAlign">
              <Image
                src={image}
                style={{ paddingRight: "3px" }}
                alt="LQ Logo"
                size="mini"
              />
              Liquid Computing
              <br />
              Helpdesk
            </Menu.Item>
            <Menu.Item
              name="home"
              as={Link}
              to="/"
              active={activeItem === "home"}
              onClick={this.handleItemClick}
            >
              <Icon name="home" />
              Home
            </Menu.Item>
            <Menu.Item
              name="clients"
              as={Link}
              to="/clients"
              active={activeItem === "clients"}
              onClick={this.handleItemClick}
            >
              <Icon name="users" />
              Clients
            </Menu.Item>
            <Menu.Item
              name="tickets"
              as={Link}
              to="/tickets"
              active={activeItem === "tickets"}
              onClick={this.handleItemClick}
            >
              <Icon name="ticket" />
              Tickets
            </Menu.Item>
            <Menu.Item
              name="billing"
              as={Link}
              to="/billing"
              active={activeItem === "billing"}
              onClick={this.handleItemClick}
            >
              <Icon name="pound" />
              Billing
            </Menu.Item>
            <Menu.Item
              name="domain_lookup"
              as={Link}
              to="/domain_lookup"
              active={activeItem === "domain_lookup"}
              onClick={this.handleItemClick}
            >
              <Icon name="server" />
              Domain Lookup
            </Menu.Item>
            {/* <Menu.Item name="reports" as={Link} to="/reports" active={activeItem === "reports"} onClick={this.handleItemClick}>
              <Icon name="book" />
              Reports
            </Menu.Item> */}
            <Menu.Menu position="right">
              <Dropdown item text={fullName} className="dark_dropdown">
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.props.timerWidget}>
                    <Icon name="clock" />
                    Timer
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/admin">
                    <Icon name="calendar" />
                    Holidays
                  </Dropdown.Item>
                  {this.props.admin && (
                    <React.Fragment>
                      <Dropdown.Item as={Link} to="/admin">
                        <Icon name="eye" />
                        Admin
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/settings">
                        <Icon name="cog" />
                        Settings
                      </Dropdown.Item>
                    </React.Fragment>
                  )}
                  {/* <Dropdown.Item as={Link} to="/settings">
                        <Icon name="cog" />
                        Settings
                      </Dropdown.Item> */}
                  <Dropdown.Item onClick={() => Authentication.logout()}>
                    <Icon name="sign-out" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Menu>
        </Sticky>
      </React.Fragment>
    );
  }
}
