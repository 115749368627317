import React, { Component } from "react";
import { Form, Segment, Icon, Loader } from "semantic-ui-react";

const options = [
  { key: "admin", text: "Admin", value: "admin" },
  { key: "technician", text: "Technician", value: "technician" },
];

export default class editUserForm extends Component {
  state = {
    data: {
      fullName: "",
      email: "",
      password: "",
      groups: [],
    },
  };

  onSubmit = () => {
    this.props.submit(this.state.data);
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const vname = target.name;

    this.setState({ data: { ...this.state.data, [vname]: value } });
  }

  handleDropdownChange(e, data) {
    const vname = data.name;
    this.setState({ data: { ...this.state.data, [vname]: data.value } });
  }

  render() {
    if (this.props.data.length === 0) {
      return (
        <Loader size="big" active>
          <span role="img" aria-label="">
            🤔
          </span>
          Thinking...
        </Loader>
      );
    } else {
      return (
        <Segment>
          <Form onSubmit={this.onSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                name="fullName"
                label="Full Name"
                defaultValue={this.props.data.fullName}
                onChange={this.handleChange.bind(this)}
                placeholder="Full Name"
              />
              <Form.Input
                name="email"
                label="Email"
                defaultValue={this.props.data.email}
                onChange={this.handleChange.bind(this)}
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Dropdown
                name="groups"
                label="Groups"
                defaultValue={this.props.data.groups}
                onChange={this.handleDropdownChange.bind(this)}
                placeholder="Please Select A Response"
                options={options}
                multiple
                selection
                clearable
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="password"
                label="Password"
                type="password"
                defaultValue={this.props.data.password}
                onChange={this.handleChange.bind(this)}
                placeholder="Replacement Password"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Button
                type="submit"
                color="red"
                floated="left"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.close();
                }}
              >
                <Icon name="close" />
                Cancel
              </Form.Button>
              <Form.Button type="submit" color="green" floated="right">
                <Icon name="cloud upload" />
                Update
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      );
    }
  }
}
