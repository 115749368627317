import React from "react";

import {
  List,
  Segment,
  Button,
  Input,
  Form,
  Item,
  Header,
  Divider,
  Icon
} from "semantic-ui-react";

//TODO Tim make this ordered please
export default class SettingRateList extends React.Component {
  state = {
    data: {
      name: "",
      onsite: "",
      remote: "",
    },
    isEdit: false,
  };
  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };
  handleSubmit = () => {
    let fd = [].concat(this.props.data);
    if (this.state.data._id !== undefined) {
      fd = fd.map((item) => {
        if (item._id === this.state.data._id) {
          return {
            ...item,
            name: this.state.data.name,
            onsite: this.state.data.onsite,
            remote: this.state.data.remote,
          };
        }
        return item;
      });
    } else {
      fd.push({
        name: this.state.data.name,
        onsite: this.state.data.onsite,
        remote: this.state.data.remote,
      });
    }
    const data = {};
    data[this.props.field] = fd;
    this.props.submit(data).then(() => {
      this.setState({
        isEdit: false,
        data: {
          _id: undefined,
          name: "",
          onsite: "",
          remote: "",
        },
      });
    });
  };
  handleDelete = (obj) => {
    let newList = this.props.data.filter((item, i) => {
      return obj !== item;
    });
    const data = {};
    data[this.props.field] = newList;
    this.props.delete(data);
  };

  render() {
    const form = (
      <Segment basic>
        {!this.props.disabled && (
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Field width={6}>
                <Input
                  name="name"
                  disabled={this.state.data.default}
                  onChange={this.handleChange}
                  value={this.state.data.name}
                  placeholder={this.props.placeholder}
                />
              </Form.Field>

              <Form.Field width={4}>
                <Input
                  name="remote"
                  onChange={this.handleChange}
                  value={this.state.data.remote}
                  placeholder={"remote"}
                />
              </Form.Field>
              <Form.Field width={4}>
                <Input
                  name="onsite"
                  onChange={this.handleChange}
                  value={this.state.data.onsite}
                  placeholder={"onsite"}
                />
              </Form.Field>
              <Form.Field width={2}>
                <Button icon basic color="green">
                  {this.state.isEdit ? (
                    <Icon name="check" color="blue" />
                  ) : (
                    <Icon name="plus" color="green" />
                  )}
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>
        )}
      </Segment>
    );
    let list;
    if (this.props.data) {
      list = this.props.data.map((item, i) => {
        return (
          <List.Item key={i} className="listSpace">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ flex: 1 }}>{item.name}</span>
              <span style={{ flex: 1 }}>{item.remote}</span>
              <span style={{ flex: 1 }}>{item.onsite}</span>

              <Button
                circular
                icon="close"
                floated="right"
                size="tiny"
                color="red"
                onClick={() => this.handleDelete(item)}
              />
              {!this.props.disabled && (
                <Button
                  circular
                  icon="pencil"
                  floated="right"
                  size="tiny"
                  color="blue"
                  onClick={() => this.setState({ data: item, isEdit: true })}
                />
              )}
            </div>
            <Divider />
          </List.Item>
        );
      });
    }

    return (
      <Segment basic>
        <Header>{this.props.title}</Header>
        <Divider />
        {form}
        <Divider />
        <Item>
          <List.Item className="listSpace">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ flex: 1, color: "#e58f38" }}>Type</span>
              <span style={{ flex: 1, color: "#e58f38" }}>Remote</span>
              <span style={{ flex: 1, color: "#e58f38" }}>Onsite</span>

              <span style={{ flex: 1 }} />
            </div>
          </List.Item>
          <Divider />
          <List className="dataList">{list}</List>
        </Item>
      </Segment>
    );
  }
}
