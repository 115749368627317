import React, { Component } from "react";
import { Message, Icon } from "semantic-ui-react";

const messageHeaders = [
  "OwO What Is This?!",
  "Epic! Another Error :)",
  "This Is So Sad Alexa Play Despacito",
  "Stop Right There Criminal Scum",
  "It Was Bound To Happen At Some Point ",
  "YEET! It's an error message"
];

const messageBodies = [
  "No data can currently be displayed, it's safe to assume that Tim's to blame.",
  "No data can currently be displayed due to a network error.",
  "Helpdesk machine broke.",
  "Have you tried turing it off and on again?",
  "Make some tea and hope it's fixed by then."
];

export default class errorMessageGenerator extends Component {
  messageSelector() {
    var RandHead =
      messageHeaders[Math.floor(Math.random() * messageHeaders.length)];

    var RandBody =
      messageBodies[Math.floor(Math.random() * messageBodies.length)];

    return (
      <React.Fragment>
        <Message.Header>{RandHead}</Message.Header>
        {RandBody}
      </React.Fragment>
    );
  }

  render() {
    return (
      <Message icon negative>
        <Icon name="meh outline" />
        <Message.Content>{this.messageSelector()}</Message.Content>
      </Message>
    );
  }
}
