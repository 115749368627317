import React, { Component } from "react";
import { Table, Segment, Button, Modal, Header } from "semantic-ui-react";
import DeleteForm from "../Forms/deleteForm";
import api from "../../../../actions/api";
import { toast } from "react-toastify";

export default class ticketTable extends Component {
  state = {
    data: [],
    toggle_delete: false,
    current_id: "",
  };

  renderTable() {
    if (this.props.data.length === 0) {
      return (
        <Table.Row key={1}>
          <Table.Cell>No Data</Table.Cell>
        </Table.Row>
      );
    } else {
      const tickets = this.props.data.map((data) => {
        return (
          <Table.Row key={data._id}>
            <Table.Cell>{data.title}</Table.Cell>
            <Table.Cell>
              {data.company ? data.company.name : "Unassigned"}
            </Table.Cell>
            <Table.Cell>
              {data.contact ? data.contact.name : "Unassigned"}
            </Table.Cell>
            <Table.Cell>{data.status}</Table.Cell>
            <Table.Cell width="3">
              <Button
                fluid
                onClick={() =>
                  this.setState({
                    toggle_delete: !this.state.toggle_delete,
                    current_id: data._id,
                  })
                }
                color="red"
              >
                Delete Ticket
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
      return tickets;
    }
  }

  handleClose() {
    if (this.state.toggle_delete === true) {
      this.setState({ toggle_delete: false, data: [] });
    }
  }

  onSubmit = (data) => {
    return api.tickets
      .deleteTicket(this.state.current_id)
      .then(() => {
        this.renderToast();
        this.props.getData();
        this.handleClose();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  renderToast() {
    if (this.state.toggle_delete === true) {
      toast.warn("Ticket Deleted Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  renderModalDelete() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_delete}
        onClose={this.handleClose.bind(this)}
        basic
        size="small"
      >
        <Header icon="user" content="Deleting Ticket" />
        <Modal.Content>
          <DeleteForm
            submit={this.onSubmit}
            close={this.handleClose.bind(this)}
          />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderModalDelete()}
        <Segment basic>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Ticket Title</Table.HeaderCell>
                <Table.HeaderCell>Client</Table.HeaderCell>
                <Table.HeaderCell>Contact</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderTable()}</Table.Body>
          </Table>
        </Segment>
      </React.Fragment>
    );
  }
}
