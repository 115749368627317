import React, { Component } from "react";
import { Segment, Form, Icon } from "semantic-ui-react";
import api from "../../../../actions/api";

export default class editNoteForm extends Component {
  state = {
    data: {
      message: ""
    }
  };

  componentDidMount() {
    if (this.props.idnumb !== "") {
      api.company.company_notes
        .getNote(this.props.iddata, this.props.idnumb)
        .then(data => {
          this.setState({ data: data });
        })
        .catch(err => {
          console.log(err.response);
        });
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const vname = target.name;

    this.setState({ data: { ...this.state.data, [vname]: value } });
  }

  onSubmit = () => {
    this.props.submit(this.state.data);
  };

  render() {
    return (
      <Segment>
        <Form onSubmit={this.onSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="message"
              value={this.state.data.message}
              label="Note"
              placeholder="Edit Note Here"
              onChange={this.handleChange.bind(this)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              type="submit"
              color="red"
              floated="left"
              onClick={e => {
                e.preventDefault();
                this.props.close();
              }}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button type="submit" color="green" floated="right">
              <Icon name="cloud upload" />
              Update
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
