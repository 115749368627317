import React, { Component } from "react";
import { Table, Segment, Header, Button, Icon, Modal } from "semantic-ui-react";
import AddForm from "./Forms/assignServiceForm";
import api from "../../../actions/api";

class additionalServices extends Component {
  state = {
    data: {},
    toggle_add: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    api.company
      .getCompany(this.props.iddata)
      .then((data) => {
        this.setState({ data: data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  renderModalAdd() {
    const handleClose = () => this.setState({ toggle_add: false });

    const handleSubmit = (additional_services) => {
      api.company
        .updateCompany(this.props.iddata, {
          additional_services,
        })
        .then(() => this.setState({ toggle_add: false }, () => this.getData()));
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_add}
        onClose={handleClose}
        size="small"
      >
        <Header icon="file alternate" content="Assign Additional Services" />
        <Modal.Content>
          <AddForm
            submit={handleSubmit}
            close={handleClose}
            currentlyAssigned={
              this.state.data.additional_services !== undefined &&
              this.state.data.additional_services.length !== 0
                ? this.state.data.additional_services.map((item) => {
                    return item._id;
                  })
                : []
            }
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderBody() {
    return this.state.data.additional_services.map((item) => {
      return (
        <Table.Row key={item._id}>
          <Table.Cell>{item.service}</Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <Segment basic>
        {this.renderModalAdd()}
        <Header>
          Additional Services
          <Button
            circular
            icon
            floated="right"
            onClick={() => this.setState({ toggle_add: true })}
          >
            <Icon name="plus" color="green"></Icon>
          </Button>
        </Header>
        <Table celled columns="8">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Service</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.data.additional_services !== undefined &&
            this.state.data.additional_services.length !== 0 ? (
              this.renderBody()
            ) : (
              <Table.Row>
                <Table.Cell>No Additional Services Assigned</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default additionalServices;
