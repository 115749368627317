import React, { Component } from "react";
import { Form, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";

const typeoptions = [
  { key: "user_reponse", value: "user_response", text: "User Response" },
  { key: "note", value: "note", text: "Note" }
];

export default class replyForm extends Component {
  state = {
    data: {
      type: "",
      message: ""
    }
  };

  handleSubmit() {
    if (this.state.data.message !== "" && this.state.data.type !== "") {
      this.props.submit(this.state.data);
      this.setState({ data: { message: "", type: "" } });
    } else {
      toast.error("Missing Required Field", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const vname = target.name;

    this.setState({ data: { ...this.state.data, [vname]: value } });
  }

  handleDropdownChange(e, data) {
    const vname = data.name;
    this.setState({ data: { ...this.state.data, [vname]: data.value } });
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="type"
            value={this.state.data.type}
            onChange={this.handleDropdownChange.bind(this)}
            placeholder="Please select a message type"
            options={typeoptions}
            selection
            clearable
          />
        </Form.Group>
        {this.state.data.type !== "" ? (
          <React.Fragment>
            <Form.Group widths="equal">
              <Form.TextArea
                name="message"
                value={this.state.data.message}
                onChange={this.handleChange.bind(this)}
                placeholder="Enter Information Regarding Ticket"
              />
            </Form.Group>
            <Form.Button fluid type="submit" color="green">
              <Icon name="plus" />
              Add
            </Form.Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Form.Group widths="equal">
              <Form.TextArea
                disabled
                name="message"
                value={this.state.data.message}
                onChange={this.handleChange.bind(this)}
                placeholder=""
              />
            </Form.Group>
            <Form.Button fluid type="submit" disabled>
              <Icon name="plus" />
              Add
            </Form.Button>
          </React.Fragment>
        )}
      </Form>
    );
  }
}
