import React, { Component } from "react";
import {
  Segment,
  Button,
  Icon,
  Loader,
  Header,
  Modal,
  Table,
  Grid,
  Container,
  Transition,
  Statistic,
  Divider,
} from "semantic-ui-react";
import api from "../../actions/api";
import EditForm from "./Forms/editTicketForm";
import EditTimeForm from "./Forms/editTimeSpent";
import { toast } from "react-toastify";
import Moment from "react-moment";
import ShowTicketSubMenu from "./showTicketSubMenu";
import moment from "moment";

export default class viewTicket extends Component {
  state = {
    data: [],
    activityData: [],
    toggle_edit: false,
    toggleTime_edit: false,
    edit_item: {},
    onLoad: { visible: false },
    param_data: {},
  };

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const params = Object.fromEntries(urlSearchParams.entries());

    if (Object.keys(params).length > 0) {
      this.setState({ toggleTime_edit: true, param_data: params });
    }

    this.setState({
      onLoad: { visible: true },
    });
    this.getData();
  }

  getData() {
    api.tickets
      .getTicket(this.props.match.params.id)
      .then((data) => {
        this.setState({ data: data });
      })
      .catch((err) => {
        console.log(err.response);
      });

    api.tickets
      .getActivity(this.props.match.params.id)
      .then((data) => {
        this.setState({ activityData: data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  handleClose() {
    if (this.state.toggle_edit === true) {
      this.setState({ toggle_edit: false });
    }

    if (this.state.toggleTime_edit === true) {
      this.setState({ toggleTime_edit: false, edit_item: {} }, () => {
        if (Object.keys(this.state.param_data).length > 0) {
          this.props.history.push(
            `/tickets/show/${this.props.match.params.id}`
          );
        }
      });
    }
  }

  onSubmit = (data) => {
    if (this.state.toggle_edit === true) {
      return (
        api.tickets
          .updateTicketStatus(this.props.match.params.id, data)
          .catch((err) => {
            console.log(err.response);
          }),
        api.tickets
          .updateTicketAssignment(this.props.match.params.id, data)
          .catch((err) => {
            console.log(err.response);
          }),
        api.tickets
          .updateTicket(this.props.match.params.id, data)
          .then(() => {
            this.renderToast();
            this.handleClose();
            this.getData();
          })
          .catch((err) => {
            console.log(err.response);
          })
      );
    }
  };

  onTimeSubmit = (data) => {
    if (this.state.edit_item && Object.keys(this.state.edit_item).length > 0) {
      return api.tickets
        .editTime(this.props.match.params.id, this.state.edit_item._id, data)
        .then(() => {
          this.renderToast();
          this.handleClose();
          this.getData();
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      return api.tickets
        .updateTime(this.props.match.params.id, data)
        .then(() => {
          this.renderToast();
          this.handleClose();
          this.getData();
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  activitySubmit = (data) => {
    return api.tickets
      .newActivity(this.props.match.params.id, data)
      .then(() => {
        this.getData();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  renderModal() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_edit}
        onClose={this.handleClose.bind(this)}
      >
        <Header icon="file alternate" content="Edit Ticket" />
        <Modal.Content>
          <EditForm
            submit={this.onSubmit}
            close={this.handleClose.bind(this)}
            data={this.state.data}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderModalTime() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggleTime_edit}
        onClose={this.handleClose.bind(this)}
      >
        <Header icon="file alternate" content="Assign Time Spent" />
        <Modal.Content>
          <EditTimeForm
            submit={this.onTimeSubmit}
            close={this.handleClose.bind(this)}
            data={this.state.data}
            param_data={this.state.param_data}
            edit_item={this.state.edit_item}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderToast() {
    toast.info("Data Updated Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }

  renderCCData() {
    const data = this.state.data.cc_contacts;
    return data.map((data) => {
      return <p key={data._id}>{data.name}</p>;
    });
  }

  renderSpentTime() {
    if (this.state.data.times.length === 0) {
      return (
        <Table.Row key={1} colSpan={6}>
          <Table.Cell>No Data</Table.Cell>
        </Table.Row>
      );
    } else {
      return this.state.data.times.map((data) => {
        return (
          <React.Fragment key={data._id}>
            <Table.Row>
              <Table.Cell>
                <Moment format="DD/MM/YYYY">{data.date}</Moment>
              </Table.Cell>
              <Table.Cell>
                {data.author !== null ? data.author.fullName : ""}
              </Table.Cell>
              <Table.Cell>{data.bill_type}</Table.Cell>
              <Table.Cell>{data.location}</Table.Cell>
              <Table.Cell>{data.total_time}</Table.Cell>
              <Table.Cell textAlign="center">
                <Button
                  color="blue"
                  icon="pencil"
                  onClick={() =>
                    this.setState({ toggleTime_edit: true, edit_item: data })
                  }
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell
                style={{ borderBottom: "2px solid #ccc" }}
                colSpan={6}
              >
                {data.note ? data.note : ""}
              </Table.Cell>
            </Table.Row>
          </React.Fragment>
        );
      });
    }
  }

  renderDetailsText() {
    let priorLineBlank = false;
    return (
      <p>
        {this.state.data.details
          .split("\n")
          .map((item, key) => {
            if (item.trim() === "") {
              if (priorLineBlank) {
                return null;
              }
              priorLineBlank = true;
            } else {
              priorLineBlank = false;
            }
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            );
          })
          .filter((i) => i !== null)}
      </p>
    );
  }

  renderTicketInfo() {
    return (
      <Segment>
        <Grid celled stackable className="details">
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as={"h2"} floated="left">
                Ticket Information
              </Header>
              <Button
                primary
                floated={"right"}
                icon
                basic
                circular
                onClick={() =>
                  this.setState({
                    toggle_edit: !this.state.toggle_edit,
                  })
                }
              >
                <Icon name="pencil" color="blue" />
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header>{this.state.data.title}</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <p>
                Creation Date:{" "}
                {this.state.data.createdDate
                  ? moment
                      .utc(this.state.data.createdDate)
                      .format("DD-MM-YYYY HH:mm")
                  : "[ERROR READING DATE]"}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <p>
                Assigned To:
                <span>
                  {this.state.data.assigned
                    ? this.state.data.assigned.fullName
                    : "[UNDEFINED]"}
                </span>
              </p>
            </Grid.Column>
            <Grid.Column width={8}>
              <p>
                Ticket Type:
                <span>
                  {this.state.data.ticketType
                    ? this.state.data.ticketType
                    : "[UNDEFINED]"}
                </span>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <p>
                Contact Name:
                <span>
                  {this.state.data.contact
                    ? this.state.data.contact.name
                    : "[UNDEFINED]"}
                </span>
              </p>
            </Grid.Column>
            <Grid.Column width={8}>
              <p>
                Contact Phone:
                <span>
                  {this.state.data.contact
                    ? this.state.data.contact.phone_number
                    : "[UNDEFINED]"}
                </span>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <p>
                Contact Email:
                <span>
                  {this.state.data.contact
                    ? this.state.data.contact.email_address
                    : "[UNDEFINED]"}
                </span>
              </p>
            </Grid.Column>
            <Grid.Column width={8}>
              <p>
                Contact Company:
                <span>
                  {this.state.data.company
                    ? this.state.data.company.name
                    : "[UNDEFINED]"}
                </span>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  renderTime() {
    let totalRemoteTime = 0;
    let totalOnsiteTime = 0;
    let totalNonTime = 0;
    for (let index in this.state.data.times) {
      let time = this.state.data.times[index];
      switch (time.location) {
        case "Remote":
          totalRemoteTime = totalRemoteTime + time.total_time;
          break;
        case "Onsite":
          totalOnsiteTime = totalOnsiteTime + time.total_time;
          break;
        case "Non_billable":
          totalNonTime = totalNonTime + time.total_time;
          break;
        default:
          break;
      }
    }
    return (
      <Segment style={{ overflowX: "auto" }}>
        {this.renderModalTime()}
        <div className="splitHeader">
          <Header>Time Spent</Header>
          <Button
            primary
            icon
            basic
            circular
            onClick={() =>
              this.setState({
                toggleTime_edit: !this.state.toggleTime_edit,
              })
            }
          >
            <Icon name="plus" color="green" />
          </Button>
        </div>
        <Statistic.Group widths={3}>
          <Statistic>
            <Statistic.Value>{totalRemoteTime}</Statistic.Value>
            <Statistic.Label>Billable Remote</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{totalOnsiteTime}</Statistic.Value>
            <Statistic.Label>Billable Onsite</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{totalNonTime}</Statistic.Value>
            <Statistic.Label>Non-billable</Statistic.Label>
          </Statistic>
        </Statistic.Group>
        <Divider />
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Technician</Table.HeaderCell>
              <Table.HeaderCell>Engineer Type</Table.HeaderCell>
              <Table.HeaderCell>Location Type</Table.HeaderCell>
              <Table.HeaderCell>Time (mins)</Table.HeaderCell>
              <Table.HeaderCell>Options</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderSpentTime()}</Table.Body>
        </Table>
      </Segment>
    );
  }

  render() {
    if (this.state.data.length === 0) {
      return (
        <Container fluid>
          <Segment inverted attached>
            <Header
              textAlign="center"
              as="h2"
              style={{ paddingBottom: "30px" }}
            >
              <Button
                color="green"
                content="Mark As Complete"
                icon="checkmark"
                style={{ position: "absolute", right: "14px" }}
                loading={true}
              ></Button>
            </Header>
          </Segment>
          <Loader size="big" active>
            <span role="img" aria-label="">
              🤔
            </span>
            Thinking...
          </Loader>
        </Container>
      );
    } else {
      return (
        <React.Fragment>
          <Segment inverted attached>
            <Header textAlign="center" as="h2">
              #{this.props.match.params.id}: {this.state.data.status}
              {this.state.data.status !== "Cancelled" &&
              this.state.data.status !== "Complete" ? (
                <Button
                  color="green"
                  content="Mark As Complete"
                  icon="checkmark"
                  style={{ position: "absolute", right: "14px" }}
                  onClick={() => {
                    api.tickets
                      .updateTicketStatus(this.props.match.params.id, {
                        status: "Complete",
                      })
                      .catch((err) => {
                        console.log(err.response);
                      });

                    api.tickets
                      .updateTicket(this.props.match.params.id, {
                        status: "Complete",
                      })
                      .then(() => {
                        this.getData();
                        this.renderToast();
                      });
                  }}
                />
              ) : (
                <React.Fragment />
              )}
            </Header>
          </Segment>
          <Transition
            transitionOnMount
            visible={this.state.onLoad.visible}
            duration={750}
            animation="fade"
          >
            <Container fluid>
              {this.renderModal()}
              <Segment basic>
                <Grid centered>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      {this.renderTicketInfo()}
                      <ShowTicketSubMenu
                        submit={this.activitySubmit}
                        data={this.state.data}
                        activity={this.state.activityData}
                      />
                    </Grid.Column>
                    <Grid.Column>{this.renderTime()}</Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Container>
          </Transition>
        </React.Fragment>
      );
    }
  }
}
