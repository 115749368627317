import React, { Component } from "react";
import { Form, Icon, Divider, Modal, Header, Popup } from "semantic-ui-react";
import api from "../../../actions/api";
import AddForm from "./addContactForm";
import _ from "lodash";
import moment from "moment";
import { DateInput } from "semantic-ui-calendar-react";
import { toast } from "react-toastify";

const ticketTypeList = [
  { key: "web", text: "Web", value: "Web" },
  { key: "helpdesk", text: "Helpdesk", value: "Helpdesk" },
  { key: "maintenance", text: "Maintenance", value: "Maintenance" },
  { key: "server", text: "Server", value: "Server" },
];

const ticketstatus = [
  { key: "open", text: "Open", value: "Open" },
  { key: "inprogress", text: "In Progress", value: "In Progress" },
  { key: "complete", text: "Complete", value: "Complete" },
  { key: "cancelled", text: "Cancelled", value: "Cancelled" },
];

export default class editTicketForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        title: "",
        details: "",
        contact: "",
        cc_contacts: [],
        status: "",
        assigned: "",
        ticketType: "",
        createdDate: "",
      },
      loading: true,
      addContact: false,
      contactDetails: [],
      userList: [],
    };
  }

  componentDidMount() {
    this.setState(
      {
        data: {
          ...this.props.data,
          cc_contacts: this.props.data.cc_contacts.map((item) => {
            return item._id;
          }),
          createdDate: this.props.data.createdDate
            ? moment.utc(this.props.data.createdDate).format("DD-MM-YYYY")
            : moment.utc().format("DD-MM-YYYY"),
        },
      },
      () => {
        this.getContactData();
        this.setState({ loading: false });
      }
    );

    api.current_user
      .getAllUserDetails()
      .then((data) => {
        const filterData = _.filter(data, { groups: ["technician"] });
        let dataArray = [];
        for (var key in filterData) {
          dataArray.push({
            key: filterData[key]._id,
            text: filterData[key].fullName,
            value: filterData[key]._id,
          });
        }
        this.setState({ userList: dataArray });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  getContactData() {
    api.company.contacts
      .allContacts(this.props.data.company._id)
      .then((data) => {
        let dataArray = [];
        for (var key in data) {
          dataArray.push({
            key: data[key]._id,
            text: data[key].name,
            value: data[key]._id,
          });
        }
        this.setState({ contactDetails: dataArray });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  handleDropdownChange = (e, data) => {
    this.setState({
      data: { ...this.state.data, [data.name]: data.value },
    });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  onSubmit = () => {
    if (
      this.state.data.createdDate === "" ||
      this.state.data.title === "" ||
      this.state.data.company === "" ||
      this.state.data.type === "" ||
      this.state.data.details === "" ||
      this.state.data.status === ""
    ) {
      toast.error("Missing Required Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.props.submit(this.state.data);
    }
  };

  renderAddContactModal() {
    const handleClose = () => this.setState({ addContact: false });

    const contactSubmit = (data) => {
      api.company.contacts
        .addContact(this.state.data.company._id, data)
        .then(() => {
          this.getContactData();
          this.setState({ addContact: false });
        });
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.addContact}
        onClose={handleClose}
        size="small"
      >
        <Header icon="user" content="Add New Contact" />
        <Modal.Content>
          <AddForm submit={contactSubmit} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Form>
        {this.renderAddContactModal()}
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="date">Date</label>
            <DateInput
              name="createdDate"
              placeholder="Date"
              value={this.state.data.createdDate || ""}
              iconPosition="left"
              popupPosition="bottom center"
              onChange={this.handleDateChange}
              startMode="year"
              closable
            />
          </Form.Field>
          <Form.Input
            name="title"
            label="Ticket Title"
            value={this.state.data.title}
            onChange={this.handleChange}
            placeholder="Ticket Title"
          />
          <Form.Dropdown
            name="ticketType"
            label="Ticket Type"
            value={this.state.data.ticketType ? this.state.data.ticketType : ""}
            onChange={this.handleDropdownChange}
            placeholder="Please Select A Response"
            options={ticketTypeList}
            selection
            clearable
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.TextArea
            name="details"
            label="Details"
            value={this.state.data.details}
            onChange={this.handleChange}
            placeholder="Details"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="contact"
            label="Contact Name"
            value={this.state.data.contact ? this.state.data.contact._id : ""}
            onChange={this.handleDropdownChange}
            placeholder="Please Select A Response"
            options={this.state.contactDetails}
            selection
            clearable
            noResultsMessage={"No Contacts Available"}
            search
          />
          <Form.Dropdown
            name="cc_contacts"
            label="CC Contact Name"
            value={this.state.data.cc_contacts}
            onChange={this.handleDropdownChange}
            placeholder="Please Select A Response"
            options={this.state.contactDetails}
            multiple
            selection
            clearable
            noResultsMessage={"No Contacts Available"}
            search
          />
          <Popup
            position="right center"
            content="Add Contact"
            style={{ marginTop: "22px" }}
            trigger={
              <Form.Button
                width="1"
                style={{ marginTop: "22px" }}
                icon="plus"
                color="green"
                disabled={this.state.data.company === "" ? true : false}
                onClick={() => {
                  this.setState({ addContact: true });
                }}
              />
            }
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="assigned"
            label="Assigned To"
            value={this.state.data.assigned ? this.state.data.assigned._id : ""}
            onChange={this.handleDropdownChange}
            placeholder="Please Select A Response"
            options={this.state.userList}
            selection
            clearable
          />

          <Form.Dropdown
            name="status"
            label="Ticket Status"
            value={this.state.data.status}
            onChange={this.handleDropdownChange}
            placeholder="Please Select A Response"
            options={ticketstatus}
            selection
            clearable
          />
        </Form.Group>
        <Divider />
        <Form.Group widths="equal">
          <Form.Button
            type="submit"
            color="red"
            floated="left"
            onClick={() => {
              this.props.close();
            }}
          >
            <Icon name="close" />
            Cancel
          </Form.Button>
          <Form.Button
            color="green"
            floated="right"
            onClick={() => this.onSubmit()}
          >
            <Icon name="cloud upload" />
            Update
          </Form.Button>
        </Form.Group>
      </Form>
    );
  }
}
