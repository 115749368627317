import React, { Component } from "react";
import { Tab, Segment, Header } from "semantic-ui-react";
import NotesAndResponse from "./TicketNotesAndResponses";

class ShowTicketSubMenu extends Component {
  renderDetails() {
    let priorLineBlank = false;
    return (
      <Segment basic>
        <Header>Ticket Details</Header>
        <Segment
          basic
          textAlign={"left"}
          style={{ overflowY: "auto", height: 300 }}
        >
          {this.props.data && this.props.data.details && (
            <p>
              {this.props.data.details
                .split("\n")
                .map((item, key) => {
                  if (item.trim() === "") {
                    if (priorLineBlank) {
                      return null;
                    }

                    priorLineBlank = true;
                  } else {
                    priorLineBlank = false;
                  }
                  return (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  );
                })
                .filter((i) => i !== null)}
            </p>
          )}
        </Segment>
      </Segment>
    );
  }

  renderLog() {
    return (
      <Segment basic>
        <NotesAndResponse
          submit={this.props.submit}
          data={this.props.activity}
        />
      </Segment>
    );
  }

  render() {
    const panes = [
      {
        menuItem: "Ticket Details",
        render: () => (
          <Tab.Pane attached={false}>{this.renderDetails()}</Tab.Pane>
        ),
      },
      {
        menuItem: "Ticket Log",
        render: () => <Tab.Pane attached={false}>{this.renderLog()}</Tab.Pane>,
      },
    ];
    return (
      <Tab
        menu={{
          inverted: true,
          attached: true,
        }}
        panes={panes}
      />
    );
  }
}

export default ShowTicketSubMenu;
