import React, { Component } from "react";
import { Divider, Icon, Form, Label } from "semantic-ui-react";
import { toast } from "react-toastify";
import { DateInput } from "semantic-ui-calendar-react";

const options = [
  { key: "paygo", text: "Pay-Go", value: "Pay-Go" },
  { key: "contract", text: "Contract", value: "Contract" },
];

export default class AddClient extends Component {
  state = {
    data: {
      name: "",
      sla: "",
      contract_start_date: "",
      company_ref: "",
    },

    errors: {},
  };

  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  handleDateOnChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  validate = (data) => {
    const errors = {};

    if (!data.name) errors.name = "Can't Be Empty";
    if (!data.sla) errors.sla = "Can't Be Empty";
    if (data.sla === "Contract" && data.contract_start_date === "")
      errors.contract_start_date = "Can't Be Empty";

    return errors;
  };

  onSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });

    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    } else {
      toast.error("Mandatory fields are missing data.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  render() {
    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            name="name"
            label="Client Name"
            value={this.state.data.name}
            onChange={this.onChange}
            placeholder="Client Name"
            error={this.state.errors.name}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            name="company_ref"
            label="Account Number"
            value={this.state.data.company_ref}
            onChange={this.onChange}
            placeholder="Account Number"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="sla"
            label="Support Type"
            defaultValue={this.state.data.sla}
            onChange={this.handleDropdownChange}
            placeholder="Please Select A Response"
            options={options}
            selection
            clearable
            error={this.state.errors.sla}
          />
        </Form.Group>
        {this.state.data.sla === "Contract" ? (
          <Form.Field
            error={this.state.errors.contract_start_date ? true : false}
          >
            <DateInput
              label="Starting Date Of Contract"
              name="contract_start_date"
              closable={true}
              value={
                this.state.data.contract_start_date &&
                this.state.data.contract_start_date !== null
                  ? this.state.data.contract_start_date
                  : ""
              }
              iconPosition="left"
              onChange={this.handleDateOnChange}
            />
            {this.state.errors.contract_start_date && (
              <Label pointing prompt>
                {this.state.errors.contract_start_date}
              </Label>
            )}
          </Form.Field>
        ) : (
          <React.Fragment />
        )}
        <Divider />
        <Form.Group widths="equal">
          <Form.Button
            type="submit"
            color="red"
            floated="left"
            onClick={() => {
              this.props.close();
            }}
          >
            <Icon name="close" />
            Cancel
          </Form.Button>
          <Form.Button
            color="green"
            floated="right"
            onClick={() => this.onSubmit()}
          >
            <Icon name="paper plane" />
            Submit
          </Form.Button>
        </Form.Group>
      </Form>
    );
  }
}
