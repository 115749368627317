import React, { Component } from "react";
import { Form, Icon } from "semantic-ui-react";
import api from "../../../actions/api";
import _ from "lodash";
import { toast } from "react-toastify";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";

export default class editTimeSpent extends Component {
  state = {
    data: {
      date: moment.utc().format("DD-MM-YYYY"),
      location: "Remote",
      total_time: 0,
      bill_type: "",
      author: "",
      note: "",
    },
    technicians: [],
    rates: [],
    rateTable: [],
  };

  componentDidMount() {
    if (this.props.param_data && this.props.param_data.time_spent) {
      this.setState({
        data: {
          ...this.state.data,
          total_time: parseFloat(this.props.param_data.time_spent / 60).toFixed(
            2
          ),
          note: this.props.param_data.title,
        },
      });
    } else if (
      this.props.edit_item &&
      Object.keys(this.props.edit_item).length > 0
    ) {
      this.setState({
        data: {
          date: this.props.edit_item.date
            ? moment.utc(this.props.edit_item.date).format("DD-MM-YYYY")
            : "",
          location: this.props.edit_item.location,
          total_time: this.props.edit_item.total_time,
          bill_type: this.props.edit_item.bill_type,
          author:
            this.props.edit_item.author && this.props.edit_item.author._id,
          note: this.props.edit_item.note,
        },
      });
    }

    api.current_user
      .getUserDetails()
      .then((res) => {
        api.current_user
          .getAllUserDetails()
          .then((data) => {
            const filterData = _.filter(data, { groups: ["technician"] });
            let dataArray = [];
            for (var key in filterData) {
              dataArray.push({
                key: filterData[key]._id,
                text: filterData[key].fullName,
                value: filterData[key]._id,
              });
            }
            this.setState({
              technicians: dataArray,
              data: { ...this.state.data, author: res._id },
            });
          })
          .catch((err) => {
            console.log(err.response);
          });

        this.getRates();
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  getRates = () => {
    if (this.props.data) {
      let rates = this.props.data.company.rates;

      this.setState({
        rateTable: rates,
        rates: rates.map((item) => ({
          key: item._id,
          text: item.name,
          value: item.name,
        })),
      });
    }
  };

  handleDropdownChange = (e, data) => {
    this.setState({
      data: { ...this.state.data, [data.name]: data.value },
    });
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  onSubmit = () => {
    if (
      this.state.data.total_time === 0 ||
      this.state.data.date === "" ||
      this.state.data.author === "" ||
      this.state.data.bill_type === "" ||
      this.state.data.location === ""
    ) {
      toast.error("Missing Required Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      let loc = this.state.data.location.toLowerCase();
      // if (loc !== "non-billable") {
      let rate = this.state.rateTable.filter((item) => {
        return item.name === this.state.data.bill_type;
      });
      // }
      let time = {
        ...this.state.data,
        rate: rate[0][loc],
      };

      this.props.submit({ time });
    }
  };

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="date">Date</label>
            <DateInput
              name="date"
              placeholder="Date"
              value={this.state.data.date || ""}
              iconPosition="left"
              popupPosition="bottom center"
              onChange={this.handleDateChange}
              startMode="year"
              closable
            />
          </Form.Field>
          <Form.Input
            name="total_time"
            label="Total Time"
            value={this.state.data.total_time}
            onChange={this.onChange}
            placeholder="Total Time"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="location"
            label="Time Location"
            onChange={this.handleDropdownChange}
            value={this.state.data.location}
            placeholder="Select A Response"
            options={[
              { text: "Remote", value: "Remote" },
              { text: "Onsite", value: "Onsite" },
            ]}
            selection
          />
          <Form.Dropdown
            name="bill_type"
            label="Engineer Rate"
            onChange={this.handleDropdownChange}
            value={this.state.data.bill_type}
            placeholder="Select A Response"
            options={this.state.rates}
            selection
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.TextArea
            name="note"
            label="Additional Notes (Optional)"
            onChange={this.onChange}
            value={this.state.data.note}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="author"
            label="Technician"
            onChange={this.handleDropdownChange}
            value={this.state.data.author}
            placeholder="Select A Response"
            options={this.state.technicians}
            selection
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Button
            type="submit"
            color="red"
            floated="left"
            onClick={(e) => {
              e.preventDefault();
              this.props.close();
            }}
          >
            <Icon name="close" />
            Cancel
          </Form.Button>
          <Form.Button type="submit" color="green" floated="right">
            <Icon name="plus" />
            Add Time
          </Form.Button>
        </Form.Group>
      </Form>
    );
  }
}
