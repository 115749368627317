import React, { Component } from "react";
import {
  Pagination,
  Segment,
  Table,
  Grid,
  Input,
  Button,
  Form,
  Checkbox,
} from "semantic-ui-react";

export default class FilteredPagedList extends Component {
  state = {
    isLoading: true,
    error: false,
    search_query: "",
    total: 0,
    page_size: 0,
    page: 0,
    items: [],
    size: 25,
    filters: {},
  };

  componentDidMount() {
    let finalFilterInitial = {};

    if (this.props.filter !== undefined) {
      for (let index in this.props.filter) {
        let filter = this.props.filter[index];
        finalFilterInitial[filter.key] = filter.default;
      }
    }

    this.setState(
      {
        filters: finalFilterInitial,
      },
      () => {
        this.run_query(1);
      }
    );
  }
  run_query(page) {
    this.setState({
      isLoading: true,
      error: false,
    });
    this.props
      .query(page, this.state.size, this.state.search_query, this.state.filters)
      .then((data) => {
        this.setState({
          isLoading: false,
          total: data.total,
          page_size: data.page_size,
          page: data.page,
          items: data.items,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          error: true,
        });
      });
  }
  search = (event) => {
    this.setState(
      {
        search_query: event.target.value,
      },
      () => {
        this.run_query(1);
      }
    );
  };
  handleFilterChange = (event, { value, name }) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      () => {
        this.run_query(1);
      }
    );
  };

  render() {
    let totalPages = Math.ceil(this.state.total / this.state.page_size);
    let theList = [];
    if (this.state.items && this.state.items.length) {
      theList = this.state.items.map((item) => {
        return this.props.body(item);
      });
    }

    let filters = [];

    if (this.props.filter !== undefined) {
      filters = this.props.filter.map((filter) => {
        return (
          <Grid.Column key={filter.key}>
            <Form.Field>{filter.title}</Form.Field>
            {filter.options.map((option) => {
              return (
                <Form.Field key={option.title}>
                  <Checkbox
                    radio
                    label={option.title}
                    name={filter.key}
                    value={option.value}
                    checked={this.state.filters[filter.key] === option.value}
                    onChange={this.handleFilterChange}
                  />
                </Form.Field>
              );
            })}
          </Grid.Column>
        );
      });
    }

    return (
      <div>
        {this.props.search !== false ? (
          <Grid columns="equal">
            <Grid.Column>
              <Input
                placeholder="Search By Name..."
                style={{
                  marginTop: "14px",
                  marginLeft: "14px",
                  marginRight: "14px",
                }}
                type="text"
                value={this.state.search_query}
                onChange={this.search}
                icon="search"
                iconPosition="left"
                fluid
              />
            </Grid.Column>
          </Grid>
        ) : (
          <React.Fragment />
        )}
        <Grid columns="equal" padded>
          {filters}
        </Grid>
        <Segment
          basic={true}
          loading={this.state.isLoading}
          style={{ padding: "0px" }}
        >
          <Table celled selectable>
            <Table.Header>{this.props.header()}</Table.Header>
            <Table.Body>
              {theList.length ? theList : this.props.noItems()}
            </Table.Body>
          </Table>
          {theList.length && !this.state.isLoading ? (
            <React.Fragment>
              <Pagination
                defaultActivePage={this.state.page}
                totalPages={totalPages}
                onPageChange={(event, data) => {
                  this.run_query(data.activePage);
                }}
              />
              <Button.Group floated="right">
                <Button
                  content="25"
                  onClick={() => {
                    this.setState({ size: 25 });
                    setTimeout(() => {
                      this.run_query(1);
                    }, 500);
                  }}
                />
                <Button
                  content="50"
                  onClick={() => {
                    this.setState({ size: 50 });
                    setTimeout(() => {
                      this.run_query(1);
                    }, 500);
                  }}
                />
                <Button
                  content="100"
                  onClick={() => {
                    this.setState({ size: 100 });
                    setTimeout(() => {
                      this.run_query(1);
                    }, 500);
                  }}
                />
              </Button.Group>
            </React.Fragment>
          ) : (
            ""
          )}
        </Segment>
      </div>
    );
  }
}
