import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Transition,
  Container,
  Button,
  Card,
  Loader,
  Message,
  Icon,
} from "semantic-ui-react";
import CountUp from "react-countup";
import api from "../../actions/api";
import moment from "moment";
import RandomErrorMesasage from "../../assets/errorMessageGenerator";

class Home extends Component {
  state = {
    userData: {},
    todos: [],
    ticketCount: 0,
    error: "",
    loading: true,
    onLoad: { visible: false },
  };

  componentDidMount() {
    this.setState({ onLoad: { visible: true } });
    this.getData();
  }

  getData() {
    api.current_user
      .getStats()
      .then((res) => {
        console.log(res);
        this.setState({
          userData: res.currentUser,
          ticketCount: res.ticketCount,
          todos: res.todos,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error:
            err.response !== undefined
              ? err.response.data.error_message
              : "Unexpected Network Issue",
        });
      });
  }

  renderTodoCard() {
    let todos = this.state.todos.map((item) => {
      return (
        <Card key={item._id}>
          <Card.Content>
            <Card.Header>{item.title}</Card.Header>
            <Card.Meta>
              Due:
              {moment(item.deadline).format("DD-MM-YYYY")}
            </Card.Meta>
            <Card.Description>{item.details}</Card.Description>
          </Card.Content>
          {item.ticketLink ? (
            <Card.Content textAlign="center" extra>
              <Button
                onClick={() => {
                  this.props.history.push(
                    `/tickets/show/${item.ticketLink._id}`
                  );
                }}
              >
                Go To Linked Ticket
              </Button>
            </Card.Content>
          ) : (
            <React.Fragment />
          )}
          <Card.Content textAlign="center" extra>
            <Button
              onClick={() => {
                api.todos.updateStatus(item._id).then(() => {
                  this.getData();
                });
              }}
              content={
                item.completed === false
                  ? "Mark As Complete"
                  : "Mark As Incomplete"
              }
              icon={item.completed === false ? "check" : "close"}
              color={item.completed === false ? "green" : "red"}
            ></Button>
          </Card.Content>
        </Card>
      );
    });

    return (
      <React.Fragment>
        {this.state.error === true && this.state.loading === false ? (
          <RandomErrorMesasage />
        ) : this.state.loading === false &&
          this.state.error === false &&
          this.state.todos.length !== 0 ? (
          <Card.Group itemsPerRow={4}>{todos}</Card.Group>
        ) : this.state.loading === true ? (
          <Segment padded basic>
            <Loader active></Loader>
          </Segment>
        ) : (
          <Message icon success>
            <Icon name="check" />
            <Message.Content>
              <Message.Header>No Upcoming Todos</Message.Header>
              There are currently no todos upcoming in the next week.
            </Message.Content>
          </Message>
        )}
      </React.Fragment>
    );
  }

  render() {
    const motivational_quotes = [
      '"We cannot solve problems with the kind of thinking we employed when we came up with them." - Albert Einstein',
      '"Learn as if you will live forever, live like you will die tomorrow." - Mahatma Gandhi',
      '"Stay away from those people who try to disparage your ambitions. Small minds will always do that, but great minds will give you a feeling that you can become great too." - Mark Twain',
      '"When you give joy to other people, you get more joy in return. You should give a good thought to happiness that you can give out." - Eleanor Roosevelt',
      '"When you change your thoughts, remember to also change your world." - Norman Vincent Peale',
      '"It is only when we take chances, when our lives improve. The initial and the most difficult risk that we need to take is to become honest." - Walter Anderson',
      '"Nature has given us all the pieces required to achieve exceptional wellness and health, but has left it to us to put these pieces together." - Diane McLaren',
      '"There are no accidents" - Master Oogway',
    ];

    return (
      <React.Fragment>
        <Segment inverted attached textAlign="center">
          <Header as="h2">Greetings {this.state.userData.fullName}</Header>
        </Segment>
        <Transition
          visible={this.state.onLoad.visible}
          duration={1000}
          animation="fade"
        >
          <Container fluid>
            <Segment basic className="Margins">
              <Grid columns={"equal"}>
                <Grid.Row textAlign="center">
                  <Grid.Column>
                    <Segment attached>
                      <Header>Motivational Quote</Header>
                      <div style={{ paddingTop: "20px" }}>
                        {
                          motivational_quotes[
                            Math.floor(
                              Math.random() * motivational_quotes.length
                            )
                          ]
                        }
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row textAlign="center">
                  <Grid.Column>
                    <Segment attached>
                      <Header>Active Tickets You Are Involved In</Header>
                      <CountUp
                        start={0}
                        end={this.state.ticketCount}
                        duration={6}
                      />
                      <div style={{ paddingTop: "20px" }}>
                        <Button
                          onClick={() => {
                            this.props.history.push(`/tickets/`);
                          }}
                          fluid
                          animated="vertical"
                        >
                          <Button.Content hidden>
                            <Icon name="chevron right" />
                            <Icon name="chevron right" />
                            <Icon name="chevron right" />
                          </Button.Content>
                          <Button.Content visible>View</Button.Content>
                        </Button>
                      </div>
                    </Segment>
                  </Grid.Column>
                  {/* <Grid.Column>
                    <Segment attached>
                      <Header>Items In The Workshop</Header>
                      <CountUp
                        start={0}
                        end={this.state.workshopData.length}
                        duration={6}
                      />
                      <div style={{ paddingTop: "20px" }}>
                        <Button
                          onClick={() => {
                            this.props.history.push(`/workshop/`);
                          }}
                          fluid
                          animated="vertical"
                        >
                          <Button.Content hidden>>>></Button.Content>
                          <Button.Content visible>View</Button.Content>
                        </Button>
                      </div>
                    </Segment>
                  </Grid.Column> */}
                </Grid.Row>
                {/* <Grid.Row columns={1}>
                  <Grid.Column>
                    <Segment attached>
                      <Header textAlign="center" as="h3">
                        Upcoming Todos
                      </Header>
                      {this.renderTodoCard()}
                    </Segment>
                  </Grid.Column>
                </Grid.Row> */}
              </Grid>
            </Segment>
          </Container>
        </Transition>
      </React.Fragment>
    );
  }
}

export default Home;
