import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Container, Segment } from "semantic-ui-react";
import Dash from "./dash";

export class SettingsPage extends React.Component {
  render() {
    return (
      <Container fluid style={{ padding: 0 }}>
        <Segment basic className="no-pad">
          <Switch>
            <Route exact path={`${this.props.match.url}`} component={Dash} />
            {/* <Route exact path={`${this.props.match.url}/lists`} component={SettingsList} />
            <Route exact path={`${this.props.match.url}/sms`} component={SMS} />
            <Route exact path={`${this.props.match.url}/log`} component={LogDefaults} /> */}
          </Switch>
        </Segment>
      </Container>
    );
  }
}
export default connect(state => {
  return {
    chosenIncident: state.chosenIncident
  };
}, {})(SettingsPage);
