import React, { Component } from "react";
import { Grid, Icon, Loader, Message, Segment, Table } from "semantic-ui-react";
import api from "../../actions/api";
import moment from "moment";

class viewResult extends Component {
  state = {
    data: {},
    loading: true,
  };

  componentDidMount() {
    api.hosting_package
      .findOneFullData(this.props.currentItem)
      .then((res) => this.setState({ data: res, loading: false }))
      .catch((e) => console.log(e));
  }

  renderError(errName) {
    return (
      <Message
        error
        header={`Error fetching data for ${errName}!`}
        content="The most likely cause of this is that it has been deleted."
        icon="warning"
      />
    );
  }

  render() {
    if (this.state.loading === true) {
      return (
        <Segment basic>
          <Loader active />
        </Segment>
      );
    } else {
      console.log(this.state);
      const { data } = this.state;

      return (
        <Segment>
          <Grid>
            <Grid.Row columns={2}>
              {data.client !== null ? (
                <Grid.Column>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan="2" textAlign="center">
                          Client: <br />
                          {data.client.name}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row textAlign="center">
                        <Table.Cell width="8">Account Number</Table.Cell>
                        <Table.Cell width="8">
                          {data.client.company_ref}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row textAlign="center">
                        <Table.Cell width="8">
                          Service Level Agreement
                        </Table.Cell>
                        <Table.Cell width="8">{data.client.sla}</Table.Cell>
                      </Table.Row>
                      <Table.Row textAlign="center">
                        <Table.Cell width="8">Archive Status</Table.Cell>
                        <Table.Cell width="8">
                          {data.client.archived === true ? (
                            <Icon name="checkmark" color="green" />
                          ) : (
                            <Icon name="close" color="red" />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              ) : (
                <Grid.Column>{this.renderError("client")}</Grid.Column>
              )}
              {data.server !== null ? (
                <Grid.Column>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan="2" textAlign="center">
                          Server: <br />
                          {data.server.name}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row textAlign="center">
                        <Table.Cell width="8">Management URL</Table.Cell>
                        <Table.Cell width="8">
                          {data.server.management_url !== null &&
                          data.server.management_url !== "" ? (
                            <a
                              href={
                                data.server.management_url.search(
                                  "https://"
                                ) === -1
                                  ? `https://${data.server.management_url}`
                                  : data.server.management_url
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {data.server.management_url.search("https://") ===
                              -1
                                ? `https://${data.server.management_url}`
                                : data.server.management_url}
                            </a>
                          ) : (
                            "No URL Provided"
                          )}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row textAlign="center">
                        <Table.Cell width="8">Meta Data</Table.Cell>
                        <Table.Cell width="8">
                          {data.server.meta !== undefined ? (
                            <ul
                              style={{ listStyle: "none", paddingLeft: "0px" }}
                            >
                              <li>
                                RAM:{" "}
                                {data.server.meta.ram !== ""
                                  ? data.server.meta.ram
                                  : "N/A"}
                              </li>
                              <li>
                                CPU Cores:{" "}
                                {data.server.meta.cpu_cores !== ""
                                  ? data.server.meta.cpu_cores
                                  : "N/A"}
                              </li>
                              <li>
                                Storage:{" "}
                                {data.server.meta.storage !== ""
                                  ? data.server.meta.storage
                                  : "N/A"}
                              </li>
                            </ul>
                          ) : (
                            "[ERROR DISPLAYING DATA]"
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              ) : (
                <Grid.Column>{this.renderError("server")}</Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row>
              {data.server !== null &&
              data.server.provider !== undefined &&
              data.server.provider !== null ? (
                <Grid.Column>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan="2" textAlign="center">
                          Server Provider: <br />
                          {data.server.provider.name}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row textAlign="center">
                        <Table.Cell width="8">Management URL</Table.Cell>
                        <Table.Cell width="8">
                          {data.server.provider.provider_management_url !==
                            null &&
                          data.server.provider.provider_management_url !==
                            "" ? (
                            <a
                              href={
                                data.server.provider.provider_management_url.search(
                                  "https://"
                                ) === -1
                                  ? `https://${data.server.provider.provider_management_url}`
                                  : data.server.provider.provider_management_url
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {data.server.provider.provider_management_url.search(
                                "https://"
                              ) === -1
                                ? `https://${data.server.provider.provider_management_url}`
                                : data.server.provider.provider_management_url}
                            </a>
                          ) : (
                            "No URL Provided"
                          )}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row textAlign="center">
                        <Table.Cell width="8">Type</Table.Cell>
                        <Table.Cell width="8">
                          {data.server.provider.type}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row textAlign="center">
                        <Table.Cell width="8">Setup Date</Table.Cell>
                        <Table.Cell width="8">
                          {moment(data.server.provider.setup_date).format(
                            "DD/MM/YYYY"
                          )}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row textAlign="center">
                        <Table.Cell width="8">Renewal Date</Table.Cell>
                        <Table.Cell width="8">
                          {moment(data.server.provider.renewal_date).format(
                            "DD/MM/YYYY"
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              ) : (
                <Grid.Column>{this.renderError("provider")}</Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      );
    }
  }
}

export default viewResult;
