import React, { Component } from "react";
import { Header, Button, Segment, Modal } from "semantic-ui-react";
import api from "../../../../actions/api";
import UserTable from "./userTable";
import AddForm from "./forms/addUserForm";
import { toast } from "react-toastify";

export default class userManagement extends Component {
  state = {
    data: [],
    toggle_add: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    api.management.users
      .getAll()
      .then((data) => {
        this.setState({ data: data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  handleClose() {
    if (this.state.toggle_add === true) {
      this.setState({ toggle_add: false });
    }
  }

  onSubmit = (data) => {
    return api.management.users
      .addUser(data)
      .then(() => {
        this.handleClose();
        this.getData();
        this.renderToast();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  renderModal() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_add}
        onClose={this.handleClose.bind(this)}
        size="small"
        basic
      >
        <Header icon="user" content="Add New User" />
        <Modal.Content>
          <AddForm submit={this.onSubmit} close={this.handleClose.bind(this)} />
        </Modal.Content>
      </Modal>
    );
  }

  renderToast() {
    toast.success("User Added Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.renderModal()}
        <Header textAlign="center">User Management</Header>
        <Segment textAlign="right" basic>
          <Button
            onClick={() => {
              this.setState({ toggle_add: !this.state.toggle_add });
            }}
            icon="plus"
            content="Add New User"
          />
        </Segment>
        <UserTable
          getData={this.getData.bind(this)}
          data={this.state.data}
          history={this.props.history}
        />
      </React.Fragment>
    );
  }
}
