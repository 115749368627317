import React, { Component } from "react";
import {
  Segment,
  Header,
  Button,
  Icon,
  Loader,
  Modal,
  Grid,
  Input,
  Transition,
  Message,
  Divider,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import moment from "moment";
import api from "../../actions/api";
import ShowClientSubMenu from "./showClientSubMenu";
import EditForm from "./Forms/editClientForm";
import Settings_Receiver from "../../lib/Settings_Receiver";

class ShowClient extends Component {
  state = {
    data: {
      rates: [],
    },
    ticketdata: [],
    contactData: [],
    ratesModal: false,
    toggle_edit: false,
    onLoad: { visible: false },
    year: moment().get("year"),
    month: moment().get("month") + 1,
    mountLoading: true,
  };

  componentDidMount() {
    this.setState({
      onLoad: { visible: true },
    });
    this.getData();
  }

  getData() {
    api.company
      .getCompany(this.props.match.params.id)
      .then((data) => {
        this.setState({
          data: {
            ...data,
            contract_start_date:
              data.sla === "Contract"
                ? moment(data.contract_start_date).format("DD-MM-YYYY")
                : "",
          },
          mountLoading: false,
        });

        if (this.state.data.firstedit === true) {
          this.setState({ toggle_edit: true });
        }
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({ mountloading: false });
      });

    api.tickets
      .getAll()
      .then((data) => {
        this.setState({ ticketdata: data });
      })
      .catch((err) => {
        console.log(err.response);
      });

    api.company.contacts
      .allContacts(this.props.match.params.id)
      .then((data) => {
        this.setState({
          contactData: data.map((item) => {
            return { key: item._id, value: item._id, text: item.name };
          }),
        });
      });
  }

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  updateRate = (id) => {
    return (e) => {
      this.setState({
        data: {
          ...this.state.data,
          rates: this.state.data.rates.map((item) => {
            if (item._id === id) {
              return {
                ...item,
                [e.target.name]: e.target.value,
              };
            }
            return item;
          }),
        },
      });
    };
  };

  renderModal() {
    const handleClose = () => {
      if (this.state.toggle_edit === true) {
        this.setState({ toggle_edit: false });
      }
    };

    const onSubmit = (data) => {
      api.company
        .updateCompany(this.props.match.params.id, data)
        .then(() => {
          handleClose();
          this.getData();
          toast.info("Data Updated Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((err) => {
          toast.error("Data Update Failed", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_edit}
        onClose={handleClose}
      >
        <Header icon="group" content="Edit Client" />
        <Modal.Content>
          {this.state.data.firstedit === true && (
            <React.Fragment>
              <Message
                info
                icon="warning circle"
                header="Missing Information"
                content="This client is missing important information please fill out missing fields where possible."
              />
              <Divider />
            </React.Fragment>
          )}
          <EditForm
            submit={onSubmit}
            close={handleClose}
            data={this.state.data}
            contactData={this.state.contactData}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderRates() {
    const rates =
      this.state.data &&
      this.state.data.rates.map((rate) => {
        return (
          <Grid.Row key={rate._id}>
            <Grid.Column width="8">{rate.name}</Grid.Column>
            <Grid.Column width="4">{rate.remote}</Grid.Column>
            <Grid.Column width="4">{rate.onsite}</Grid.Column>
          </Grid.Row>
        );
      });

    return (
      <Segment>
        {this.renderRatesModal()}
        <div className="splitHeader">
          <Header>Engineer Rates</Header>
          <Button
            icon
            basic
            circular
            onClick={() => this.setState({ ratesModal: true })}
            primary
          >
            <Icon name="pencil" color="blue" />
          </Button>
        </div>

        <Grid celled stackable>
          <Grid.Row>
            <Grid.Column width="8" className="gridHeader">
              Type
            </Grid.Column>
            <Grid.Column width="4" className="gridHeader">
              Remote
            </Grid.Column>
            <Grid.Column width="4" className="gridHeader">
              Onsite
            </Grid.Column>
          </Grid.Row>
          {rates.length ? (
            rates
          ) : (
            <Grid.Row>
              <Grid.Column width={16}>No rates</Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    );
  }

  renderRatesModal() {
    const rates = this.state.data.rates.map((rate) => {
      return (
        <Grid.Column key={rate._id} width={4}>
          <label>{rate.name}</label>
          <Input
            name="remote"
            value={rate.remote}
            onChange={this.updateRate(rate._id)}
          />
          <Input
            name="onsite"
            value={rate.onsite}
            onChange={this.updateRate(rate._id)}
          />
        </Grid.Column>
      );
    });
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.ratesModal}
        onClose={() => this.setState({ ratesModal: false })}
      >
        <Header>
          <Icon name="file alternate" />
          Update Engineer Rates
        </Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>{rates}</Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => this.setState({ ratesModal: false })}
            icon
          >
            <Icon name="remove" /> Cancel
          </Button>
          <Button
            color="green"
            icon
            onClick={() => {
              api.company
                .updateCompany(this.props.match.params.id, this.state.data)
                .then(() => {
                  this.setState({ ratesModal: false });
                  this.getData();
                  toast.info("Data Updated Successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                })
                .catch((err) => {
                  toast.error("Data Update Failed", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                });
            }}
          >
            <Icon name="checkmark" /> Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  renderDetails() {
    return (
      <Segment>
        <Grid celled stackable className="details">
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as={"h2"} floated="left">
                {this.state.data.name}
              </Header>
              <Button
                primary
                floated={"right"}
                icon
                basic
                circular
                onClick={() =>
                  this.setState({
                    toggle_edit: !this.state.toggle_edit,
                  })
                }
              >
                <Icon name="pencil" color="blue" />
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <p>
                Accounts Number:
                <span>
                  {this.state.data.company_ref
                    ? this.state.data.company_ref
                    : "None Provided"}
                </span>
              </p>
            </Grid.Column>
            <Grid.Column width={8}>
              <p>
                Support Type:
                <span>
                  {this.state.data.sla ? this.state.data.sla : "None Provided"}
                  <b>
                    {this.state.data.sla === "Contract"
                      ? `(${this.state.data.contract_start_date})`
                      : ""}
                  </b>
                </span>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <p>
                Company Email:
                <span>
                  {this.state.data.email_address
                    ? this.state.data.email_address
                    : "None Provided"}
                </span>
              </p>
            </Grid.Column>
            <Grid.Column width={8}>
              <p>
                Company Phone:
                <span>
                  {this.state.data.telephone
                    ? this.state.data.telephone
                    : "None Provided"}
                </span>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <p>
                Website:
                <span>
                  {this.state.data.website
                    ? this.state.data.website
                    : "None Provided"}
                </span>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <p>
                Primary Contact:
                <span>
                  {this.state.data.primary_contact ? (
                    <React.Fragment>
                      {this.state.data.primary_contact.name} (
                      <b>
                        {this.state.data.primary_contact.phone_number !==
                          undefined &&
                        this.state.data.primary_contact.phone_number !== ""
                          ? this.state.data.primary_contact.phone_number
                          : "No Number Provided"}
                      </b>
                      )
                    </React.Fragment>
                  ) : (
                    "None Provided"
                  )}
                </span>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  render() {
    if (this.state.mountLoading) {
      return (
        <Loader size="big" active>
          <span role="img" aria-label="">
            🤔
          </span>
          Thinking...
        </Loader>
      );
    } else {
      return (
        <Transition
          visible={this.state.onLoad.visible}
          transitionOnMount
          duration={750}
          animation="fade"
        >
          <Segment basic>
            {this.renderModal()}
            <Grid centered stackable>
              <Grid.Row columns={2}>
                <Grid.Column>{this.renderDetails()}</Grid.Column>
                <Grid.Column>{this.renderRates()}</Grid.Column>
              </Grid.Row>
            </Grid>
            <Segment>
              <ShowClientSubMenu
                history={this.props.history}
                data={this.state.ticketdata}
                iddata={this.props.match.params.id}
              />
            </Segment>
          </Segment>
        </Transition>
      );
    }
  }
}

export default Settings_Receiver(ShowClient, (settings) => {
  return {
    settings, // bring back the whole thing as prop named settings
  };
});
