import moment from "moment";
import React, { useState, useEffect } from "react";
import { Container, Header, Segment, Button, Grid } from "semantic-ui-react";

export default function Timer() {
  const calculateTime = (start) => {
    return `${
      moment
        .duration(moment.utc().diff(moment.utc(start, "DD/MM/YYYY HH:mm:ss")))
        .hours()
        ? `${moment
            .duration(
              moment.utc().diff(moment.utc(start, "DD/MM/YYYY HH:mm:ss"))
            )
            .hours()}:`
        : ""
    }${
      moment
        .duration(moment.utc().diff(moment.utc(start, "DD/MM/YYYY HH:mm:ss")))
        .minutes() < 10
        ? `0${moment
            .duration(
              moment.utc().diff(moment.utc(start, "DD/MM/YYYY HH:mm:ss"))
            )
            .minutes()}`
        : moment
            .duration(
              moment.utc().diff(moment.utc(start, "DD/MM/YYYY HH:mm:ss"))
            )
            .minutes()
    }:${
      moment
        .duration(moment.utc().diff(moment.utc(start, "DD/MM/YYYY HH:mm:ss")))
        .seconds() < 10
        ? `0${moment
            .duration(
              moment.utc().diff(moment.utc(start, "DD/MM/YYYY HH:mm:ss"))
            )
            .seconds()}`
        : moment
            .duration(
              moment.utc().diff(moment.utc(start, "DD/MM/YYYY HH:mm:ss"))
            )
            .seconds()
    }`;
  };

  const [mountLoad, setMountLoad] = useState(true);
  const [timerDetails, setTimerDetails] = useState({});

  const [timeDifference, setTimeDifference] = useState(0);

  useEffect(() => {
    if (mountLoad) {
      const urlSearchParams = new URLSearchParams(window.location.search);

      const params = Object.fromEntries(urlSearchParams.entries());

      setTimerDetails(params);

      setInterval(() => {
        setTimeDifference(calculateTime(params.start_time));
      }, 1000);

      setMountLoad(false);
    }
  }, [mountLoad]);

  return (
    <Container style={{ marginTop: "40px", marginBottom: "40px" }}>
      <Segment textAlign="center">
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h2">{timerDetails.title} Timer</Header>
              <Header as="h1">
                {timeDifference === 0 ? (
                  <span role="img" aria-label="">
                    🤔
                  </span>
                ) : (
                  timeDifference
                )}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Button
                content="Cancel"
                onClick={() => window.close()}
                color="red"
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                content="Complete"
                color="green"
                onClick={() => {
                  window.open(
                    `/tickets/show/${timerDetails.ticket}?title=${
                      timerDetails.title
                    }&time_spent=${moment
                      .utc()
                      .diff(
                        moment.utc(
                          timerDetails.start_time,
                          "DD/MM/YYYY HH:mm:ss"
                        ),
                        "seconds"
                      )}`
                  );
                  window.close();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
}
