import React, { Component } from "react";
import {
  Table,
  Message,
  Select,
  Segment,
  Icon,
  Checkbox,
  Grid,
  Modal,
  Header,
  Menu,
  Popup,
  Divider,
  Button,
} from "semantic-ui-react";
import moment from "moment";
import API from "../../actions/api";
import TicketDisplay from "./summaryTicketDisplay";
import InvoiceForm from "./forms/billingForm";

const yearOptions = [
  { key: 2015, value: 2015, text: "2015" },
  { key: 2016, value: 2016, text: "2016" },
  { key: 2017, value: 2017, text: "2017" },
  { key: 2018, value: 2018, text: "2018" },
  { key: 2019, value: 2019, text: "2019" },
  { key: 2020, value: 2020, text: "2020" },
  { key: 2021, value: 2021, text: "2021" },
  { key: 2022, value: 2022, text: "2022" },
  { key: 2023, value: 2023, text: "2023" },
  { key: 2024, value: 2024, text: "2024" },
  { key: 2025, value: 2025, text: "2025" },
];
const monthOptions = [
  { key: 0, value: 0, text: "January" },
  { key: 1, value: 1, text: "February" },
  { key: 2, value: 2, text: "March" },
  { key: 3, value: 3, text: "April" },
  { key: 4, value: 4, text: "May" },
  { key: 5, value: 5, text: "June" },
  { key: 6, value: 6, text: "July" },
  { key: 7, value: 7, text: "August" },
  { key: 8, value: 8, text: "September" },
  { key: 9, value: 9, text: "October" },
  { key: 10, value: 10, text: "November" },
  { key: 11, value: 11, text: "December" },
];

export default class ticketBillingTable extends Component {
  state = {
    currentID: "",
    userFilter: "",
    clientFilter: "",
    error: "",
    start_year: moment().get("year"),
    start_month: moment().get("month"),
    end_year: moment().get("year"),
    end_month: moment().get("month"),
    tickets: [],
    userList: [],
    clientList: [],
    ticketModal: false,
    invoiceModal: false,
    loading: true,
  };

  componentDidMount() {
    Promise.all([
      API.company.getAll(),
      API.current_user.getAllUserDetails(),
    ]).then((res) => {
      this.setState({
        clientList: res[0].map((item) => {
          return {
            text: item.name,
            value: item._id,
            key: item._id,
          };
        }),
        userList: res[1].map((item) => {
          return {
            text: item.fullName,
            value: item._id,
            key: item._id,
          };
        }),
      });
    });

    this.getTickets();
  }

  getTickets() {
    this.setState(
      {
        loading: true,
        error: "",
      },
      () => {
        API.billing
          .closed_tickets(
            this.state.start_month,
            this.state.start_year,
            this.state.end_month,
            this.state.end_year
          )
          .then((res) => {
            if (this.state.clientFilter !== "") {
              res = res.filter((item) => item._id === this.state.clientFilter);
            }

            if (this.state.userFilter !== "") {
              let finalTickets = [];
              res.forEach((company) => {
                let theTickets = [];
                company.tickets.forEach((ticket) => {
                  let times = ticket.times.filter(
                    (time) => time.author === this.state.userFilter
                  );
                  if (times.length > 0) {
                    theTickets.push({
                      ...ticket,
                      times,
                    });
                  }
                });

                if (theTickets.length > 0) {
                  finalTickets.push({
                    ...company,
                    tickets: theTickets,
                  });
                }

                res = finalTickets;
              });
            }

            this.setState({
              loading: false,
              tickets: res,
            });
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error:
                err.response !== undefined
                  ? err.response.data.message
                  : "Unexpected network issue",
              tickets: [],
            });
          });
      }
    );
  }

  exportTicketsByCompany = (id) => {
    this.setState(
      {
        printing: true,
        printingError: null,
      },
      () => {
        let company = this.state.tickets.find((item) => item._id === id);
        let total_ticket_time = 0;
        let total_remote = 0;
        let total_onsite = 0;
        let total_non_billable = 0;
        let tickets = company.tickets.map((item) => {
          let remote = 0;
          let onsite = 0;
          let non_billable = 0;

          item.times.forEach((time) => {
            if (time.location === "Onsite") {
              onsite = onsite + time.total_time;
              total_onsite = total_onsite + time.total_time;
              total_ticket_time = total_ticket_time + time.total_time;
            } else if (time.location === "Remote") {
              remote = remote + time.total_time;
              total_remote = total_remote + time.total_time;
              total_ticket_time = total_ticket_time + time.total_time;
            } else if (time.location === "Non-Billable") {
              non_billable = non_billable + time.total_time;
              total_non_billable = total_non_billable + time.total_time;
              total_ticket_time = total_ticket_time + time.total_time;
            }
          });

          return {
            ...item,
            createdDate: moment(item.createdDate).format("DD/MM/YYYY"),
            closedDate: moment(item.closedDate).format("DD/MM/YYYY"),
            contact: item.contact && item.contact.name,
            remote: remote,
            onsite: onsite,
            non_billable: non_billable,
          };
        });

        let header = [
          { csv_field: "Ticket Number", database_field: "_id" },
          { csv_field: "Created", database_field: "createdDate" },
          { csv_field: "Closed", database_field: "closedDate" },
          { csv_field: "Type", database_field: "ticketType" },
          { csv_field: "Title", database_field: "title" },
          { csv_field: "Contact", database_field: "contact" },
          { csv_field: "Remote", database_field: "remote" },
          { csv_field: "Onsite", database_field: "onsite" },
          { csv_field: "Non-Billable", database_field: "non_billable" },
        ];

        // generating csv rows and then downloading
        if (tickets && tickets.length !== 0) {
          let csv_rows = [];
          let csv_header = [];
          let csv_header_fields = [];

          header.forEach((item) => {
            csv_header.push(item.csv_field);
            csv_header_fields.push(item.database_field);
          });

          csv_rows.push(
            `Company, ${company.name}`,
            `Company Ref, ${company.company_ref}`,
            `SLA, ${company.sla}`,
            ""
          );

          csv_rows.push(csv_header.join(","));

          tickets.forEach((item) => {
            csv_rows.push(
              csv_header_fields
                .map((header) => {
                  let clean_value = (
                    item[header] === undefined ? "" : "" + item[header]
                  ).replace(/"|'/g, "");
                  return `"${clean_value}"`;
                })
                .join(",")
            );
          });

          csv_rows.push(
            "",
            `Total Remote, ${total_remote}`,
            `Total Onsite, ${total_onsite}`,
            `Total Non-Billable, ${total_non_billable}`,
            `Total, ${total_ticket_time}`
          );

          let data_blob = new Blob([csv_rows.join("\n")], { type: "text/csv" });
          let a = document.createElement("a");

          a.setAttribute("hidden", "");
          a.setAttribute("href", window.URL.createObjectURL(data_blob));
          a.setAttribute(
            "download",
            `${
              company.name.slice(0, 1).toUpperCase() + company.name.substring(1)
            } Ticket Export - ${moment().format("YYYY-MM-DD")}.csv`
          );
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    );
  };

  exportAllTickets = () => {
    this.setState(
      {
        printing: true,
        printingError: null,
      },
      () => {
        let companies = this.state.tickets.map((company) => {
          let total_ticket_time = 0;
          let total_remote = 0;
          let total_onsite = 0;
          let total_non_billable = 0;
          let tickets = company.tickets.map((ticket) => {
            let remote = 0;
            let onsite = 0;
            let non_billable = 0;

            ticket.times.forEach((time) => {
              if (time.location === "Onsite") {
                onsite = onsite + time.total_time;
                total_onsite = total_onsite + time.total_time;
                total_ticket_time = total_ticket_time + time.total_time;
              } else if (time.location === "Remote") {
                remote = remote + time.total_time;
                total_remote = total_remote + time.total_time;
                total_ticket_time = total_ticket_time + time.total_time;
              } else if (time.location === "Non-Billable") {
                non_billable = non_billable + time.total_time;
                total_non_billable = total_non_billable + time.total_time;
                total_ticket_time = total_ticket_time + time.total_time;
              }
            });

            return {
              ...ticket,
              createdDate: moment(ticket.createdDate).format("DD/MM/YYYY"),
              closedDate: moment(ticket.closedDate).format("DD/MM/YYYY"),
              contact: ticket.contact && ticket.contact.name,
              remote: remote,
              onsite: onsite,
              non_billable: non_billable,
            };
          });

          return {
            ...company,
            tickets: tickets,
            total_ticket_time,
            total_remote,
            total_onsite,
            total_non_billable,
          };
        });

        let header = [
          { csv_field: "Ticket Number", database_field: "_id" },
          { csv_field: "Created", database_field: "createdDate" },
          { csv_field: "Closed", database_field: "closedDate" },
          { csv_field: "Type", database_field: "ticketType" },
          { csv_field: "Title", database_field: "title" },
          { csv_field: "Contact", database_field: "contact" },
          { csv_field: "Remote", database_field: "remote" },
          { csv_field: "Onsite", database_field: "onsite" },
          { csv_field: "Non-Billable", database_field: "non_billable" },
        ];

        // generating csv rows and then downloading
        if (companies && companies.length !== 0) {
          let csv_rows = [];
          let csv_header = [];
          let csv_header_fields = [];

          header.forEach((item) => {
            csv_header.push(item.csv_field);
            csv_header_fields.push(item.database_field);
          });

          companies.forEach((item, index) => {
            if (index > 0) {
              csv_rows.push("");
            }

            csv_rows.push(
              `Company, ${item.name}`,
              `Company Ref, ${item.company_ref}`,
              `SLA, ${item.sla}`,
              ""
            );

            csv_rows.push(csv_header.join(","));

            item.tickets.forEach((ticket) => {
              csv_rows.push(
                csv_header_fields
                  .map((header) => {
                    let clean_value = (
                      ticket[header] === undefined ? "" : "" + ticket[header]
                    ).replace(/"|'/g, "");
                    return `"${clean_value}"`;
                  })
                  .join(",")
              );
            });

            csv_rows.push(
              "",
              `Total Remote, ${item.total_remote}`,
              `Total Onsite, ${item.total_onsite}`,
              `Total Non-Billable, ${item.total_non_billable}`,
              `Total, ${item.total_ticket_time}`
            );
          });

          let data_blob = new Blob([csv_rows.join("\n")], { type: "text/csv" });
          let a = document.createElement("a");

          a.setAttribute("hidden", "");
          a.setAttribute("href", window.URL.createObjectURL(data_blob));
          a.setAttribute(
            "download",
            `Ticket Export - ${moment().format("YYYY-MM-DD")}.csv`
          );
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    );
  };

  handleClose = (reload = false) => {
    if (this.state.ticketModal === true) {
      this.setState({ ticketModal: false });
      if (reload) {
        this.getTickets();
      }
    }
    if (this.state.invoiceModal === true) {
      this.setState({ invoiceModal: false });
    }
  };

  handleCheckboxClick = (data) => {
    API.tickets
      .invoiceUpdate(this.state.currentID, data)
      .then(() => {
        this.getTickets();
        this.handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDropdownChange = (e, data) => {
    this.setState({ [data.name]: data.value }, () => this.getTickets());
  };

  renderModalInvoice() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.invoiceModal}
        onClose={this.handleClose}
        size="small"
      >
        <Header icon="book" content="Billing Confirmation" />
        <Modal.Content>
          <InvoiceForm
            submit={this.handleCheckboxClick}
            close={this.handleClose}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderTable() {
    if (this.state.loading) {
      return (
        <Message positive={true}>
          <Message.Header>Loading...</Message.Header>
        </Message>
      );
    }

    if (this.state.error) {
      return <Message error>{this.state.error}</Message>;
    }

    if (this.state.tickets.length <= 0) {
      return (
        <Message info={true}>
          There are no closed support tickets for your query
        </Message>
      );
    }

    return this.state.tickets.map((company) => {
      let totalRemote = 0;
      let totalOnSite = 0;
      let totalNon = 0;
      for (let index in company.tickets) {
        let ticket = company.tickets[index];
        for (let indexTwo in ticket.times) {
          let time = ticket.times[indexTwo];
          switch (time.location) {
            case "Remote":
              totalRemote = totalRemote + time.total_time;
              break;
            case "Onsite":
              totalOnSite = totalOnSite + time.total_time;
              break;
            case "Non-Billable":
              totalNon = totalNon + time.total_time;
              break;
            default:
              break;
          }
        }
      }
      let totalTime = totalRemote + totalOnSite + totalNon;
      return (
        <div className="BillingTablePadding" key={company._id}>
          <Segment attached>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width="13" verticalAlign="top">
                  <h2>
                    {company.name}{" "}
                    {company.company_ref ? `(${company.company_ref})` : ""}
                  </h2>
                  <Menu>
                    <Menu.Item>
                      <Header>SLA: {company.sla}</Header>
                    </Menu.Item>
                    <Menu.Menu position="right">
                      <Menu.Item
                        onClick={() =>
                          this.props.history.push(
                            `/clients/show/${company._id}`
                          )
                        }
                      >
                        <Icon name="linkify" />
                        Go To Company
                      </Menu.Item>
                      <Popup
                        content={"Direct import into Xero, Coming Soon"}
                        trigger={
                          <Menu.Item disabled={true}>
                            <Icon name="money" />
                            Invoice
                          </Menu.Item>
                        }
                      />
                      <Popup
                        content={"Export list into .csv, Coming Soon"}
                        trigger={
                          <Menu.Item
                            onClick={() =>
                              this.exportTicketsByCompany(company._id)
                            }
                          >
                            <Icon name="download" />
                            Export .csv
                          </Menu.Item>
                        }
                      />
                    </Menu.Menu>
                  </Menu>
                  <Table selectable celled textAlign="center">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Ticket Number</Table.HeaderCell>
                        <Table.HeaderCell>Created</Table.HeaderCell>
                        <Table.HeaderCell>Closed</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Contact</Table.HeaderCell>
                        <Table.HeaderCell>Remote</Table.HeaderCell>
                        <Table.HeaderCell>Onsite</Table.HeaderCell>
                        <Table.HeaderCell>Non-Billable</Table.HeaderCell>
                        <Table.HeaderCell>Billed</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {company.tickets.map((ticket) => {
                        let totalRemote = 0;
                        let totalOnSite = 0;
                        let totalNon = 0;
                        for (let indexTwo in ticket.times) {
                          let time = ticket.times[indexTwo];
                          switch (time.location) {
                            case "Remote":
                              totalRemote = totalRemote + time.total_time;
                              break;
                            case "Onsite":
                              totalOnSite = totalOnSite + time.total_time;
                              break;
                            case "Non-Billable":
                              totalNon = totalNon + time.total_time;
                              break;
                            default:
                              break;
                          }
                        }
                        return (
                          <Table.Row key={ticket._id}>
                            <Table.Cell
                              onClick={() => {
                                this.setState({
                                  currentID: ticket._id,
                                  ticketModal: true,
                                });
                              }}
                            >
                              #{ticket._id}
                            </Table.Cell>
                            <Table.Cell
                              onClick={() => {
                                this.setState({
                                  currentID: ticket._id,
                                  ticketModal: true,
                                });
                              }}
                            >
                              {moment(ticket.createdDate).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </Table.Cell>
                            <Table.Cell
                              onClick={() => {
                                this.setState({
                                  currentID: ticket._id,
                                  ticketModal: true,
                                });
                              }}
                            >
                              {moment(ticket.closedDate).format(
                                "DD/MM/YYYY hh:mma"
                              )}
                            </Table.Cell>
                            <Table.Cell
                              onClick={() => {
                                this.setState({
                                  currentID: ticket._id,
                                  ticketModal: true,
                                });
                              }}
                            >
                              {ticket.ticketType}
                            </Table.Cell>
                            <Table.Cell
                              onClick={() => {
                                this.setState({
                                  currentID: ticket._id,
                                  ticketModal: true,
                                });
                              }}
                            >
                              {ticket.title}
                            </Table.Cell>
                            <Table.Cell
                              onClick={() => {
                                this.setState({
                                  currentID: ticket._id,
                                  ticketModal: true,
                                });
                              }}
                            >
                              {ticket.contact ? ticket.contact.name : "N/A"}
                            </Table.Cell>
                            <Table.Cell
                              onClick={() => {
                                this.setState({
                                  currentID: ticket._id,
                                  ticketModal: true,
                                });
                              }}
                            >
                              {totalRemote}
                            </Table.Cell>
                            <Table.Cell
                              onClick={() => {
                                this.setState({
                                  currentID: ticket._id,
                                  ticketModal: true,
                                });
                              }}
                            >
                              {totalOnSite}
                            </Table.Cell>
                            <Table.Cell
                              onClick={() => {
                                this.setState({
                                  currentID: ticket._id,
                                  ticketModal: true,
                                });
                              }}
                            >
                              {totalNon}
                            </Table.Cell>
                            {ticket.invoiced ? (
                              <Table.Cell
                                onClick={() => {
                                  this.setState({
                                    currentID: ticket._id,
                                  });
                                  setTimeout(() => {
                                    this.handleCheckboxClick();
                                  }, 500);
                                }}
                              >
                                <Checkbox checked={ticket.invoiced} />
                              </Table.Cell>
                            ) : (
                              <Table.Cell
                                onClick={() => {
                                  this.setState({
                                    currentID: ticket._id,
                                    invoiceModal: true,
                                  });
                                }}
                              >
                                <Checkbox checked={ticket.invoiced} />
                              </Table.Cell>
                            )}
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
                <Grid.Column style={{ paddingTop: "60px" }} width="3">
                  <Table selectable basic="very">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Usage Statistics</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Total
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <Icon name="ticket" />
                          Tickets:
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {company.tickets.length}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Icon name="desktop" />
                          Remote Time:
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {totalRemote}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Icon name="building" />
                          Onsite Time:
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {totalOnSite}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Icon name="money" />
                          Non Billable:
                        </Table.Cell>
                        <Table.Cell textAlign="center">{totalNon}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Icon name="time" />
                          Total:
                        </Table.Cell>
                        <Table.Cell textAlign="center">{totalTime}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </div>
      );
    });
  }

  renderModalTicketSummary() {
    return (
      <Modal
        centered={false}
        open={this.state.ticketModal}
        onClose={() => this.handleClose()}
        className="summaryModal"
      >
        <Header icon="ticket" content="Ticket Summary" />
        <Modal.Content>
          <TicketDisplay
            close={this.handleClose}
            data={this.state.currentID}
            userFilter={this.state.userFilter}
          />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderModalInvoice()}
        {this.renderModalTicketSummary()}
        <Grid>
          <Grid.Row columns="2">
            <Grid.Column>
              <h3>Display From:</h3>
              <Select
                name="start_month"
                placeholder="Start Month"
                options={monthOptions}
                value={this.state.start_month}
                onChange={this.handleDropdownChange}
              />
              <Select
                name="start_year"
                placeholder="Year"
                options={yearOptions}
                value={this.state.start_year}
                onChange={this.handleDropdownChange}
              />
            </Grid.Column>
            <Grid.Column>
              <h3>Select To Filter By Specific User:</h3>
              <Select
                name="userFilter"
                placeholder="Defaults To All"
                options={this.state.userList}
                value={this.state.userFilter}
                onChange={this.handleDropdownChange}
                clearable
                search
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <Grid.Column>
              <h3>Display To:</h3>
              <Select
                name="end_month"
                placeholder="End Month"
                options={monthOptions}
                value={this.state.end_month}
                onChange={this.handleDropdownChange}
              />
              <Select
                name="end_year"
                placeholder="End Year"
                options={yearOptions}
                value={this.state.end_year}
                onChange={this.handleDropdownChange}
              />
            </Grid.Column>
            <Grid.Column>
              <h3>Select To Filter By Specific Client:</h3>
              <Select
                name="clientFilter"
                placeholder="Defaults To All"
                options={this.state.clientList}
                value={this.state.clientFilter}
                onChange={this.handleDropdownChange}
                clearable
                search
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="1">
            <Grid.Column>
              <Button
                fluid
                content="Export Current Client Listing"
                icon="download"
                color="blue"
                onClick={() => this.exportAllTickets()}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider section />
        {this.renderTable()}
      </React.Fragment>
    );
  }
}
