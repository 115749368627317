import React, { Component } from "react";
import { Table, Segment, Button, Modal, Header, Icon } from "semantic-ui-react";
import DeleteForm from "../Forms/deleteForm";
import EditForm from "./forms/editServiceForm";
import api from "../../../../actions/api";
import { toast } from "react-toastify";

class servicesTable extends Component {
  state = {
    data: [],
    toggle_delete: false,
    toggle_edit: false,
    current_id: "",
  };

  renderTable() {
    const tickets = this.props.data.map((data) => {
      return (
        <Table.Row key={data._id}>
          <Table.Cell>{data.service}</Table.Cell>
          <Table.Cell width="2" textAlign="center">
            <Icon name={data.archived === true ? "checkmark" : "close"} />
          </Table.Cell>
          <Table.Cell width="3" textAlign="center">
            <Button
              onClick={() => {
                api.additional_services.get(data._id).then(() => {
                  this.setState({
                    toggle_edit: !this.state.toggle_edit,
                    data: data,
                  });
                });
              }}
              color="green"
            >
              Edit
            </Button>
            <Button
              onClick={() =>
                this.setState({
                  toggle_delete: !this.state.toggle_delete,
                  current_id: data._id,
                })
              }
              color="red"
            >
              Delete
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
    return tickets;
  }

  renderToast() {
    if (this.state.toggle_delete === true) {
      toast.warn("Ticket Deleted Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  renderModalDelete() {
    const onSubmit = () =>
      api.additional_services
        .remove(this.state.current_id)
        .then(() => {
          this.setState({ toggle_delete: false }, () => {
            this.renderToast();
            this.props.getData();
          });
        })
        .catch((err) => {
          console.log(err.response);
        });

    const handleClose = () => this.setState({ toggle_delete: false });

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_delete}
        onClose={handleClose}
        basic
        size="small"
      >
        <Header icon="user" content="Editing Service" />
        <Modal.Content>
          <DeleteForm submit={onSubmit} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  renderModalEdit() {
    const onSubmit = (data) =>
      api.additional_services
        .update(data._id, data)
        .then(() => {
          this.setState({ toggle_edit: false }, () => {
            this.renderToast();
            this.props.getData();
          });
        })
        .catch((err) => {
          console.log(err.response);
        });

    const handleClose = () => this.setState({ toggle_edit: false });

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_edit}
        onClose={handleClose}
        basic
        size="small"
      >
        <Header icon="user" content="Deleting Service" />
        <Modal.Content>
          <EditForm
            data={this.state.data}
            submit={onSubmit}
            close={handleClose}
          />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderModalDelete()}
        {this.renderModalEdit()}
        <Segment basic>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Service</Table.HeaderCell>
                <Table.HeaderCell>Archived</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.data.length !== 0 ? (
                this.renderTable()
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="3">No Data</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
      </React.Fragment>
    );
  }
}

export default servicesTable;
