import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Segment, Container, Header } from "semantic-ui-react";
import SettingRateList from "../common/lists/SettingRateList";
import SettingsList from "../common/lists/settingList";
import {
  getSystemSettings,
  updateSetting,
} from "../../actions/settingsActions";

function mapStateToProps(state) {
  return {
    system: state.system,
  };
}

class Dash extends Component {
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    return this.props.getSystemSettings();
  };

  handleSubmit = (data) => {
    return this.props.updateSetting(data).then(() => {
      this.getData();
    });
  };

  handleDelete = (data) => {
    this.props.updateSetting(data, () => {});
  };

  render() {
    return (
      <Container fluid>
        <Segment inverted attached>
          <Header textAlign="center" as="h2">
            System Settings
          </Header>
        </Segment>
        <Segment basic className="Margins">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width="8">
                <Segment>
                  <SettingRateList
                    submit={this.handleSubmit}
                    delete={this.handleDelete}
                    data={this.props.system.settings.rates}
                    placeholder="engineer..."
                    title="Default Rates"
                    field="rates"
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column width="8">
                <Segment>
                  <SettingsList
                    submit={this.handleSubmit}
                    delete={this.handleDelete}
                    data={this.props.system.settings.support_types}
                    placeholder="helpdesk..."
                    title="Contract Support Types"
                    field="support_types"
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    );
  }
}
export default connect(mapStateToProps, {
  getSystemSettings,
  updateSetting,
})(Dash);
