import React, { Component } from "react";
import { Form, Icon, Header, Divider, Label } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { toast } from "react-toastify";

const options = [
  { key: "paygo", text: "Pay-Go", value: "Pay-Go" },
  { key: "contract", text: "Contract", value: "Contract" },
];

export default class editClientForm extends Component {
  state = {
    data: {
      name: "",
      company_ref: "",
      sla: "",
      contract_start_date: "",
      telephone: "",
      email_address: "",
      website: "",
      firstedit: false,
    },
    errors: {},
  };

  componentDidMount() {
    this.setState({
      data: {
        ...this.props.data,
        primary_contact:
          this.props.data.primary_contact !== undefined
            ? this.props.data.primary_contact._id
            : "",
      },
    });
  }

  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  handleDateOnChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  handleChange(event) {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  }

  validate = (data) => {
    const errors = {};

    if (!data.name) errors.name = "Can't Be Empty";
    if (!data.sla) errors.sla = "Can't Be Empty";
    if (
      data.sla === "Contract" &&
      (data.contract_start_date === "" ||
        data.contract_start_date === undefined ||
        data.contract_start_date === null)
    )
      errors.contract_start_date = "Can't Be Empty";

    return errors;
  };

  onSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });

    if (Object.keys(errors).length === 0) {
      this.props.submit({ ...this.state.data, firstedit: false });
    } else {
      toast.error("Mandatory fields are missing data.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  render() {
    return (
      <Form>
        <Header>Basic Information</Header>
        <Divider />
        <Form.Group widths="equal">
          <Form.Input
            name="name"
            label="Client Name"
            value={this.state.data.name}
            onChange={this.onChange}
            placeholder="Client Name"
            error={this.state.errors.name}
          />
          <Form.Input
            name="company_ref"
            label="Account Number"
            value={this.state.data.company_ref}
            onChange={this.onChange}
            placeholder="Account Number"
          />
          <Form.Dropdown
            name="sla"
            label="Support Type"
            value={this.state.data.sla}
            onChange={this.handleDropdownChange}
            placeholder="Please Select A Response"
            options={options}
            selection
            clearable
            error={this.state.errors.sla}
          />
          {this.state.data.sla === "Contract" ? (
            <Form.Field
              error={this.state.errors.contract_start_date ? true : false}
            >
              <DateInput
                label="Starting Date Of Contract"
                name="contract_start_date"
                closable={true}
                value={
                  this.state.data.contract_start_date &&
                  this.state.data.contract_start_date !== null
                    ? this.state.data.contract_start_date
                    : ""
                }
                iconPosition="left"
                onChange={this.handleDateOnChange}
              />
              {this.state.errors.contract_start_date && (
                <Label pointing prompt>
                  {this.state.errors.contract_start_date}
                </Label>
              )}
            </Form.Field>
          ) : (
            <React.Fragment />
          )}
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            name="telephone"
            label="Telephone Number"
            value={this.state.data.telephone ? this.state.data.telephone : ""}
            onChange={this.onChange}
            placeholder="Telephone"
          />
          <Form.Input
            name="email_address"
            label="Email Address"
            value={
              this.state.data.email_address ? this.state.data.email_address : ""
            }
            onChange={this.onChange}
            placeholder="Email Address"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            name="website"
            label="Website"
            value={this.state.data.website ? this.state.data.website : ""}
            onChange={this.onChange}
            placeholder="Website"
          />
          <Form.Dropdown
            selection
            name="primary_contact"
            label="Primary Contact"
            value={this.state.data.primary_contact}
            onChange={this.handleDropdownChange}
            placeholder="Primary Contact"
            options={
              this.props.contactData !== undefined ? this.props.contactData : []
            }
          />
        </Form.Group>
        <Divider />
        <Form.Group widths="equal">
          <Form.Button
            type="submit"
            color="red"
            floated="left"
            onClick={() => this.props.close()}
          >
            <Icon name="close" />
            Cancel
          </Form.Button>
          <Form.Button
            color="green"
            floated="right"
            onClick={() => this.onSubmit()}
          >
            <Icon name="cloud upload" />
            Update
          </Form.Button>
        </Form.Group>
      </Form>
    );
  }
}
