import React, { Component } from "react";
import { Table, Header, Button, Icon, Modal, Segment } from "semantic-ui-react";
import AddForm from "./Forms/addNoteForm";
import EditForm from "./Forms/editNoteForm";
import api from "../../../actions/api";
import { toast } from "react-toastify";

export default class notesTab extends Component {
  state = {
    data: [],
    toggle_add: false,
    toggle_edit: false,
    activeNote: "",
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.company.company_notes
      .getNotes(this.props.iddata)
      .then((data) => this.setState({ data: data }))
      .catch((err) => {
        console.log(err.response);
      });
  };

  handleClose = () => {
    if (this.state.toggle_add === true) {
      this.setState({ toggle_add: false });
    }

    if (this.state.toggle_edit === true) {
      this.setState({ toggle_edit: false });
    }
  };

  handleDelete = () => {
    api.company.company_notes
      .deleteNote(this.props.iddata, this.state.activeNote)
      .then(() => {
        this.getData();
        toast.error("Note Deleted Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  onSubmit = (data) => {
    if (this.state.toggle_add === true) {
      return api.company.company_notes
        .addNote(this.props.iddata, data)
        .then(() => {
          this.renderToast();
          this.handleClose();
          this.getData();
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else if (this.state.toggle_edit === true) {
      api.company.company_notes
        .updateNote(this.props.iddata, this.state.activeNote, data)
        .then(() => {
          this.renderToast();
          this.handleClose();
          this.getData();
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  renderToast() {
    if (this.state.toggle_add === true) {
      toast.success("Note Added Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    if (this.state.toggle_edit === true) {
      toast.info("Note Updated Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  renderModalAdd() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_add}
        onClose={this.handleClose}
        basic
        size="small"
      >
        <Header icon="file alternate" content="Add New Note" />
        <Modal.Content>
          <AddForm submit={this.onSubmit} close={this.handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  renderModalEdit() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_edit}
        onClose={this.handleClose}
        basic
        size="small"
      >
        <Header icon="file alternate" content="Edit Note" />
        <Modal.Content>
          <EditForm
            idnumb={this.state.activeNote}
            iddata={this.props.iddata}
            submit={this.onSubmit}
            close={this.handleClose}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderTableNotes() {
    if (this.state.data.length === 0) {
      return (
        <Table.Row key={1}>
          <Table.Cell>No Notes</Table.Cell>
        </Table.Row>
      );
    } else {
      return this.state.data.map((data) => {
        return (
          <Table.Row key={data._id}>
            <Table.Cell width="2">{data.written_by}</Table.Cell>
            <Table.Cell>{data.message}</Table.Cell>
            <Table.Cell width="2" textAlign="center">
              <Button
                circular
                icon
                color="twitter"
                onClick={() =>
                  this.setState({
                    toggle_edit: !this.state.toggle_edit,
                    activeNote: data._id,
                  })
                }
              >
                <Icon name="pencil" />
              </Button>
              <Button
                circular
                icon
                color="red"
                onClick={() => {
                  this.setState(
                    {
                      activeNote: data._id,
                    },
                    () => this.handleDelete()
                  );
                }}
              >
                <Icon name="close" />
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {
    return (
      <Segment basic>
        {this.renderModalAdd()}
        {this.renderModalEdit()}
        <Header>
          Note List
          <Button
            floated="right"
            icon
            circular
            onClick={() =>
              this.setState({ toggle_add: !this.state.toggle_add })
            }
          >
            <Icon name="plus" color="green" />
          </Button>
        </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Author</Table.HeaderCell>
              <Table.HeaderCell>Note</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderTableNotes()}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
