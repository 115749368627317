import React, { Component } from "react";
import { Segment, Form, Icon, Checkbox, Select } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { toast } from "react-toastify";
import moment from "moment";

export default class EditDomainForm extends Component {
  state = {
    isManaged: false,
    registrars: [
      "Heart Internet",
      "Kristal",
      "123-Reg",
      "CloudFlare",
      "Namescheap",
      "Network Solutions",
      "Other",
    ],
    data: {
      domain: "",
      registrar: null,
      expires: "",
    },
  };
  constructor(props) {
    super(props);

    this.state.data = {
      ...props.data,
    };
    if (props.data.expires !== null) {
      this.state.data.expires = moment(props.data.expires).format("DD/MM/YYYY");
    } else {
      this.state.data.expires = "";
    }
    if (props.data.registrar !== null) {
      this.state.isManaged = true;
    }
  }

  /** API Requests */

  /** Handlers */
  handleRegisteredChange = (e, data) => {
    this.setState(
      {
        isManaged: data.checked,
      },
      () => {
        if (!data.checked) {
          this.setState({
            data: {
              ...this.state.data,
              registrar: null,
              expires: null,
            },
          });
        }
      }
    );
  };
  handleDateOnChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };
  handleOnChange = (event) => {
    const target = event.target;
    const value = target.value;
    const vname = target.name;
    this.setState({ data: { ...this.state.data, [vname]: value } });
  };
  handleDropdownChange = (props, e) => {
    this.setState({ data: { ...this.state.data, [e.name]: e.value } });
  };
  onSubmit = () => {
    if (this.state.data.domain === "") {
      toast.error("Missing Required Field", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.props
        .submit(this.state.data)
        .then(() => {
          this.props.close(true);
        })
        .catch((err) => {
          console.log(err, "error");
        });
    }
  };

  /** Renderers */
  render() {
    return (
      <Segment>
        <Form>
          <Form.Input
            fluid
            name="domain"
            value={this.state.data.domain}
            label="Domain Name"
            placeholder="e.g. liquid-computing.co.uk"
            onChange={this.handleOnChange}
          />
          <Form.Field
            control={Checkbox}
            label={"Is this domain registered though by us?"}
            onChange={this.handleRegisteredChange}
            checked={this.state.isManaged}
          />
          {this.state.isManaged && (
            <React.Fragment>
              <Form.Field
                name={"registrar"}
                control={Select}
                onChange={this.handleDropdownChange}
                placeholder={"Select Registrar"}
                value={this.state.data.registrar}
                options={this.state.registrars.map((item) => {
                  return {
                    key: item,
                    value: item,
                    text: item,
                  };
                })}
              />
              <DateInput
                name="expires"
                popupPosition={"bottom left"}
                dateFormat="DD/MM/YYYY"
                closable={true}
                autoComplete="nope"
                value={
                  this.state.data.expires !== null
                    ? this.state.data.expires
                    : ""
                }
                iconPosition="left"
                onChange={this.handleDateOnChange}
              />
            </React.Fragment>
          )}
          <Form.Group widths="equal">
            <Form.Button
              type="submit"
              color="red"
              floated="left"
              onClick={(e) => {
                e.preventDefault();
                this.props.close();
              }}
            >
              <Icon name="close" /> Cancel
            </Form.Button>
            <Form.Button
              type="submit"
              color="green"
              floated="right"
              onClick={this.onSubmit}
            >
              <Icon name="paper plane" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
