import React, { Component } from "react";
import { Table, Segment, Button, Modal, Header } from "semantic-ui-react";
import DeleteForm from "../Forms/deleteForm";
import api from "../../../../actions/api";
import { toast } from "react-toastify";

export default class clientTable extends Component {
  state = {
    data: [],
    toggle_delete: false,
    current_id: "",
    error: "",
  };

  renderTable() {
    if (this.props.data.length === 0) {
      return (
        <Table.Row key={1}>
          <Table.Cell>No Data</Table.Cell>
        </Table.Row>
      );
    } else {
      const client = this.props.data.map((data) => {
        return (
          <Table.Row key={data._id}>
            <Table.Cell>{data.company_ref}</Table.Cell>
            <Table.Cell>{data.name}</Table.Cell>
            <Table.Cell>{data.sla}</Table.Cell>
            <Table.Cell width="3">
              <Button
                fluid
                onClick={() =>
                  this.setState({
                    toggle_delete: !this.state.toggle_delete,
                    current_id: data._id,
                  })
                }
                color="red"
              >
                Delete Client Data
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
      return client;
    }
  }

  handleClose() {
    if (this.state.toggle_delete === true) {
      this.setState({ toggle_delete: false });
    }
  }

  onSubmit = (data) => {
    api.company
      .deleteCompany(this.state.current_id)
      .then(() => {
        this.props.getData();
        this.renderToast();
        this.handleClose();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  renderToast() {
    toast.warn("Client Deleted Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }

  renderModalDelete() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_delete}
        onClose={this.handleClose.bind(this)}
        basic
        size="small"
      >
        <Header icon="user" content="Deleting Client" />
        <Modal.Content>
          <DeleteForm
            submit={this.onSubmit}
            close={this.handleClose.bind(this)}
          />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderModalDelete()}
        <Segment basic>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Kashflow Account Number</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>SLA</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderTable()}</Table.Body>
          </Table>
        </Segment>
      </React.Fragment>
    );
  }
}
