import React, { Component } from "react";
import { Table, Header, Button, Icon, Modal, Segment } from "semantic-ui-react";
import AddForm from "./Forms/addHostingPackage";
import EditForm from "./Forms/editHostingPackage";
import api from "../../../actions/api";
import { toast } from "react-toastify";

class webHosting extends Component {
  state = {
    hosting_packages: [],
    toggle_add: false,
    toggle_edit: false,
    activeItem: "",
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.hosting_package.listAllCompany(this.props.iddata).then((res) => {
      this.setState({ hosting_packages: res });
    });
  };

  handleClose = () => {
    if (this.state.toggle_add === true) {
      this.setState({ toggle_add: false });
    }

    if (this.state.toggle_edit === true) {
      this.setState({ toggle_edit: false });
    }
  };

  handleDelete = () => {
    api.hosting_package.remove(this.state.activeItem).then(() => {
      this.getData();
      toast.error("Hosting Package Deleted Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
  };

  onSubmit = (data) => {
    if (this.state.toggle_add === true) {
      return api.hosting_package
        .add(data)
        .then(() => {
          this.renderToast();
          this.handleClose();
          this.getData();
        })
        .catch((err) => {
          console.log(err.response);
          toast.error("Error Submitting Data, Please Try Again", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    } else if (this.state.toggle_edit === true) {
      api.hosting_package
        .edit(this.state.activeItem, data)
        .then(() => {
          this.renderToast();
          this.handleClose();
          this.getData();
        })
        .catch((err) => {
          console.log(err.response);
          toast.error("Error Submitting Data, Please Try Again", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  };

  renderToast() {
    if (this.state.toggle_add === true) {
      toast.success("Domain Package Added Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    if (this.state.toggle_edit === true) {
      toast.info("Domain Package Updated Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  renderModalAdd() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_add}
        onClose={this.handleClose}
        basic
        size="small"
      >
        <Header icon="server" content="Add New Hosting Package" />
        <Modal.Content>
          <AddForm
            submit={this.onSubmit}
            close={this.handleClose}
            companyID={this.props.iddata}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderModalEdit() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_edit}
        onClose={this.handleClose}
        basic
        size="small"
      >
        <Header icon="server" content="Edit Hosting Package" />
        <Modal.Content>
          <EditForm
            idnumb={this.state.activeItem}
            companyID={this.props.iddata}
            submit={this.onSubmit}
            close={this.handleClose}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderBody() {
    return this.state.hosting_packages.map((data) => {
      return (
        <Table.Row key={data._id}>
          <Table.Cell width="2">{data.name}</Table.Cell>
          <Table.Cell>{data.client && data.client.name}</Table.Cell>
          <Table.Cell>{data.server && data.server.name}</Table.Cell>
          <Table.Cell>
            {data.domains !== undefined && data.domains.length !== 0
              ? data.domains.map((item) => {
                  return <p key={item._id}>{item.domain}</p>;
                })
              : "[ERROR]"}
          </Table.Cell>
          <Table.Cell width="2" textAlign="center">
            <Button
              circular
              icon
              color="twitter"
              onClick={() =>
                this.setState({
                  toggle_edit: !this.state.toggle_edit,
                  activeItem: data._id,
                })
              }
            >
              <Icon name="pencil" />
            </Button>
            <Button
              circular
              icon
              color="red"
              onClick={() => {
                this.setState(
                  {
                    activeItem: data._id,
                  },
                  () => this.handleDelete()
                );
              }}
            >
              <Icon name="close" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <Segment basic>
        {this.renderModalAdd()}
        {this.renderModalEdit()}
        <Header>
          Note List
          <Button
            floated="right"
            icon
            circular
            onClick={() =>
              this.setState({ toggle_add: !this.state.toggle_add })
            }
          >
            <Icon name="plus" color="green" />
          </Button>
        </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Package Name</Table.HeaderCell>
              <Table.HeaderCell>Client</Table.HeaderCell>
              <Table.HeaderCell>Server</Table.HeaderCell>
              <Table.HeaderCell>Domains</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.hosting_packages.length !== 0 ? (
              this.renderBody()
            ) : (
              <Table.Row>
                <Table.Cell>No Data</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default webHosting;
