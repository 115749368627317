const initialState = {
  settings: {},
  config: {},
  loading: false,
  error: null
};

export function system(state = initialState, action = {}) {
  switch (action.type) {
    case "SYSTEM_SETTINGS_BEGIN":
      return {
        ...state,
        loading: true,
        error: null
      };
    case "SYSTEM_SETTINGS_GOT":
      return {
        ...state,
        loading: false,
        settings: action.payload
      };
    case "SYSTEM_SETTINGS_FALIURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
        settings: {}
      };
    case "SYSTEM_SETTINGS_UPDATING":
      return {
        ...state,
        loading: false,
        settings: action.payload
      };
    case "SYSTEM_SETTINGS_CONFIG":
      return {
        ...state,
        config: action.payload
      };

    default:
      return state;
  }
}
