import React, { Component } from "react";
import { Form, Icon, Popup, Modal, Header } from "semantic-ui-react";
import api from "../../../actions/api";
import { toast } from "react-toastify";
import AddForm from "./addContactForm";
import _ from "lodash";
import moment from "moment";
import { DateInput } from "semantic-ui-calendar-react";

const ticketTypeList = [
  { key: "web", text: "Web", value: "Web" },
  { key: "helpdesk", text: "Helpdesk", value: "Helpdesk" },
  { key: "maintenance", text: "Maintenance", value: "Maintenance" },
  { key: "server", text: "Server", value: "Server" },
];

export default class addTicketForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticketdata: {
        title: "",
        company: "",
        details: "",
        contact: null,
        cc_contacts: [],
        assigned: "",
        ticketType: "",
        createdDate: moment.utc().format("DD-MM-YYYY"),
      },
      addContact: false,
      clientData: [],
      contactDetails: [],
      userList: [],
    };
  }

  componentDidMount() {
    this.getCompanyData();

    api.current_user
      .getAllUserDetails()
      .then((data) => {
        const filterData = _.filter(data, { groups: ["technician"] });
        let dataArray = [];
        for (var key in filterData) {
          dataArray.push({
            key: filterData[key]._id,
            text: filterData[key].fullName,
            value: filterData[key]._id,
          });
        }

        this.setState({ userList: dataArray });
      })
      .catch((err) => {
        console.log(err.response);
      });

    api.current_user
      .getUserDetails()
      .then((data) => {
        this.setState({
          ticketdata: { ...this.state.ticketdata, assigned: data._id },
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  getCompanyData() {
    api.company
      .getAll()
      .then((data) => {
        let dataArray = [];
        for (var key in data) {
          dataArray.push({
            key: data[key]._id,
            text: data[key].name,
            value: data[key]._id,
          });
        }
        this.setState({ clientData: dataArray });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  getContactData() {
    if (this.state.contactDetails) {
      api.company.contacts
        .allContacts(this.state.ticketdata.company)
        .then((data) => {
          let dataArray = [];
          for (var key in data) {
            dataArray.push({
              key: data[key]._id,
              text: data[key].name,
              value: data[key]._id,
            });
          }
          this.setState({ contactDetails: dataArray });
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }

  handleDropdownChange = (e, data) => {
    this.setState({
      ticketdata: { ...this.state.ticketdata, [data.name]: data.value },
    });

    if (data.name === "company") {
      setTimeout(() => {
        this.getContactData();
      }, 500);
    }
  };

  handleContactDropdownChange = (e, data) => {
    if (data.value === "") {
      this.setState({
        ticketdata: { ...this.state.ticketdata, [data.name]: null },
      });
    } else {
      this.setState({
        ticketdata: { ...this.state.ticketdata, [data.name]: data.value },
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      ticketdata: {
        ...this.state.ticketdata,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ ticketdata: { ...this.state.ticketdata, [name]: value } });
  };

  onSubmit = () => {
    if (
      this.state.ticketdata.createdDate === "" ||
      this.state.ticketdata.title === "" ||
      this.state.ticketdata.company === "" ||
      this.state.ticketdata.type === "" ||
      this.state.ticketdata.details === "" ||
      this.state.ticketdata.status === ""
    ) {
      toast.error("Missing Required Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.props.submit(this.state.ticketdata);
    }
  };

  renderAddContactModal() {
    const handleClose = () => this.setState({ addContact: false });

    const contactSubmit = (data) => {
      api.company.contacts
        .addContact(this.state.ticketdata.company, data)
        .then(() => {
          this.getContactData();
          this.setState({ addContact: false });
        });
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.addContact}
        onClose={handleClose}
        size="small"
      >
        <Header icon="user" content="Add New Contact" />
        <Modal.Content>
          <AddForm submit={contactSubmit} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Form>
        {this.renderAddContactModal()}
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="date">Date</label>
            <DateInput
              name="createdDate"
              placeholder="Date"
              value={this.state.ticketdata.createdDate || ""}
              iconPosition="left"
              popupPosition="bottom center"
              onChange={this.handleDateChange}
              startMode="year"
              closable
            />
          </Form.Field>
          <Form.Input
            name="title"
            label="Ticket Title"
            value={this.state.ticketdata.title}
            onChange={this.handleChange}
            placeholder="Ticket Title"
          />
          <Form.Dropdown
            name="company"
            label="Client"
            value={this.state.ticketdata.company}
            onChange={this.handleDropdownChange}
            placeholder="Client"
            options={
              this.state.clientData.length !== 0 ? this.state.clientData : []
            }
            selection
            search
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="ticketType"
            label="Ticket Type"
            value={this.state.ticketdata.ticketType}
            onChange={this.handleDropdownChange}
            placeholder="Please Select A Response"
            options={ticketTypeList}
            selection
            clearable
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.TextArea
            name="details"
            label="Details"
            value={this.state.ticketdata.details}
            onChange={this.handleChange}
            placeholder="Details"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="contact"
            label="Contact Name (Optional)"
            value={this.state.ticketdata.contact}
            onChange={this.handleContactDropdownChange}
            placeholder="Please Select A Response"
            options={this.state.contactDetails}
            selection
            clearable
            noResultsMessage={"No Contacts Available"}
            search
            disabled={this.state.contactDetails.length === 0 ? true : false}
          />
          <Form.Dropdown
            name="cc_contacts"
            label="CC Contact Name (Optional)"
            value={this.state.ticketdata.cc_contacts}
            onChange={this.handleDropdownChange}
            placeholder="Please Select A Response"
            options={this.state.contactDetails}
            multiple
            selection
            clearable
            noResultsMessage={"No Contacts Available"}
            search
            disabled={this.state.contactDetails.length === 0 ? true : false}
          />
          <Popup
            position="right center"
            content="Add Contact"
            style={{ marginTop: "22px" }}
            trigger={
              <Form.Button
                width="1"
                style={{ marginTop: "22px" }}
                icon="plus"
                color="green"
                disabled={this.state.ticketdata.company === "" ? true : false}
                onClick={() => {
                  this.setState({ addContact: true });
                }}
              />
            }
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="assigned"
            label="Assigned To"
            value={this.state.ticketdata.assigned}
            onChange={this.handleDropdownChange}
            placeholder="Please Select A Response"
            options={
              this.state.userList.length !== 0 ? this.state.userList : []
            }
            selection
            clearable
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Button
            type="submit"
            color="red"
            floated="left"
            onClick={() => {
              this.props.close();
            }}
          >
            <Icon name="close" />
            Cancel
          </Form.Button>
          <Form.Button
            color="green"
            floated="right"
            onClick={() => this.onSubmit()}
          >
            <Icon name="paper plane" />
            Submit
          </Form.Button>
        </Form.Group>
      </Form>
    );
  }
}
