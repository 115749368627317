import React, { Component } from "react";
import { Table, Segment, Icon, Button, Modal, Header } from "semantic-ui-react";
import DeleteForm from "../Forms/deleteForm";
import EditForm from "./forms/editUserForm";
import api from "../../../../actions/api";
import { toast } from "react-toastify";

export default class userTable extends Component {
  state = {
    data: [],
    toggle_delete: false,
    toggle_edit: false,
    current_id: "",
  };

  renderGroups(data) {
    const filterData = data.indexOf("admin");
    if (filterData !== -1) {
      return <Icon name="check" />;
    } else {
      return <Icon name="close" />;
    }
  }

  renderTable() {
    if (this.props.data.length === 0) {
      return (
        <Table.Row key={1}>
          <Table.Cell>No Users</Table.Cell>
        </Table.Row>
      );
    } else {
      const users = this.props.data.map((data) => {
        return (
          <Table.Row key={data._id}>
            <Table.Cell width="3">{data.fullName}</Table.Cell>
            <Table.Cell>{data.email}</Table.Cell>
            <Table.Cell width="1" textAlign="center">
              {this.renderGroups(data.groups)}
            </Table.Cell>
            <Table.Cell textAlign="center" width="4">
              <Button
                onClick={() => {
                  this.setState({
                    toggle_edit: !this.state.toggle_edit,
                    current_id: data._id,
                  });
                  this.getUserData();
                }}
                color="green"
              >
                Edit User
              </Button>
              <Button
                onClick={() =>
                  this.setState({
                    toggle_delete: !this.state.toggle_delete,
                    current_id: data._id,
                  })
                }
                color="red"
              >
                Delete User
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
      return users;
    }
  }

  handleClose() {
    if (this.state.toggle_delete === true) {
      this.setState({ toggle_delete: false, data: [] });
    }

    if (this.state.toggle_edit === true) {
      this.setState({ toggle_edit: false, data: [] });
    }
  }

  onSubmit = (data) => {
    if (this.state.toggle_delete === true) {
      return api.management.users
        .deleteUser(this.state.current_id)
        .then(() => {
          this.renderToast();
          this.props.getData();
          this.handleClose();
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
    if (this.state.toggle_edit === true) {
      return api.management.users
        .editUser(this.state.current_id, data)
        .then(() => {
          this.renderToast();
          this.props.getData();
          this.handleClose();
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  renderToast() {
    if (this.state.toggle_delete === true) {
      toast.warn("User Deleted Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    if (this.state.toggle_edit === true) {
      toast.info("User Updated Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  renderModalDelete() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_delete}
        onClose={this.handleClose.bind(this)}
        basic
        size="small"
      >
        <Header icon="user" content="Deleting User" />
        <Modal.Content>
          <DeleteForm
            submit={this.onSubmit}
            close={this.handleClose.bind(this)}
          />
        </Modal.Content>
      </Modal>
    );
  }

  getUserData() {
    setTimeout(() => {
      api.management.users.getUser(this.state.current_id).then((data) => {
        this.setState({ data: data });
      });
    }, 500);
  }

  renderModalEdit() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_edit}
        onClose={this.handleClose.bind(this)}
        basic
        size="small"
      >
        <Header icon="user" content="Editing User" />
        <Modal.Content>
          <EditForm
            data={this.state.data}
            submit={this.onSubmit}
            close={this.handleClose.bind(this)}
          />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderModalDelete()}
        {this.renderModalEdit()}
        <Segment basic>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Admin</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderTable()}</Table.Body>
          </Table>
        </Segment>
      </React.Fragment>
    );
  }
}
