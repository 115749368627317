import React, { Component } from "react";
import {
  Segment,
  Header,
  Transition,
  Container,
  Input,
  Table,
  Modal,
  Button,
} from "semantic-ui-react";
import api from "../../actions/api";
import ViewResult from "./viewResult";

class index extends Component {
  state = {
    domains: [],
    onLoad: { visible: false },
    toggle_view: false,
    current_item: "",
  };

  componentDidMount() {
    this.setState({ onLoad: { visible: true } });
  }

  handleSearch = (searchQuery) => {
    api.hosting_package
      .packageLookup(searchQuery)
      .then((res) => {
        this.setState({ domains: res });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  renderViewModal() {
    const handleClose = () => {
      this.setState({ toggle_view: false, current_item: "" });
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_view}
        onClose={handleClose}
        basic
        size="large"
      >
        <Header
          icon="file text outline"
          content="Advanced Search Results View"
        />
        <Modal.Content>
          <ViewResult currentItem={this.state.current_item._id} />
        </Modal.Content>
        <Modal.Actions>
          <Button floated="left" onClick={() => handleClose()} color="red">
            Close
          </Button>
          <Button
            onClick={() =>
              this.props.history.push(
                `/clients/show/${this.state.current_item.client._id}`
              )
            }
            color="blue"
          >
            Go To Company Page
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  renderTable() {
    return this.state.domains.map((item) => {
      return (
        <Table.Row
          key={item._id}
          onClick={() =>
            this.setState({ toggle_view: true, current_item: item })
          }
        >
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell>
            <ul>
              {item.domains !== undefined && item.domains !== null
                ? item.domains.map((domain) => {
                    return <li key={domain._id}>{domain.domain}</li>;
                  })
                : "[ERROR]"}
            </ul>
          </Table.Cell>
          <Table.Cell>
            {item.client !== undefined && item.client !== null
              ? item.client.name
              : "[ERROR]"}
          </Table.Cell>
          <Table.Cell>
            {item.server !== undefined && item.server !== null
              ? item.server.name
              : "[ERROR]"}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.renderViewModal()}
        <Segment inverted attached textAlign="center">
          <Header as="h2">Domain Lookup</Header>
        </Segment>
        <Transition
          visible={this.state.onLoad.visible}
          duration={1000}
          animation="fade"
        >
          <Container fluid>
            <Segment basic className="Margins">
              <Segment attached>
                <Input
                  name="search"
                  fluid
                  placeholder="Search Via Domain Name..."
                  onChange={(event) => {
                    if (event.target.value.length > 2) {
                      this.handleSearch(event.target.value);
                    } else if (event.target.value.length < 2) {
                      this.setState({ domains: [] });
                    }
                  }}
                />
                <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Hosting Package Name</Table.HeaderCell>
                      <Table.HeaderCell>Associated Domains</Table.HeaderCell>
                      <Table.HeaderCell>Company</Table.HeaderCell>
                      <Table.HeaderCell>Server</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.domains.length !== 0 ? (
                      this.renderTable()
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan="4">
                          No Domains To Display
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Segment>
            </Segment>
          </Container>
        </Transition>
      </React.Fragment>
    );
  }
}

export default index;
