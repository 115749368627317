import React, { Component } from "react";
import { Form, Segment, Icon, Label } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { toast } from "react-toastify";
import moment from "moment";

const options = [
  { text: "Placeholder", value: "Placeholder", key: "Placeholder" },
];

class editForm extends Component {
  state = {
    data: {
      name: "",
      type: "",
      setup_date: "",
      renewal_date: "",
      provider_management_url: "",
    },
    errors: {},
  };

  componentDidMount() {
    if (this.state.data !== this.props.data) {
      this.setState({
        data: {
          ...this.props.data,
          setup_date: moment(this.props.data.setup_date).format("DD-MM-YYYY"),
          renewal_date: moment(this.props.data.renewal_date).format(
            "DD-MM-YYYY"
          ),
        },
      });
    }
  }

  validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Can't Be Empty.";
    if (!data.type) errors.type = "Can't Be Empty.";
    if (!data.setup_date) errors.setup_date = "Can't Be Empty.";
    if (!data.renewal_date) errors.renewal_date = "Can't Be Empty.";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    } else {
      toast.error("Missing Required Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="name"
              label="Name"
              value={this.state.data.name}
              onChange={this.handleChange}
              placeholder="Name"
              error={this.state.errors.name}
            />
            <Form.Dropdown
              name="type"
              label="Type"
              value={this.state.data.type}
              onChange={this.handleDropdownChange}
              placeholder="Please Select A Response"
              options={options}
              selection
              error={this.state.errors.type}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={this.state.errors.setup_date}>
              <label htmlFor="setup_date">Setup Date</label>
              <DateInput
                name="setup_date"
                placeholder="Setup Date"
                value={this.state.data.setup_date || ""}
                iconPosition="left"
                popupPosition="bottom center"
                onChange={this.handleDateChange}
                startMode="year"
                clearable
              />
              {this.state.errors.setup_date && (
                <Label pointing prompt>
                  Can't Be Empty.
                </Label>
              )}
            </Form.Field>
            <Form.Field error={this.state.errors.renewal_date}>
              <label htmlFor="renewal_date">Renewal Date</label>
              <DateInput
                name="renewal_date"
                placeholder="Renewal Date"
                value={this.state.data.renewal_date || ""}
                iconPosition="left"
                popupPosition="bottom center"
                onChange={this.handleDateChange}
                startMode="year"
                clearable
              />
              {this.state.errors.renewal_date && (
                <Label pointing prompt>
                  Can't Be Empty.
                </Label>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="provider_management_url"
              label="Provider Management URL"
              value={this.state.data.provider_management_url}
              onChange={this.handleChange}
              placeholder="Provider Management URL"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              type="submit"
              color="red"
              floated="left"
              onClick={() => {
                this.props.close();
              }}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              type="submit"
              color="green"
              floated="right"
              onClick={() => this.handleSubmit()}
            >
              <Icon name="paper plane" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default editForm;
