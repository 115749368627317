import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { toast } from "react-toastify";

export default class invoiceForm extends Component {
  state = {
    data: {
      invoiced_numb: "",
    },
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  onSubmit = () => {
    if (this.state.data.invoiced_numb === "") {
      toast.error("Missing Required Field", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            name="invoiced_numb"
            label="Invoice Number"
            value={this.state.data.invoiced_numb}
            onChange={this.handleChange}
            placeholder="Please Input Invoice Number"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Button
            color="red"
            icon="close"
            content="Cancel"
            floated="left"
            onClick={() => {
              this.props.close();
            }}
          />
          <Form.Button
            color="green"
            floated="right"
            icon="paper plane"
            content="Submit"
            onClick={() => this.onSubmit()}
          />
        </Form.Group>
      </Form>
    );
  }
}
