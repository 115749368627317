import React from "react";
import Settings from "./Settings_Context";
import API from "../actions/api";

export default class Settings_Receiver extends React.Component {
    state = {
        settings: {}
    }
    componentDidMount(){
        this._refreshSettings();
    }
    _refreshSettings = () => {
        API.settings.getSystemSettings().then(res => {
            this.setState({
                settings: res
            });
        });
    }
    render(){
        return (
            <Settings.Provider value={{
                _refresh: this._refreshSettings,
                settings: this.state.settings
            }}>{this.props.children}</Settings.Provider>
        )
    }
}