import React, { Component } from "react";
import { Form, Segment, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";

class addServiceForm extends Component {
  state = {
    data: {
      service: "",
    },
  };

  onSubmit = () => {
    if (this.state.data.service === "") {
      toast.error("Missing Required Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.props.submit(this.state.data);
    }
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="service"
              label="Service Name"
              value={this.state.data.service}
              onChange={this.handleChange}
              placeholder="Service"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              color="red"
              floated="left"
              onClick={() => this.props.close()}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              color="green"
              floated="right"
              onClick={() => this.onSubmit()}
            >
              <Icon name="paper plane" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default addServiceForm;
