import React, { Component } from "react";
import { Form, Segment, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";

const options = [
  { key: "admin", text: "Admin", value: "admin" },
  { key: "technician", text: "Technician", value: "technician" },
];

export default class addUserForm extends Component {
  state = {
    data: {
      email: "",
      password: "",
      fullName: "",
      groups: [],
    },
  };

  onSubmit = () => {
    if (
      this.state.data.email === "" ||
      this.state.data.password === "" ||
      this.state.data.fullName === "" ||
      this.state.data.groups === undefined
    ) {
      toast.error("Missing Required Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.props.submit(this.state.data);
    }
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="fullName"
              label="Full Name"
              value={this.state.data.fullName}
              onChange={this.handleChange}
              placeholder="Full Name"
            />
            <Form.Input
              name="email"
              label="Email"
              value={this.state.data.email}
              onChange={this.handleChange}
              placeholder="Email"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="password"
              label="Password"
              type="password"
              value={this.state.data.password}
              onChange={this.handleChange}
              placeholder="Password"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Dropdown
              name="groups"
              label="Groups"
              defaultValue={this.state.data.groups}
              onChange={this.handleDropdownChange}
              placeholder="Please Select A Response"
              options={options}
              multiple
              selection
              clearable
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              type="submit"
              color="red"
              floated="left"
              onClick={() => {
                this.props.close();
              }}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              type="submit"
              color="green"
              floated="right"
              onClick={() => this.onSubmit()}
            >
              <Icon name="paper plane" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
