import React, { Component } from "react";
import {
  Segment,
  Header,
  Modal,
  Container,
  Transition,
  Button,
  Table,
  Loader,
} from "semantic-ui-react";
import api from "../../actions/api";
import AddForm from "./Forms/addClientForm";
import FilteredPagedList from "../common/lists/filteredPagedList";
import { toast } from "react-toastify";

export default class index extends Component {
  state = {
    data: [],
    toggle_add: false,
    loading: false,
    onLoad: { visible: false },
    error: "",
  };

  componentDidMount() {
    this.setState({
      onLoad: { visible: true },
    });
  }

  handleChange() {
    this.setState({ toggle_add: !this.state.toggle_add });
  }

  renderModal() {
    const handleClose = () => {
      if (this.state.toggle_add === true) {
        this.setState({ toggle_add: false });
      }
    };

    const handleSubmit = (data) => {
      this.setState({ loading: true });
      api.company
        .addCompany(data)
        .then(() => {
          handleClose();
          this.renderToast();
          this.setState({ loading: false });
        })
        .catch((err) => {
          console.log(err.response);
          this.setState({ loading: false });
        });
    };
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_add}
        onClose={handleClose}
      >
        <Header icon="group" content="Add New Client" />
        <Modal.Content>
          <AddForm submit={handleSubmit} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  renderToast() {
    toast.success("Client Added Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }

  render() {
    return (
      <Container fluid>
        <Segment inverted attached>
          <Header textAlign="center" as="h2">
            Client List
          </Header>
        </Segment>
        <Transition
          visible={this.state.onLoad.visible}
          duration={750}
          animation="fade"
        >
          {this.state.loading === false ? (
            <Segment className="Margins">
              {this.renderModal()}
              <Button
                onClick={() => {
                  this.setState({ toggle_add: true });
                }}
                fluid
                color="green"
                icon="plus"
                content="Add Client"
                size="large"
              />
              <FilteredPagedList
                header={() => {
                  return (
                    <Table.Row>
                      <Table.HeaderCell width="3">
                        Account Number
                      </Table.HeaderCell>
                      <Table.HeaderCell width="7">Name</Table.HeaderCell>
                      <Table.HeaderCell width="3">SLA</Table.HeaderCell>
                      <Table.HeaderCell width="3">
                        Active Tickets
                      </Table.HeaderCell>
                    </Table.Row>
                  );
                }}
                body={(data) => {
                  return (
                    <Table.Row
                      className="pointer"
                      key={data._id}
                      onClick={() => {
                        this.props.history.push(`/clients/show/${data._id}`);
                      }}
                    >
                      <Table.Cell>{data.company_ref}</Table.Cell>
                      <Table.Cell>{data.name}</Table.Cell>
                      <Table.Cell>{data.sla}</Table.Cell>
                      <Table.Cell>{data._activeTickets}</Table.Cell>
                    </Table.Row>
                  );
                }}
                noItems={() => {
                  return (
                    <Table.Row key="1">
                      <Table.Cell colSpan={4}>
                        There Are No Companies To Display...
                      </Table.Cell>
                    </Table.Row>
                  );
                }}
                query={(page, size, searchQuery, filters) => {
                  return new Promise((resolve, reject) => {
                    api.company
                      .getAllPaged(page, size, searchQuery, filters)
                      .then((data) => {
                        resolve(data);
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  });
                }}
                filter={[
                  {
                    key: "sla",
                    title: "SLA",
                    default: "All",
                    options: [
                      {
                        title: "Pay-Go",
                        value: "Pay-Go",
                      },
                      {
                        title: "Contract",
                        value: "Contract",
                      },
                      {
                        title: "All",
                        value: "All",
                      },
                    ],
                  },
                  {
                    key: "order_by",
                    title: "Order By",
                    default: "asc",
                    options: [
                      {
                        title: "A-Z",
                        value: "asc",
                      },
                      {
                        title: "Z-A",
                        value: "desc",
                      },
                    ],
                  },
                ]}
              />
            </Segment>
          ) : (
            <Segment padded className="Margins">
              <Loader active />
            </Segment>
          )}
        </Transition>
      </Container>
    );
  }
}
