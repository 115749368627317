import React, { Component } from "react";
import { Table, Segment, Header } from "semantic-ui-react";
import Moment from "react-moment";
import _ from "lodash";
import api from "../../../../../web_app/src/actions/api";

export default class inactiveTickets extends Component {
  state = {
    data: {},
  };

  componentDidMount() {
    api.company
      .getCompany(this.props.iddata)
      .then((data) => {
        this.setState({ data: data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  renderCCContacts = (data) => {
    return data.map((data) => {
      return (
        <p key={data._id}>{data.name !== undefined ? data.name : "ERROR"}</p>
      );
    });
  };

  renderTable() {
    const filterData = _.filter(this.props.data, {
      status: "Complete",
    });
    const finalFilter = _.filter(filterData, {
      company: { name: this.state.data.name },
    });

    if (finalFilter.length !== 0) {
      return finalFilter.map((data) => {
        return (
          <Table.Row
            className="pointer"
            key={data._id}
            onClick={() => {
              this.props.history.push(`/tickets/show/${data._id}`);
            }}
          >
            <Table.Cell>
              <Moment format="DD / MM / YYYY">{data.createdDate}</Moment>
            </Table.Cell>
            <Table.Cell>{data.title}</Table.Cell>
            <Table.Cell>{data.company.name}</Table.Cell>
            <Table.Cell>
              {data.contact !== undefined && data.contact !== null
                ? data.contact.name
                : "[NO CONTACT]"}
            </Table.Cell>
            <Table.Cell>
              {data.cc_contacts !== undefined && data.cc_contacts.length
                ? this.renderCCContacts(data.cc_contacts)
                : "[NO CC CONTACTS]"}
            </Table.Cell>
            <Table.Cell>
              {data.status !== undefined ? data.status : "[NO STATUS]"}
            </Table.Cell>
          </Table.Row>
        );
      });
    } else {
      return (
        <Table.Row key={1}>
          <Table.Cell colSpan="6">No Tickets</Table.Cell>
        </Table.Row>
      );
    }
  }

  render() {
    return (
      <Segment basic>
        <Header>Inactive Tickets</Header>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Ticket Title</Table.HeaderCell>
              <Table.HeaderCell>Client</Table.HeaderCell>
              <Table.HeaderCell>Contact</Table.HeaderCell>
              <Table.HeaderCell>CC Contacts</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderTable()}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
