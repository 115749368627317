import React, { Component } from "react";
import { Icon, Header, Segment, Grid } from "semantic-ui-react";
import Moment from "react-moment";
import ReplyForm from "./Forms/replyForm";

export default class TicketNotesAndResponses extends Component {
  onSubmit = (data) => {
    this.props.submit(data);
  };

  renderText(message) {
    if (message === undefined) {
      message = "";
    }
    if (message === null) {
      message = "";
    }
    let priorLineBlank = false;
    return (
      <p>
        {message
          .split("\n")
          .map((item, key) => {
            if (item.trim() === "") {
              if (priorLineBlank) {
                return null;
              }
              priorLineBlank = true;
            } else {
              priorLineBlank = false;
            }
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            );
          })
          .filter((i) => i !== null)}
      </p>
    );
  }
  renderGrid() {
    const data = this.props.data;
    const chat = data.map((data) => {
      if (data.type === "note") {
        return (
          <Grid.Row className="messengerRow" key={data._id}>
            <Grid.Column className="messengerColumn" />
            <Grid.Column width={16} className="messengerColumn">
              <Segment floated="right" compact className="messengerSegment">
                <p className="messengerHeaderText">
                  <Icon name="sticky note" />
                  <br />
                  {data.written_by} at{" "}
                  <Moment format="HH:mm">{data.createdAt}</Moment>
                  <br />
                  <Moment format="DD/MM/YYYY">{data.createdAt}</Moment>
                </p>
                {this.renderText(data.message)}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        );
      }

      if (data.type === "response") {
        return (
          <Grid.Row className="messengerRow" key={data._id}>
            <Grid.Column width={16} className="messengerColumn">
              <Segment
                inverted
                color="blue"
                floated="left"
                compact
                className="messengerSegment"
              >
                <p className="messengerHeaderText">
                  <Icon name="comment" />
                  <br />
                  {data.written_by} at{" "}
                  <Moment format="HH:mm">{data.createdAt}</Moment>
                  <br />
                  <Moment format="DD/MM/YYYY">{data.createdAt}</Moment>
                </p>
                {this.renderText(data.message)}
              </Segment>
            </Grid.Column>
            <Grid.Column className="messengerColumn" />
          </Grid.Row>
        );
      }

      if (data.type === "user_response") {
        return (
          <Grid.Row className="messengerRow" key={data._id}>
            <Grid.Column className="messengerColumn" />
            <Grid.Column width={16} className="messengerColumn">
              <Segment
                inverted
                color="green"
                floated="right"
                compact
                className="messengerSegment"
              >
                <p className="messengerHeaderText">
                  <Icon name="reply" />
                  <br />
                  {data.written_by} at{" "}
                  <Moment format="HH:mm">{data.createdAt}</Moment>
                  <br />
                  <Moment format="DD/MM/YYYY">{data.createdAt}</Moment>
                </p>
                {this.renderText(data.message)}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        );
      }

      if (data.type === "status_change") {
        return (
          <Grid.Row className="messengerRow" key={data._id}>
            <Grid.Column className="messengerColumn" />
            <Grid.Column width={16} className="messengerColumn">
              <Segment floated="right" color="grey" inverted compact>
                <p className="messengerHeaderText">
                  <Icon name="wrench" />
                  <br />
                  {data.written_by} at{" "}
                  <Moment format="HH:mm">{data.createdAt}</Moment>
                  <br />
                  <Moment format="DD/MM/YYYY">{data.createdAt}</Moment>
                </p>
                <p>
                  Status Changed To:
                  <br />
                  {data.status}
                </p>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        );
      }

      if (data.type === "assignment") {
        return (
          <Grid.Row className="messengerRow" key={data._id}>
            <Grid.Column className="messengerColumn" />
            <Grid.Column width={16} className="messengerColumn">
              <Segment floated="right" color="grey" inverted compact>
                <p className="messengerHeaderText">
                  <Icon name="wrench" />
                  <br />
                  {data.written_by} at{" "}
                  <Moment format="HH:mm">{data.createdAt}</Moment>
                  <br />
                  <Moment format="DD/MM/YYYY">{data.createdAt}</Moment>
                </p>
                <p>
                  Assigned To:
                  <br />
                  {data.assigned_to ? data.assigned_to.fullName : "--"} by{" "}
                  {data.assigned_by}
                </p>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        );
      }

      return [];
    });

    return chat;
  }

  render() {
    return (
      <React.Fragment>
        <Header as="h3" textAlign="center">
          Ticket Log
        </Header>
        <ReplyForm submit={this.onSubmit} />
        {this.props.data.length !== 0 ? (
          <Grid celled columns={2}>
            {this.renderGrid()}
          </Grid>
        ) : (
          <Segment basic>
            <Header textAlign="center" as="h3">
              No Messages To Be Displayed
            </Header>
          </Segment>
        )}
      </React.Fragment>
    );
  }
}
