import React, { Component } from "react";
import { Segment, Icon, Form } from "semantic-ui-react";
import { toast } from "react-toastify";

export default class addContactForm extends Component {
  state = {
    data: {
      name: "",
      email_address: "",
      phone_number: "",
      mobile_number: "",
    },
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const vname = target.name;

    this.setState({ data: { ...this.state.data, [vname]: value } });
  };

  onSubmit = () => {
    if (this.state.data.phone_number === "" || this.state.data.name === "") {
      toast.error("Please Fill In All Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="name"
              value={this.state.data.name}
              label="Name Of Client"
              placeholder="Client Name"
              onChange={this.handleChange}
            />
            <Form.Input
              fluid
              name="email_address"
              value={this.state.data.email_address}
              label="Email Address (Optional)"
              placeholder="Email Address"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="phone_number"
              value={this.state.data.phone_number}
              label="Phone Number"
              placeholder="Phone Number"
              onChange={this.handleChange}
            />
            <Form.Input
              fluid
              name="mobile_number"
              value={this.state.data.mobile_number}
              label="Mobile Number (Optional)"
              placeholder="Mobile Number"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              type="submit"
              color="red"
              floated="left"
              onClick={() => {
                this.props.close();
              }}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              color="green"
              floated="right"
              onClick={() => this.onSubmit()}
            >
              <Icon name="paper plane" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
