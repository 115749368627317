import React, { Component } from "react";
import { Table, Segment, Button, Modal, Header } from "semantic-ui-react";
// import DeleteForm from "../forms/deleteForm";
import EditForm from "./forms/editForm";
import api from "../../../../actions/api";
import { toast } from "react-toastify";

class hostingServerTable extends Component {
  state = {
    data: [],
    toggle_delete: false,
    toggle_edit: false,
    current_id: "",
  };

  renderTable() {
    const tickets = this.props.data.map((data) => {
      return (
        <Table.Row key={data._id}>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{data.provider.name}</Table.Cell>
          <Table.Cell>
            {data.management_url !== null && data.management_url !== "" ? (
              <a
                href={
                  data.management_url.search("https://") === -1
                    ? `https://${data.management_url}`
                    : data.management_url
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.management_url.search("https://") === -1
                  ? `https://${data.management_url}`
                  : data.management_url}
              </a>
            ) : (
              "No URL Provided"
            )}
          </Table.Cell>
          <Table.Cell>
            {data.meta !== undefined ? (
              <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                <li>RAM: {data.meta.ram !== "" ? data.meta.ram : "N/A"}</li>
                <li>
                  CPU Cores:{" "}
                  {data.meta.cpu_cores !== "" ? data.meta.cpu_cores : "N/A"}
                </li>
                <li>
                  Storage:{" "}
                  {data.meta.storage !== "" ? data.meta.storage : "N/A"}
                </li>
              </ul>
            ) : (
              "[ERROR DISPLAYING DATA]"
            )}
          </Table.Cell>
          <Table.Cell width="3" textAlign="center">
            <Button
              onClick={() => {
                api.management.hosting_server.findOne(data._id).then(() => {
                  this.setState({
                    toggle_edit: !this.state.toggle_edit,
                    data: data,
                  });
                });
              }}
              color="green"
            >
              Edit
            </Button>
            {/* <Button
              onClick={() =>
                this.setState({
                  toggle_delete: !this.state.toggle_delete,
                  current_id: data._id,
                })
              }
              color="red"
            >
              Delete
            </Button> */}
          </Table.Cell>
        </Table.Row>
      );
    });
    return tickets;
  }

  renderToast() {
    // if (this.state.toggle_delete === true) {
    //   toast.warn("Ticket Deleted Successfully", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //   });
    // }

    if (this.state.toggle_edit === true) {
      toast.warn("Hosting Server Updated Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  //   renderModalDelete() {
  //     const onSubmit = () =>
  //       api.additional_services
  //         .remove(this.state.current_id)
  //         .then(() => {
  //           this.setState({ toggle_delete: false }, () => {
  //             this.renderToast();
  //             this.props.getData();
  //           });
  //         })
  //         .catch((err) => {
  //           console.log(err.response);
  //         });

  //     const handleClose = () => this.setState({ toggle_delete: false });

  //     return (
  //       <Modal
  //         closeOnDimmerClick={false}
  //         open={this.state.toggle_delete}
  //         onClose={handleClose}
  //         basic
  //         size="small"
  //       >
  //         <Header icon="user" content="Deleting Hosting Provider" />
  //         <Modal.Content>
  //           <DeleteForm submit={onSubmit} close={handleClose} />
  //         </Modal.Content>
  //       </Modal>
  //     );
  //   }

  renderModalEdit() {
    const onSubmit = (data) =>
      api.management.hosting_server
        .edit(data._id, data)
        .then(() => {
          this.setState({ toggle_edit: false, current_id: "" }, () => {
            this.renderToast();
            this.props.getData();
          });
        })
        .catch((err) => {
          console.log(err.response);
        });

    const handleClose = () => this.setState({ toggle_edit: false });

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_edit}
        onClose={handleClose}
        basic
        size="small"
      >
        <Header icon="list alternate" content="Updating Hosting Server" />
        <Modal.Content>
          <EditForm
            data={this.state.data}
            submit={onSubmit}
            close={handleClose}
          />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {/* {this.renderModalDelete()} */}
        {this.renderModalEdit()}
        <Segment basic>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Provider</Table.HeaderCell>
                <Table.HeaderCell>Management URL</Table.HeaderCell>
                <Table.HeaderCell>Meta Data</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.data.length !== 0 ? (
                this.renderTable()
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="5">No Data</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
      </React.Fragment>
    );
  }
}

export default hostingServerTable;
