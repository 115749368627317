import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  AuthenticationWrapper,
  Authentication,
} from "@liquidcomputing/liquid__reactjs-localauth";
import { ToastContainer } from "react-toastify";
import AppConfig from "./_appConfig";
import Home from "./components/Homepage/Homepage";
import Navbar from "./components/common/Navbar/Navbar";
import Clients from "./components/Clients";
import Billing from "./components/Billing";
import Tickets from "./components/Tickets";
import ShowTicket from "./components/Tickets/viewTicket";
import ShowClient from "./components/Clients/showClient";
import Admin from "./components/Admin";
import Domain_Lookup from "./components/Domain_Lookup";
import FooterNav from "./components/common/FooterNav";
import System_Settings from "./components/System_Settings";
import SettingsProvider from "./lib/Settings_Provider";
import FrontendUpdateChecker from "./components/common/FrontendUpdateChecker";
// import Reports from "./components/Reporting";
import TimerWidgetModal from "./components/common/TimerWidgetModal";
import Timer from "./components/Timer";
import Logo from "./assets/LiquidFullLogo.png";

import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";
import "./main.css";

class MainApp extends React.Component {
  state = {
    timer_modal: false,
  };

  render() {
    const loggedInProfile = Authentication.getUserData();
    const isAdmin = loggedInProfile.groups.indexOf("admin") !== -1;
    return (
      <div className="ui container fluid padded">
        <SettingsProvider>
          <FrontendUpdateChecker />
          <Switch>
            <Route path="/timer" component={Timer} />
            <>
              <Navbar
                history={this.props.history}
                currentUser={loggedInProfile}
                admin={isAdmin}
                timerWidget={() => this.setState({ timer_modal: true })}
              />
              <ToastContainer
                draggable={false}
                hideProgressBar={true}
                autoClose={3000}
              />
              <TimerWidgetModal
                visible={this.state.timer_modal}
                toggleVisibilty={() => this.setState({ timer_modal: false })}
              />
              <Route exact path="/domain_lookup" component={Domain_Lookup} />
              <Route exact path="/clients" component={Clients} />
              <Route path="/clients/show/:id" component={ShowClient} />
              <Route exact path="/tickets" component={Tickets} />
              <Route path="/tickets/show/:id" component={ShowTicket} />
              <Route exact path="/billing" component={Billing} />
              <Route exact path="/settings" component={System_Settings} />
              {/* <Route path="/reports" component={Reports} /> */}
              {isAdmin && <Route exact path="/admin" component={Admin} />}
              <Route exact path="/" component={Home} />
              <FooterNav admin={isAdmin} />
            </>
          </Switch>
        </SettingsProvider>
      </div>
    );
  }
}

export default class App extends React.Component {
  render() {
    return (
      <AuthenticationWrapper
        logo={Logo}
        config={{
          applicationServer: AppConfig.api_server, // required during local development
          localPrefix: AppConfig.localStoragePrefix,
        }}
        dynamicScopes={{}}
      >
        <MainApp />
      </AuthenticationWrapper>
    );
  }
}
