import React, { Component } from "react";
import { Form, Segment, Icon, Header } from "semantic-ui-react";
import { toast } from "react-toastify";
import api from "../../../../../actions/api";

class editForm extends Component {
  state = {
    data: {
      name: "",
      provider: "",
      management_url: "",
      meta: { ram: "", cpu_cores: "", storage: "" },
    },
    providers: [],
    errors: {},
  };

  componentDidMount() {
    api.management.hosting_provider.listAllDropdown().then((res) => {
      this.setState({ providers: res });
    });

    if (this.state.data !== this.props.data) {
      console.log(this.props.data);
      this.setState({
        data: { ...this.props.data, provider: this.props.data.provider._id },
      });
    }
  }

  validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Can't Be Empty.";
    if (!data.provider) errors.provider = "Can't Be Empty.";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    } else {
      toast.error("Missing Required Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleMetaChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        meta: {
          ...this.state.data.meta,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="name"
              label="Name"
              value={this.state.data.name}
              onChange={this.handleChange}
              placeholder="Name"
              error={this.state.errors.name}
            />
            <Form.Dropdown
              name="provider"
              label="Provider"
              value={this.state.data.provider}
              onChange={this.handleDropdownChange}
              placeholder="Provider"
              error={this.state.errors.provider}
              options={this.state.providers}
              selection
              search
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="management_url"
              label="Management URL"
              value={this.state.data.management_url}
              onChange={this.handleChange}
              placeholder="Management URL"
            />
          </Form.Group>
          <Header as="h3" dividing>
            <p style={{ color: "black" }}>Meta Data (Optional Info)</p>
          </Header>
          <Form.Group widths="equal">
            <Form.Input
              name="ram"
              label="RAM"
              value={this.state.data.meta.ram}
              onChange={this.handleMetaChange}
              placeholder="RAM"
            />
            <Form.Input
              name="cpu_cores"
              label="CPU Cores"
              value={this.state.data.meta.cpu_cores}
              onChange={this.handleMetaChange}
              placeholder="CPU Cores"
            />
            <Form.Input
              name="storage"
              label="Storage"
              value={this.state.data.meta.storage}
              onChange={this.handleMetaChange}
              placeholder="Storage"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              type="submit"
              color="red"
              floated="left"
              onClick={() => {
                this.props.close();
              }}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              type="submit"
              color="green"
              floated="right"
              onClick={() => this.handleSubmit()}
            >
              <Icon name="paper plane" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default editForm;
