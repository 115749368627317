import React, { Component } from "react";
import { Header, Button, Icon, Modal, Table, Segment } from "semantic-ui-react";
import AddForm from "./Forms/addContactForm";
import EditForm from "./Forms/editContactForm";
import api from "../../../actions/api";
import { toast } from "react-toastify";

export default class companyContacts extends Component {
  state = {
    data: [],
    toggle_add: false,
    toggle_edit: false,
    activeContact: "",
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    const id = this.props.iddata;
    api.company.contacts
      .allContacts(id)
      .then((data) => {
        this.setState({ data: data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  handleClose() {
    if (this.state.toggle_add === true) {
      this.setState({ toggle_add: false });
    }

    if (this.state.toggle_edit === true) {
      this.setState({ toggle_edit: false });
    }
  }

  // handleDelete() {
  //   const id = this.props.iddata;
  //   const id2 = this.state.activeContact;
  //   api.company.deleteContact(id, id2).then(() => {
  //     this.getData();
  //     toast.error("Contact Deleted Successfully", {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     });
  //   });
  // }

  onSubmit = (data) => {
    if (this.state.toggle_add === true) {
      const id = this.props.iddata;
      return api.company.contacts
        .addContact(id, data)
        .then(() => {
          this.renderToast();
          this.handleClose();
          this.getData();
        })
        .catch((err) => {
          console.log(err.response);
        });
    }

    if (this.state.toggle_edit === true) {
      const id = this.props.iddata;
      const id2 = this.state.activeContact;
      api.company.contacts.updateContact(id, id2, data).then(() => {
        this.renderToast();
        this.handleClose();
        this.getData();
      });
    }
  };

  renderModal() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_add}
        onClose={this.handleClose.bind(this)}
        basic
        size="small"
      >
        <Header icon="user" content="Add New Contact" />
        <Modal.Content>
          <AddForm submit={this.onSubmit} close={this.handleClose.bind(this)} />
        </Modal.Content>
      </Modal>
    );
  }

  renderModalEdit() {
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_edit}
        onClose={this.handleClose.bind(this)}
        basic
        size="small"
      >
        <Header icon="file alternate" content="Edit Contact" />
        <Modal.Content>
          <EditForm
            submit={this.onSubmit}
            close={this.handleClose.bind(this)}
            idnumb={this.props.iddata}
            idnumb2={this.state.activeContact}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderContacts() {
    if (this.state.data.length === 0) {
      return (
        <Table.Row key={1}>
          <Table.Cell>No Contacts</Table.Cell>
        </Table.Row>
      );
    } else {
      return this.state.data.map((data) => {
        return (
          <Table.Row key={data._id}>
            <Table.Cell>{data.name}</Table.Cell>
            <Table.Cell>{data.email_address}</Table.Cell>
            <Table.Cell>{data.phone_number}</Table.Cell>
            <Table.Cell>{data.mobile_number}</Table.Cell>
            <Table.Cell textAlign="center">
              <Button
                circular
                icon
                color="twitter"
                onClick={() =>
                  this.setState({
                    toggle_edit: !this.state.toggle_edit,
                    activeContact: data._id,
                  })
                }
              >
                <Icon name="pencil" />
              </Button>
              {/* <Button
                circular
                icon
                color="red"
                onClick={() => {
                  this.setState({
                    activeContact: data._id
                  });
                  setTimeout(() => {
                    this.handleDelete();
                  }, 500);
                }}
              >
                <Icon name="close" />
              </Button> */}
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  renderData() {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email Address</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Mobile Number</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderContacts()}</Table.Body>
      </Table>
    );
  }

  renderToast() {
    if (this.state.toggle_add === true) {
      toast.success("Contact Added Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    if (this.state.toggle_edit === true) {
      toast.info("Data Updated Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  render() {
    return (
      <Segment basic>
        {this.renderModal()}
        {this.renderModalEdit()}
        <Header>
          Contact List
          <Button
            floated="right"
            icon
            circular
            onClick={() =>
              this.setState({ toggle_add: !this.state.toggle_add })
            }
          >
            <Icon name="plus" color="green" />
          </Button>
        </Header>
        {this.renderData()}
      </Segment>
    );
  }
}
