import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/organizations',
        clientId: 'bd776f91-8592-4404-9c91-49b82f4d01f3',
        redirectUri: window.location.origin + '/azure_authentication/'
    },
    cache: {
        cacheLocation: "localStorage"
    }
};

// Authentication Parameters
const authenticationParameters = {
    scopes: [
        'openid'
    ]
}

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html'
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);