import React, { Component } from "react";
import { Tab, Container, Transition, Segment, Header } from "semantic-ui-react";
import UserManagement from "./SubMenu/User_Management";
import ClientManagement from "./SubMenu/Client_Management";
import TicketManagement from "./SubMenu/Ticket_Management";
import AdditionalServicesManagement from "./SubMenu/Services_Management";
import HostingProviderManagement from "./SubMenu/Hosting_Provider_Management";
import HostingServerManagement from "./SubMenu/Hosting_Server_Management";

export default class index extends Component {
  state = {
    activeItem: "",
    onLoad: { visible: false },
  };

  componentDidMount() {
    this.setState({
      onLoad: { visible: true },
    });
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const panes = [
      {
        menuItem: "User Management",
        render: () => (
          <Tab.Pane>
            <UserManagement history={this.props.history} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Client Management",
        render: () => (
          <Tab.Pane>
            <ClientManagement history={this.props.history} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Ticket Management",
        render: () => (
          <Tab.Pane>
            <TicketManagement history={this.props.history} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Additional Services Management",
        render: () => (
          <Tab.Pane>
            <AdditionalServicesManagement history={this.props.history} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Hosting Provider Management",
        render: () => (
          <Tab.Pane>
            <HostingProviderManagement history={this.props.history} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Hosting Server Management",
        render: () => (
          <Tab.Pane>
            <HostingServerManagement history={this.props.history} />
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Container fluid>
        <Segment inverted attached textAlign="center">
          <Header as="h2">Admin Control Pannel</Header>
        </Segment>
        <Transition
          visible={this.state.onLoad.visible}
          duration={750}
          animation="fade"
        >
          <div className="adminPagePadding">
            <Tab
              menu={{
                fluid: true,
                vertical: true,
                tabular: true,
              }}
              grid={{ tabWidth: 2, paneWidth: 14 }}
              panes={panes}
            />
          </div>
        </Transition>
      </Container>
    );
  }
}
