import React, { Component } from "react";
import { Segment, Table } from "semantic-ui-react";
import api from "../../../../actions/api";
import moment from "moment";

class viewLogsForm extends Component {
  state = {
    data: [],
    loading: true,
  };

  componentDidMount() {
    api.company.domains.domain_actions
      .getAll(this.props.data.company, this.props.data._id)
      .then((res) => this.setState({ data: res, loading: false }))
      .catch(() => this.setState({ loading: false }));
  }

  renderBody() {
    return this.state.data.map((item) => {
      console.log(item);
      return (
        <Table.Row key={item._id}>
          <Table.Cell>{item.pending_action}</Table.Cell>
          <Table.Cell>{item.requested_by.fullName}</Table.Cell>
          <Table.Cell>
            {moment(item.requested_at).format("DD-MM-YYYY")}
          </Table.Cell>
          <Table.Cell>
            {item.pending_action === "Renew"
              ? moment(item.new_renew_date).format("DD/MM/YY")
              : "[N/A]"}
          </Table.Cell>
          <Table.Cell>{item.note}</Table.Cell>
          <Table.Cell>
            {item.completed === true ? "Complete" : "Incomplete"}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <Segment>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Action Type</Table.HeaderCell>
              <Table.HeaderCell>Requested By</Table.HeaderCell>
              <Table.HeaderCell>Requested At</Table.HeaderCell>
              <Table.HeaderCell>Domain Extension Date</Table.HeaderCell>
              <Table.HeaderCell>Notes</Table.HeaderCell>
              <Table.HeaderCell>Completion Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.data.length !== 0 ? (
              this.renderBody()
            ) : (
              <Table.Row>
                <Table.Cell>No Data</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default viewLogsForm;
