import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import moment from "moment";
import api from "../../actions/api";

export default function TimerWidget(props) {
  const [timerName, setTimerName] = useState("");
  const [timerTicket, setTimerTicket] = useState("");

  const [mountLoad, setMountLoad] = useState(true);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    if (mountLoad) {
      api.tickets
        .getAll()
        .then((res) => {
          setTickets(
            res.map((item) => {
              return {
                text: item.title,
                value: item._id,
              };
            })
          );
          setMountLoad(false);
        })
        .catch((e) => {
          toast.warn("Error Communicating With Server", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  }, [mountLoad]);

  const render_modal = () => (
    <Modal
      size="small"
      closeOnDimmerClick={false}
      open={props && props.visible}
    >
      <Modal.Header>
        <Icon name="clock" /> Creating A Timer
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Form>
            <Form.Group widths={"equal"}>
              <Form.Input
                label="Timer Name"
                value={timerName}
                onChange={(e) => {
                  setTimerName(e.target.value);
                }}
              />
              <Form.Dropdown
                label="Timer Ticket"
                selection
                options={tickets}
                value={timerTicket}
                onChange={(e, data) => setTimerTicket(data.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={props.toggleVisibilty}>
          Cancel
        </Button>
        <Button
          content="Create And Start"
          positive
          onClick={() => {
            if (timerName && timerTicket) {
              window.open(
                `/timer?ticket=${timerTicket}&title=${timerName}&start_time=${moment
                  .utc()
                  .format("DD/MM/YYYY HH:mm:ss")}`,
                "",
                "width=400,height=350"
              );
            } else {
              toast.error("Please Fill Out Both Fields", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }}
        />
      </Modal.Actions>
    </Modal>
  );

  return render_modal();
}
