import React from "react";
import { connect } from "react-redux";
import { Divider, Menu } from "semantic-ui-react";
import BuildNumber from "../../_buildNumber";

function mapStateToProps(state) {
  return {
    system: state.system,
  };
}

class FooterNav extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Divider clearing hidden />
        <Menu fixed={"bottom"} className={"footer-menu"} stackable>
          <Menu.Menu position="right">
            <Menu.Item>App Build: #{BuildNumber}</Menu.Item>
          </Menu.Menu>
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, {})(FooterNav);
