import React, { Component } from "react";
import { Header, Segment, Button, Modal } from "semantic-ui-react";
import api from "../../../../actions/api";
import HostingTable from "./hostingServerTable";
import AddForm from "./forms/addForm";
import { toast } from "react-toastify";

class index extends Component {
  state = {
    data: [],
    toggle_add: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.management.hosting_server
      .listAll()
      .then((data) => {
        this.setState({ data: data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  renderToast() {
    toast.success("Hosting Server Added Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }

  renderModal() {
    const handleClose = () =>
      this.setState({ toggle_add: false }, () => this.getData());

    const onSubmit = (data) =>
      api.management.hosting_server.add(data).then(() => {
        this.setState({ toggle_add: false }, () => this.getData());
      });

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_add}
        onClose={handleClose}
        size="small"
        basic
      >
        <Header icon="list alternate" content="Add New Server" />
        <Modal.Content>
          <AddForm submit={onSubmit} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderModal()}
        <Header textAlign="center">Hosting Server Management</Header>
        <Segment textAlign="right" basic>
          <Button
            content="Add Server"
            icon="plus"
            onClick={() => this.setState({ toggle_add: true })}
          />
        </Segment>
        <HostingTable
          getData={this.getData}
          data={this.state.data}
          history={this.props.history}
        />
      </React.Fragment>
    );
  }
}

export default index;
